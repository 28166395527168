import { Button, ButtonIcon } from "../../../../ui-kit/exports/react";
import BaseColorPicker from "./BaseColorPicker";
import MainColorPicker from "./MainColorPicker";
import Color from 'color';
import StateColorPicker from "./StateColorPicker";

export default function Colors({theme, onSave}) {
    
    const cssVars = Object.fromEntries(
        Object.entries(theme?.variables).map(([key, value]) => [`--${key}`, value])
    );

    const colorStyle2 = `flex flex-col w-1/4 h-full group relative justify-center items-center transition-all duration-150`
    const primaryHEX = Color(theme?.variables['primary'])?.hex()
    const accentHEX = Color(theme?.variables['accent'])?.hex()
    // style={{...cssVars}}>
    return (
        <div className="flex flex-col w-full gap-3 p-3" >
            <h1 className="flex justify-between items-end text-ellipsis font-accent whitespace-pre-wrap text-base font-medium text-left">
                Colors
            </h1>
            <div className="flex gap-3 text-xs items-center w-full">
                <span className="w-1/4 flex-shrink-0">Primary</span>
                <div className="w-3/4 h-full flex gap-2">
                <MainColorPicker 
                    theme={theme} 
                    colorName="primary" 
                    onColorChange={(colors) => onSave({...theme, variables: {...theme.variables, ...colors}})}
                    />
                <span className="w-1/2 flex-shrink-0 whitespace-nowrap bg-white hover:bg-base-50 cursor-pointer
                ring-[0.5px] ring-inset ring-current-20
                rounded-md px-3 h-full items-center justify-center flex
                text-center text-xs transition-all duration-150 
                "
                onClick={() => navigator.clipboard.writeText(primaryHEX)}
                >{primaryHEX}</span>
                </div>
            </div>
            <div className="flex gap-3 text-xs items-center w-full">
                <span className="w-1/4 flex-shrink-0">Accent</span>
                <div className="w-3/4 h-full flex gap-2">
                <MainColorPicker
                    theme={theme}
                    colorName="accent"
                    onColorChange={(colors) => onSave({...theme, variables: {...theme.variables, ...colors}})}
                    />
                
                <span className="w-1/2 flex-shrink-0 whitespace-nowrap bg-white hover:bg-base-50 cursor-pointer
                ring-[0.5px] ring-inset ring-current-20
                rounded-md px-3 h-full items-center justify-center flex
                text-center text-xs transition-all duration-150 
                "
                onClick={() => navigator.clipboard.writeText(accentHEX)}
                >{accentHEX}</span>
                </div>    
            </div>
            
            <div className="flex gap-3 text-xs items-center w-full">
                <span className="w-1/4 flex-shrink-0">Base</span>
                <BaseColorPicker
                    theme={theme}
                    onSave={onSave}
                    // onColorChange={(colors) => onSave({...theme, variables: {...theme.variables, ...colors}})}
                    />
            </div>
            <div className="flex gap-3 text-xs items-center w-full">
                <span className="w-1/4 flex-shrink-0">State</span>
                <StateColorPicker
                    theme={theme}
                    onSave={onSave}
                    // onColorChange={(colors) => onSave({...theme, variables: {...theme.variables, ...colors}})}
                    />
            </div>


            
            
        </div>
    )
}


/*
const colorStyle = `flex flex-col w-1/2 h-12 group relative justify-center items-center cursor-pointer transition-all duration-150`
<div className="flex flex-row w-full items-start justify-between">
                <div className={`${colorStyle} bg-primary rounded-l-md`} >
                    <p className="text-base-0 text-sm font-light transition-all duration-300">primary</p>
                </div>
                <div className={`${colorStyle} bg-accent rounded-r-md`}>
                    <p className="text-base-0 text-sm font-light transition-all duration-300">accent</p>
                </div>
            </div>
            <div className="flex flex-row w-full gap-3 items-start justify-between">
                <div className="flex flex-row w-1/2 items-start justify-between">
                    <div className={`${colorStyle2} bg-base-50 rounded-l-md`} />
                    <div className={`${colorStyle2} bg-base-200`} />
                    <div className={`${colorStyle2} bg-base-500`} />
                    <div className={`${colorStyle2} bg-base-content rounded-r-md`} />
                </div>
                <div className="flex flex-row w-1/2 items-start justify-between">
                    <div className={`${colorStyle2} bg-info rounded-l-md`} />
                    <div className={`${colorStyle2} bg-error`} />
                    <div className={`${colorStyle2} bg-success`} />
                    <div className={`${colorStyle2} bg-warning rounded-r-md`} />
                </div>
            </div>
            <div className="flex gap-3 text-xs items-center w-full">
                <span className="w-1/4 flex-shrink-0">Base</span>
                <div className="flex-grow flex h-6 rounded-md">
                
                    <div className={`${colorStyle2} bg-base-50 rounded-l-md`} />
                    <div className={`${colorStyle2} bg-base-100`} />
                    <div className={`${colorStyle2} bg-base-300`} />
                    <div className={`${colorStyle2} bg-base-500`} />
                    <div className={`${colorStyle2} bg-base-700`} />
                    <div className={`${colorStyle2} bg-base-content rounded-r-md`} />
                
                </div>
            </div>
            <div className="flex gap-3 text-xs items-center w-full">
                <span className="w-1/4 flex-shrink-0">Status</span>
                <div className="flex-grow flex h-6 rounded-md">
                    <div className={`${colorStyle2} bg-info rounded-l-md`} />
                    <div className={`${colorStyle2} bg-error`} />
                    <div className={`${colorStyle2} bg-success`} />
                    <div className={`${colorStyle2} bg-warning rounded-r-md`} />
                </div>
            </div>
            
            */