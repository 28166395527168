import FramePreview from "components/FramePreview";

export default function Folders({ 
    folders, 
    variables,
    onSelect
  }) {
    
    const tileWidth = 240;
  
  const width = 1440
  const height = 820
  // Calculate the scaling factor based on the largest dimension
  const scaleWidth = tileWidth / width;
  const scaleFactor = Math.min(1, scaleWidth); // Ensure the scaling does not exceed 1

  // Use the scaling factor to determine the dimensions
  const scaledWidth = width * scaleFactor;
  const scaledHeight = height * scaleFactor;
  
  
    return (
      <>
      <div className='flex flex-row gap-2 items-center justify-start text-sm py-1 font-medium'>
          <h1>Choose category</h1>
      </div>
      <div className='overflow-y-auto' style={{ height: '100%' }}>
          <div className='grid grid-cols-1 gap-2'>
          {folders.map(folder => { 
            const firstFrame = folder.frames && folder.frames[0];
            return (
            <div key={folder.name} className='flex flex-col w-full gap-1 last:mb-3'>
              <div className={`flex flex-col w-full text-center rounded-md text-xs items-center justify-center
              p-3 transition-all duration-75 text-base-500 bg-base-50 hover:bg-base-100
              ring-[0.5px] ring-inset ring-current-50 pb-6
              hover:shadow-inner-lg hover:shadow-base-300 group cursor-default relative 
              `}
              >
                {<div className="flex flex-col w-full" style={{height: scaledHeight}} onClick={() => onSelect(folder)}>
                {firstFrame && 
                <PreviewFrame
                frame={firstFrame}
                scaledWidth={scaledWidth}
                scaledHeight={scaledHeight}
                scaleFactor={scaleFactor}
                width={width}
                height={height}
                variables={variables}
                />}
                </div>
                }
              <div className="absolute opacity-0 group-hover:opacity-100 transition-all duration-75
              rounded-md bg-base-0 py-1 px-3 ring-1 ring-base-400
               shadow-md z-50 text-base-800 font-medium"
               onClick={() => onSelect(folder)}
               >
                view {folder.frames?.length} templates
              </div>
              
              <h4 className='capitalize text-base-900 flex-grow truncate absolute bottom-1 opacity-70 group-hover:opacity-100 transition-all duration-75'>
                {folder.name}
              </h4>
              
              </div>
  
              
            </div>
            )}
          )}
        </div>
        </div>
        </>
    );
  }
  


  function PreviewFrame({
    frame, scaledWidth, scaleFactor, width, height, variables, scaledHeight
  }) {
    return (
      <div
    className="flex flex-col justify-center transition-all duration-75 items-center relative font-normal select-none overflow-hidden shadow"
    style={{ width: scaledWidth, height: scaledHeight }}
    
  >
    
    <div
      className="bg-base-0"
      style={{
        transform: `scale(${scaleFactor})`,
        width: width,
        height: height
      }}
      >
        {<FramePreview
          frame={frame} width={width} height={height}
          // assets={assets}
          effectiveVariables={variables} 
        />}
        
      </div>
      </div>
    )
  }