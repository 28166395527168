import { useContext, useRef } from 'react';
import { EditorContext } from '../../../EditorContext.js';
import * as projectsDB from '../../../../../api/projectsAPI'

// Import default assets
import { ButtonIcon, Icon, Logo } from '../../../../../ui-kit/exports/react/';
import { uploadImage } from 'utilities/files.js';
import { config } from '../../../../../ui-kit/exports/react/components/config.tsx';
import { Xmark } from 'iconoir-react';

export default function Logos() {
    const { project, setProject } = useContext(EditorContext);
    const projectId = project.project?.id;

    // Function to find asset by its API name and return both project and default assets
    const projectConfig = project?.project?.config || {}
    const brand = projectConfig?.brand || {}

    // Define asset map
    const modifiedConfig = {...config, brand: {...config.brand, ...brand}}
    const __juno = {designConfig: modifiedConfig}
    // console.log('brand:', projectConfig)

    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        const api_name = e.target.name
        if (file) {
            try {
                let type = file.type.split('/')[1] 
                type = type.split('+')[0]
                const fileName = `project${projectId}_asset_${api_name}.${type}`;
                const uploadedImageUrl = await uploadImage(file, fileName);
                const newConfig = {
                    ...projectConfig,
                    brand: {
                        ...brand,
                        [api_name]: uploadedImageUrl
                    }
                }
                
                // update project
                const updatedProject = { ...project, project: { ...project.project, config: newConfig } };
                setProject(updatedProject);
                projectsDB.update(updatedProject.project);
            } catch (err) {
                console.error("Failed to upload image:", err);
            }
        }
    };

    function handleDelete(api_name) {
        const newConfig = {
            ...projectConfig,
            brand: {
                ...brand,
                [api_name]: null
            }
        }
        
        const updatedProject = { ...project, project: { ...project.project, config: newConfig } };
        setProject(updatedProject);
        projectsDB.update(updatedProject.project);
    }
    
    // console.log(modifiedConfig)
    const logos = [
        {api_name: 'logo', isInverted: false, type: 'logo', name: 'Logo'},
        {api_name: 'logo_inverted', isInverted: true, type: 'logo', name: 'Logo Inverted'},
        {api_name: 'symbol', isInverted: false, type: 'symbol', name: 'Symbol'},
        {api_name: 'symbol_inverted', isInverted: true, type: 'symbol', name: 'Symbol Inverted'},
    ]
    
    return (
            <div className="flex flex-col w-full h-auto gap-4 p-3">
                {logos.map((logo, index) => (
                <LogoTile 
                    onClick={handleLogoUpload} 
                    onDelete={handleDelete}
                    logo={logo}
                    key={index}
                    __juno={__juno}
                >
                
                </LogoTile>))}
        </div>
    );
}

function LogoTile({logo, onClick, onDelete, __juno}) {
    
    const cellClasses = logo.isInverted ? `bg-base-800 text-base-0` : `bg-base-50 text-base-content`
    const uploadInputRef = useRef(null);
    
    return (
        <div className="flex flex-col w-full text-base-content bg-base-0 gap-1 rounded-base items-center justify-center">
        <div className="flex flex-row w-full gap-3 justify-between items-center">
            <span className="inline-flex whitespace-nowrap text-sm text-left">
            {logo.name}
            </span>
            <div className="flex flex-row w-auto gap-3 justify-end">
            <ButtonIcon icon="download" size='small' style='ghost' />
            <ButtonIcon icon="xmark" size='small' style='ghost' onClick={() => onDelete(logo.api_name)} />
            </div>
        </div>
        <div className={`flex flex-row w-full ${cellClasses} gap-3 px-4 py-4 rounded-md items-center justify-center`}
         style={{ minHeight: '80px' }}>
            {__juno?.designConfig?.brand?.[logo?.api_name] && <Logo 
                    size={logo.type == 'logo' ? '16px' : '24px'}
                    type={logo.type}
                    isInverted={logo.isInverted}
                    onClick={() => uploadInputRef.current.click()}
                    __juno={__juno}
                    
                />}
        </div>
        
        </div>
    )
}


function LogoTileOld({logo, onClick, onDelete, __juno}) {
    
    const cellClasses = logo.isInverted ? `bg-base-800 text-base-0` : `bg-base-50 text-base-content`
    const uploadInputRef = useRef(null);
    
    return (
        <div className={`flex flex-row w-full items-center ${cellClasses}`}>
        <span className="w-1/3 text-xs flex-shrink-0 items-center p-3 whitespace-nowrap">{logo.name}</span>
        <div className={`w-2/3 h-full 
        gap-5
        flex h-[64px] items-center p-2 justify-end relative group`}
        // onClick={() => uploadInputRef.current.click()}
        >
            {__juno?.designConfig?.brand?.[logo?.api_name] ? <><Logo 
                    size={logo.type == 'logo' ? '16px' : '24px'}
                    type={logo.type}
                    isInverted={logo.isInverted}
                    onClick={() => uploadInputRef.current.click()}
                    __juno={__juno}
                    
                />
                <ButtonIcon 
                size='small' style='ghost'
                icon='xmark' onClick={() => onDelete(logo.api_name)} />
                </> : 
                <ButtonIcon 
                size='small' style='ghost'
                icon='upload' onClick={() => uploadInputRef.current.click()} />
                }
                </div>
                
                    
                
                
            <input
                        type="file"
                        name={logo.api_name}
                        ref={uploadInputRef}
                        style={{ display: 'none' }}
                        onChange={onClick}
                    />
        </div>
    )
}
