import React from 'react';
import { spacingMap } from '../helpers';
import { useMediaQuery } from 'react-responsive';


type HeaderProps = {
    width?: '100%' | '560px' | '780px' | '960px' | '1020px' | '1080px' | '1200px' | '1440px',
    paddingX?: '6px' | '8px' | '12px' | '16px' | '20px' | '24px' | '32px' | '48px' | '64px',
    paddingY?: '6px' | '8px' | '12px' | '16px' | '20px' | '24px' | '32px' | '48px' | '64px',
    gap?: '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    background?: 'base-0' | 'base-100' | 'base-200' | 'base-700' | 'base-content' | string,
    hasBorder?: boolean,
    alignItems?: 'start' | 'end' | 'center' | 'stretch',
    justifyContent?: 'start' | 'center' | 'end' | 'between',
    fontSize?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl',
    minHeight?: number,
    children?: React.ReactNode,
    display?: boolean,
    __juno?: any,
}

function Header({
        width = '100%',
        
        paddingX,
        paddingY,
        gap,

        background,
        hasBorder = false,
        display = true,
        alignItems = 'center',
        justifyContent = 'between',
        fontSize = 'base',
        minHeight = 64,
        children,

        __juno = {}
      }: HeaderProps) {
    const mobile_props = __juno?.mobile_props || {};

    const bgStyles = background ?`bg-${background}` : '';
    // font color function needs to be more robust and isolated
    const fontColor = (!background || background == 'none') ? '' : (background?.startsWith('base') && background != 'base-content' && background != 'base-700') ? 'text-base-content' : `text-base-0`
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';

    const borderStyles = hasBorder ? "border-b border-current-10" : "";
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const fontSizeStyles = `text-${fontSize}`;
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    
    let displayStyles = mobile_props?.display === false ? 'hidden' : 'flex';
    displayStyles = display == false ? displayStyles+' md:hidden' : displayStyles+' md:flex';
    
    const innerClasses = `relative flex flex-row w-full border-box ${fontSizeStyles} ${fontColor} ${paddingStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles}`

    const outerClasses = `${displayStyles} flex-col w-full items-center ${bgStyles} ${borderStyles} relative backrop-blur`;


    return (
        <div 
        className={`${outerClasses} ${__juno?.outlineStyle}`}
        {...__juno?.attributes}
        >
            <div 
            className={`${innerClasses} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            data-tag={__juno?.attributes?.['data-tag']}   
            
            style={{width: '100%', maxWidth: width, minHeight: minHeight ? `${minHeight}px` : 'auto'}}>
                {children}
                </div>
        </div>
    );
}

Header.__juno_name = "Header";
export default Header;


