import { Heading, CodeSnippet, Button } from 'ui-kit/exports/react';
import { tailwind } from './tailwindConfig';
import { useContext, useEffect, useState } from 'react';
import { DSContext } from 'pages/editor/DSContext';
import { makeIndexCss } from './makeIndexCss';
import { makeIndexHtml } from './makeIndexHtml';
import { makeJunoConfig } from './makeJunoConfig';
import { viteConfig } from './viteConfig';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import UserContext from 'UserContext';
import { Alert } from 'ui-kit/exports/react';

export default function RepoGeneration({ project }) {
    
    const { styleVariables, currentTheme } = useContext(DSContext)
    const { user } = useContext(UserContext)
    const token = user?.token

    // const indexCss = makeIndexCss(styleVariables)
    // const indexHtml = makeIndexHtml(project.project)
    // const junoConfig = makeJunoConfig(currentTheme?.config || {})

    const [ config, setConfig ] = useState({
      project: project.project,
      theme: currentTheme,
      'index.css': ``, 
      'index.html': ``,
      'config.js': ``,
      'tailwind.config.js': ``,
      'vite.config.ts': ``
    })

    const projectConfig = project.project.config || {}
    const logos = projectConfig?.brand ? Object.entries(projectConfig?.brand)
      .filter(([_, value]) => value) // Ensure the URL exists
      .map(([key, value]) => {
        try {
          const urlObj = new URL(value, window.location.origin);
          const fileExtension = urlObj.pathname.split('.').pop();

          return {
            key: key,
            fileName: `${key}.${fileExtension}`,
            extension: fileExtension,
            url: value
          };
        } catch (error) {
          console.error(`Invalid URL: ${value}`, error);
          return null;
        }
      })
      .filter(Boolean) : [] // Remove null values // Remove null values from invalid URLs
    
      const downloadAllLogos = async (logos) => {
        const zip = new JSZip();
        const folder = zip.folder("brand");
      
        // Fetch all logo files and add to ZIP
        const fetchPromises = logos.map(async (logo) => {
          try {
            const response = await fetch(logo.url);
            if (!response.ok) throw new Error(`Failed to fetch ${logo.fileName}`);
      
            const blob = await response.blob();
            folder.file(logo.fileName, blob);
          } catch (error) {
            console.error(`Error downloading ${logo.fileName}:`, error);
          }
        });
      
        await Promise.all(fetchPromises);
      
        // Generate and download the ZIP file
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, "logos.zip");
        });
      };

      async function fetchProjectExport(projectId, token) {
        if (!token) {
          console.error('No token found. Make sure you are logged in.');
          return null;
        }
      
        try {
          // 1. Make the request with the Authorization header
          const response = await fetch(`/api/export/project/${projectId}`, {
            method: 'GET',
            /*headers: {
              'Authorization': `Bearer ${token}`
            }*/
          });
      
          // 2. Check for a successful response
          if (!response.ok) {
            throw new Error(`Failed to export project. Status: ${response.status}`);
          }
      
          // 3. Parse the response JSON
          const data = await response.json();
          // console.log('Fetched project:', data);
          // data should be in the format { project: ..., theme: ... }
          
          return data;
        } catch (error) {
          console.error('Error exporting project:', error);
          return null;
        }
      }

      useEffect(() => {
        
        async function fetchProject() {
          const data = await fetchProjectExport(project.project.id, token);
          setConfig(data);
        }
      
        fetchProject();
        
      }, [project]);
        

    return (
      <>
        <div className="pl-5 pr-3 w-full max-w-full h-full gap-6 overflow-scroll items-start justify-start flex flex-col py-3">
        
        {/* Installation */}
        <div className="flex flex-row flex-nowrap w-full text-auto gap-6 items-start justify-start">
            <div className="flex flex-col flex-nowrap w-1/2 text-auto gap-2 items-start justify-start">
              <Heading text="Generate Repo" size="small" />
              <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                You can automatically generate a starter repo using Juno CLI command. 
                It will create a vite application with JUNO design system pre-configured and ready to use.
              </p>
            </div>
            <div className="flex flex-col flex-grow-0 flex-shrink-0 flex-nowrap w-1/2 text-auto gap-3 items-start justify-start">
                <CodeSnippet 
              title="CLI Commands"
              fontSize="xs" text={`junodesign login
junodesign init ${project.project.id}`}
              language="bash"
              maxHeight={320}
               hasOutline />
            </div>
          </div>


        {/* Design System */}
        <div className="flex flex-col flex-nowrap w-full text-auto gap-6 items-start justify-start">
          <div className="flex flex-row flex-nowrap w-full text-auto gap-3 items-start justify-start -mb-3">
            <Heading text="Design System" size="medium" />
          </div>
          
          {/* UI-Kit */}
          <div className="flex flex-row flex-nowrap w-full text-auto gap-6 items-start justify-start">
              <div className="flex flex-col flex-nowrap w-1/2 text-auto gap-2 items-start justify-start">
                <Heading text="JUNO UI-Kit" size="small" />
                <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                  Comprehensive React/TypeScript component library. Included in <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>/src/junokit</code> folder.
                </p>
                <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                  <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>config.ts</code> allows you to configure ui-kit settings such as outline preferences, default icon set and more. 
                </p>
              </div>
              <div className="flex flex-col flex-grow-0 flex-shrink-0 flex-nowrap w-1/2 text-auto gap-3 items-start justify-start">
                <Button
                leftIcon='box' style='filled'
                onClick={() => window.open('https://www.junodesign.app/junokit.zip', '_blank')}
                text='junokit.zip' size='small' />
                <CodeSnippet 
                    maxHeight={320}
                    title="config.ts"
                    text={config['config.js']}
                    fontSize="xs"
                    hasOutline />
              </div>
          </div>

          {/* Assets */}
          <div className="flex flex-row flex-nowrap w-full text-auto gap-6 items-start justify-start">
              <div className="flex flex-col flex-nowrap w-1/2 text-auto gap-2 items-start justify-start">
                <Heading text="Assets" size="small" />
                <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                  Standard visual assets such as logos, symbols and favicons are included in the <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>/public/brand</code> folder.
                </p>
              </div>
              <div className="flex flex-col flex-grow-0 flex-shrink-0 flex-nowrap w-1/2 text-auto gap-3 items-start justify-start">
              {logos?.length > 0 ? <Button 
                leftIcon='box' style='filled'
                onClick={() => downloadAllLogos(logos)}
                text='brand.zip' size='small' /> 
              : <Alert type='info' size='small' width='full'
              text='No assets have been added to the project' />
              }
               <div className='flex flex-wrap gap-2'>
                {logos.map(logo => (
               <Button 
                key={logo.fileName}
                style='outlined'
                leftIcon={logo.extension == 'svg' ? 'code' : 'image'}
                text={logo.fileName}
                size='small' 
                onClick={() => window.open(logo.url, '_blank')}
                />))}
                
                </div>  
              </div>
          </div>

          
          <div className="flex flex-row flex-nowrap w-full text-auto gap-6 items-start justify-start">
            <div className="flex flex-col flex-nowrap w-1/2 text-auto gap-2 items-start justify-start">
              <Heading text="CSS Variables" size="small" />
              <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                This <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>index.css</code> file contains css variables and commonly used styling presets that ensure design system consistency across different browsers and screen resolutions.
              </p>
            </div>
            <div className="flex flex-col flex-grow-0 flex-shrink-0 flex-nowrap w-1/2 text-auto gap-3 items-start justify-start">
                <CodeSnippet 
              title="index.css"
              fontSize="xs" text={config['index.css']}
              language="css"
              maxHeight={320}
               hasOutline />
            </div>
          </div>

          <div className="flex flex-row flex-nowrap w-full text-auto gap-6 items-start justify-start">
            <div className="flex flex-col flex-nowrap w-full text-auto gap-2 items-start justify-start">
              <Heading text="Tailwind Config" size="small" />
              <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                Copy this into your <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>tailwind.config.js</code> to enable design system utility classes in your project.
              </p>
            </div>
            <div className="flex flex-col flex-grow-0 flex-nowrap w-1/2 text-auto gap-3 items-start justify-start">
                <CodeSnippet 
                title="tailwind.config.js"
                text={config['tailwind.config.js']}
                fontSize="xs"
                maxHeight={320}
                language="javascript"
                hasOutline />
            </div>
          </div>

          {/* Juno Config 
          <div className="flex flex-row flex-nowrap w-full text-auto gap-6 items-start justify-start">
            <div className="flex flex-col flex-nowrap w-1/2 text-auto gap-2 items-start justify-start">
              <Heading text="JUNO Config" size="small" />
              <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>juno.config.ts</code> allows you to configure ui-kit settings such as outline preferences, default icon set and more. 
              </p>
              <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                This file is automatically included in the <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>/src/junokit</code> folder. 
              </p>
            </div>
            <div className="flex flex-col flex-grow-0 flex-shrink-0 flex-nowrap w-1/2 text-auto gap-3 items-start justify-start">
                <CodeSnippet 
                maxHeight={320}
              title="juno.config.ts"
              text={junoConfig}
              fontSize="xs"
              hasOutline />
            </div>
          </div>*/}
        
        </div>

        
        {/* Repo Config */}
        <div className="flex flex-col flex-nowrap w-full text-auto gap-6 items-start justify-start">
          <div className="flex flex-row flex-nowrap w-full text-auto gap-3 items-start justify-start -mb-3">
            <Heading text="Application" size="medium" />
          </div>
          <div className="flex flex-row flex-nowrap w-full text-auto gap-6 items-start justify-start">
              <div className="flex flex-col flex-nowrap w-1/2 text-auto gap-2 items-start justify-start">
                <Heading text="Meta Data" size="small" />
                <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                  Paste these meta tags into your <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>index.html</code> for SEO and social media sharing. 
                </p>
              </div>
              <div className="flex flex-col flex-grow-0 flex-shrink-0 flex-nowrap w-1/2 text-auto gap-3 items-start justify-start">
                <CodeSnippet 
                  title="index.html"
                  text={config['index.html']}
                  language="html"
                  width="full"
                  fontSize="xs"  hasOutline />
                
                
              </div>
          </div>
          <div className="flex flex-row flex-nowrap w-full text-auto gap-6 items-start justify-start">
              <div className="flex flex-col flex-nowrap w-1/2 text-auto gap-2 items-start justify-start">
                <Heading text="Vite Config" size="small" />
                <p className="whitespace-pre-wrap text-left text-sm text-balance w-3/4">
                  This <code className='px-1 py-0.5 rounded-sm text-xs bg-current-10'>vite.config.ts</code> file contains vite build settings and aliases for your project.
                </p>
              </div>
              <div className="flex flex-col flex-grow-0 flex-shrink-0 flex-nowrap w-1/2 text-auto gap-3 items-start justify-start">
                <CodeSnippet 
                  title="vite.config.ts"
                  width="full"
                  text={config['vite.config.ts']}
                  fontSize='xs' hasOutline />
              </div>
          </div>
          
        </div>
        </div>
      </>
    );
  }
  
  