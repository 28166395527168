import React, { useContext } from "react";
import { EditorContext } from "../EditorContext";
import AddFromLibrary from "./insideproject/AddFromLibrary";
import * as projectsDB from '../../../api/projectsAPI'
import Editable from "./Editable";
import { Button, Icon } from "ui-kit/exports/react";

export default function OverviewHeader({tab}) {
    
    const { selector, view, setView, project, handleAction, set, setProject } = useContext(EditorContext);
    

    function renameProject(name) {
        const updatedProject = { ...project, project: { ...project.project, name } };
        setProject(updatedProject);
        projectsDB.update(updatedProject.project);
    }

    function renameFolder(newName) {
        const currentFolder = selector.folder;
        const action = {
            type: 'UPDATE_FOLDER',
            folder: { ...currentFolder, name: newName },
        };
        handleAction(action);
    }

    function renamePage(newName) {
        const currentPage = selector.page;
        if (!currentPage) return;
        const action = {
            type: 'UPDATE_PAGE',
            currentPage,
            newPage: { ...currentPage, name: newName },
        };
        handleAction(action);
    }

    const flowTabs = [
        {label: project?.project?.name, onClick: () => setView({...view, inOverview: '/overview'})},
        {label: selector?.folder?.name, onClick: () => setView({...view, inOverview: '/folder'})},
    ]
    const h1Map = {
        '/overview': 'Overview',
        '/config': 'Config',
        '/config/router': 'Config',
        '/config/database': 'Config',
        '/config/docs': 'Config',
        '/ds/themes': 'Design System',
        '/ds/layouts': 'Design System',
        '/ds/brand': 'Design System',
        '/ds/uikit': 'Design System',
        '/folder': project?.project?.name,
        '/flow': <BreadCrumbs tabs={flowTabs}/>
    }

    const h2Map = {
        '/overview': <Editable 
        size="large"
        string={project?.project?.name}
        onBlur={renameProject}
        placeholder="Project Name"
    />,
        '/config': 'Configuration',
        '/config/router': 'Router',
        '/config/database': 'Database',
        '/config/docs': 'Install',
        '/ds/layouts': 'Layouts',
        '/folder': <Editable 
        size="large"
        string={selector?.folder?.name}
        onBlur={renameFolder}
        placeholder="Project Name"
    />,
        '/flow': <Editable 
        size="large"
        string={selector?.page?.name}
        onBlur={renamePage}
        placeholder="Project Name"
    />,
        '/ds/themes': 'Themes',
        '/ds/brand': 'Brand',
        '/ds/uikit': 'UI Kit',
    }

    const h1 = h1Map[view.inOverview];
    const h2 = h2Map[view.inOverview];
        
    function addFlow() {
        const folder_id = selector.folder?.id;
        if (folder_id) {handleAction({ type: 'APPEND_PAGE', folder_id})}
    }
    return (
        <div className={`flex flex-row pl-5 px-2 w-full justify-between items-end transition-all`}>
            <div className="w-1/2 flex flex-col gap-1">
                <h1 className="text-xs opacity-80">{h1}</h1>
                <h2 className="flex flex-row gap-2 h-full items-end text-xl font-semibold">{h2}</h2>
            </div>

            <div className="flex flex-row gap-2 items-end pb-1"> 
                {tab === '/folder' &&  
                <Button
                    size={'small'}
                    text={'New Flow'}
                    rightIcon={'plus'}
                    onClick={() => addFlow()}
                    
                
                />}
                {(tab === '/flow' || tab === '/folder') &&
                <AddFromLibrary
                selector={selector}
                handleAction={handleAction}
                set={set}
                />}
                
            </div>
        </div>)
}


const BreadCrumbs = ({tabs}) => {
    return (
        <div className="flex flex-row gap-1 items-center">
            {tabs.map((tab, index) => (
                <React.Fragment key={index}>
                    <span className="hover:underline select-none"
                    onClick={tab.onClick}
                    >{tab.label}</span>
                    {index < tabs.length - 1 && <Icon icon='chevron-right' size='12px'/>}
                </React.Fragment>
            ))}
        </div>
    )

}