import { Icon } from '../';
import { config } from '../config';
import { IconType, allIconNames } from '../iconMap';

type InputWithoutSize = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;
type InputTextProps = InputWithoutSize & {
    state?: 'default' | 'disabled' | 'error' | 'success',
    bgColor?: 'base-0' | 'base-50' | 'base-100' | 'current-5' | 'current-10' | 'transparent',
    size?: 'small' | 'medium' | 'large',
    label?: string,
    helperText?: string,
    rightIcon?: IconType,
    leftIcon?: IconType,
    prefix?: string,
    suffix?: string,
    textAlign?: 'left' | 'right' | 'center',
    width?: 'auto' | '1/2' | 'full',
    hasOutline?: boolean,
    isPill?: boolean,
    __juno?: any, 

    /* React Input Props (subset) */
    defaultValue?: string,
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    placeholder?: string,    
}

export default function InputText({
    state = 'default',

    bgColor: passedBgColor,
    size = 'medium',
    label = '',
    helperText = '',
    rightIcon,
    leftIcon,
    prefix = '',
    suffix = '',
    textAlign = 'left',
    width = 'auto',
    isPill: passedIsPill,
    hasOutline = false,        
    __juno = {},

    /* React Input Props (subset) */
    defaultValue,
    value,
    onChange,
    onBlur,
    onFocus,
    placeholder = '',
    ...extraProps

  }: InputTextProps) {
    
    let reactProps = {...extraProps, defaultValue, value, onChange, onBlur, onFocus, placeholder}
    delete reactProps.children
    
    const iconSize = size == 'small' ? '16px' : size == 'large' ? '24px' : '20px'
    const iconSizeEms = size == 'small' ? 4 : size == 'large' ? 6 : 5
    const iconStyle = `w-${iconSizeEms}`

    const localConfig = { ...config, ...(__juno?.designConfig || {}) };
    const isPill = passedIsPill ?? localConfig?.is_rounded
    const defaultBgColor = localConfig.outlines == 'none' ? 'current-5' : 'base-0' // if no outline, we need to use color to show focus
    const bgColor = passedBgColor || defaultBgColor

    const spacingUnit = size == 'small' ? 2.5 : size == 'large' ? 4 : 3.5
    const gapUnit = size == 'small' ? 2 : size == 'large' ? 3 : 2.5
    // left-1.5 left-2 left-2.5 left-3 left-3.5 left-4 right-1.5 right-2 right-2.5 right-3 right-3.5 right-4
    const LeftIconComponent = leftIcon ? <Icon icon={leftIcon} size={iconSize} className={`absolute left-${spacingUnit} top-1/2 -translate-y-1/2 scale-90 ${iconStyle}`}/> : null;
    const RightIconComponent = rightIcon ? <Icon icon={rightIcon} size={iconSize} className={`absolute right-${spacingUnit} top-1/2 -translate-y-1/2 scale-90 ${iconStyle}`}/> : null;

    // pl-2 pl-2.5 pl-3 pl-3.5 pl-4 pl-5 pl-6 pl-7 pl-8 pl-9 pl-10 pl-11 pl-12 pl-13
    // pr-2 pr-2.5 pr-3 pr-3.5 pr-4 pr-5 pr-6 pr-7 pr-8 pr-9 pr-10 pr-11 pr-12 pr-13
    const paddingLeft = LeftIconComponent ? `pl-${gapUnit + iconSizeEms + gapUnit}` : `pl-${spacingUnit}`;
    const paddingRight = RightIconComponent ? `pr-${gapUnit + iconSizeEms + gapUnit}` : `pr-${spacingUnit}`;
    const paddingX = `${paddingLeft} ${paddingRight}`;
    
    const textSize = size == 'small' ? 'text-xs' : size == 'large' ? 'text-base' : 'text-sm';
    const cornerStyles = isPill ? 'rounded-full' : size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"

    const ringSize = size == 'small' ? '1' : size == 'large' ? '2' : '1.5'
    // focus-within:outline-[1px] focus-within:outline-[2px] focus-within:outline-[1.5px]

    const lightOutlineMap = {
        'none': '',
        'subtle': 'outline -outline-offset-1 outline-[0.5px] outline-current-10',
        'strong': 'outline -outline-offset-1 outline-[0.5px] outline-current-20',
    }
    const lightOutlineStyles = lightOutlineMap[localConfig.outlines || 'none'];
    
    // default
    let stateStyles = hasOutline 
    ? `outline-1 outline-current-10 outline -outline-offset-1 focus-within:outline-[${ringSize}px] focus-within:outline-primary` 
    : `${lightOutlineStyles} focus-within:outline-[${ringSize}px] focus-within:-outline-offset-1 focus-within:outline focus-within:outline-primary`;

    switch (state) {
        case 'disabled':
            stateStyles = `bg-base-100 opacity-70 cursor-not-allowed ${hasOutline ? 'outline outline-1 outline-current-10 -outline-offset-1' : ''}`
            break;
        case 'error':
            stateStyles = `text-warning ${hasOutline ? 'outline outline-1 outline-current-10 -outline-offset-1 outline-warning' : ''}`
            break;
        case 'success':
            stateStyles = `text-success ${hasOutline ? 'outline outline-1 outline-current-10 -outline-offset-1 outline-success' : ''}`
            break;
    }
    
    const bgStyles = bgColor ? `bg-${bgColor} ${!hasOutline && ''}` : '';
    
    const heightStyle = size == 'small' ? 'h-7' : size == 'large' ? 'h-12' : 'h-9';
    let inputWrapper = `relative flex !overflow-hidden flex-row items-center ${heightStyle}  ${textSize} ${cornerStyles} ${bgStyles} ${stateStyles} `

    
    const labelTextSize = size == 'small' ? `text-xs` :  size == 'large' ? `text-lg`: `text-sm`;
    const labelClasses = `${labelTextSize} font-normal`

    const messageTextColor = state == 'error' ? stateStyles = 'text-warning' : state == 'success' ? stateStyles = 'text-success' : ''
    const messageClasses = size == 'large' ? `text-base  ${messageTextColor}` : `text-sm ${messageTextColor}`
    const widthStyle = width != 'auto' ? `w-${width}` : size == 'small' ? '' : size == 'large' ? 'min-w-[200px]' : 'min-w-[160px]'

    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    const textColor = (state == 'disabled' || state == 'default') ? bgColor?.startsWith('base') ? 'text-base-content' : 'text-current' : `text-${state}-content`
    
    const classes = `flex flex-col ${widthStyle} ${gapStyles} ${textColor}`

    const inputPaddingX = `${(prefix || LeftIconComponent) ? 'pl-'+gapUnit : ''} ${(suffix || RightIconComponent) ? 'pr-'+gapUnit : ''}`

    
    
    return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        >
            {label && ( <label className={labelClasses}>{label}</label>)}
            <div className={inputWrapper} >
            {LeftIconComponent}
            {/*<span className={`flex-shrink-0 ${LeftIconComponent && prefix ? `pl-${gapUnit}` : ''}`}>{prefix}</span*/}
            <div className='flex-grow relative'> <div className='absolute right-0 w-full h-full' /> {/* remove line */}
            <input
            {...reactProps}
            disabled={state == 'disabled'}
            className={`text-${textAlign} h-full focus:outline-none focus:ring-0 ${paddingX} bg-transparent w-full
            placeholder-current-70 ${state == 'disabled' && 'cursor-not-allowed'} `}
            />
            </div> {/* remove line */}
            {/*<span className={`flex-shrink-0 ${RightIconComponent && suffix ? `pr-${gapUnit}` : ''}`}>{suffix}</span> */}
            {RightIconComponent}
            </div>
            {helperText && <span className={messageClasses}>{helperText}</span>}    
        </div>
        
    );
}




/* ignore rest */

InputText.definitions = {
    apiName: 'InputText',
    displayName: 'Text Input',
    description: 'A text input field with customizable features such as label, placeholder, icons, and background color. Supports various states like placeholder, filled, disabled, warning, and success, and includes options for text alignment and size.',
    ai_instructions: 'text input field with label, helperText (use for info, hints or errors) and optional icons.',
    type: 'inputs',
    relativeSize: 'small',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'error', 'success'],
            default: 'default', 
            displayName: 'State',
            tile: '1/2',
        },
        bgColor: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'current-5', 'current-10', 'transparent'],
            default: 'base-50',
            displayName: 'Background',
            tile: '1/2',
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2',
        },
        
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2',
        },        
        
        label: {
            type: 'string',
            default: '', 
            displayName: 'Label',
            tile: '1/2',
            editable: true
        },

        textAlign: {
            type: 'oneOf',
            options: ['left', 'right', 'center'],
            default: 'left', 
            displayName: 'Align Text',
            tile: '1/2',
        },
        defaultValue: {
            type: 'string',
            default: '', 
            displayName: 'Default Value',
        },
        placeholder: {
            type: 'string',
            default: 'placeholder text', 
            displayName: 'Placeholder Text',
            required: true,
            ai_instructions: 'value example, e.g. "email@example.com" or "Acme Corp Inc". do not write placeholder like action "Enter text". limit to 3 words.'
        },
        helperText: {
            type: 'string',
            default: '', 
            displayName: 'Help Text (hint/error)',
            ai_instructions: 'the text under the field', 
            editable: true

        },
        name: {
            type: 'string',
            default: '', 
            displayName: 'Name',
            tile: '1/2',
        },
        type: {
            type: 'oneOf',
            options: ['text', 'password', 'email', 'number', 'tel', 'url'],
            default: 'text',
            displayName: 'Type',
            tile: '1/2',
        },
        /*prefix: {
            type: 'string',
            default: '',
            displayName: 'Prefix',
            ai_instructions: 'can be used for things https://', 
            tile: '1/2',
            
        },
        suffix: {
            type: 'string',
            default: '', 
            displayName: 'Suffix',
            ai_instructions: 'can be used for things like @gmail.com', 
            tile: '1/2',
        },*/
        leftIcon: {
            type: 'icon',
            options: ['none', ...allIconNames], 
            displayName: 'Left Icon',
            default: 'none'
        },
        rightIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Right Icon',
            default: 'none' 
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds 1px outline',
        }, 
        isPill: {
            type: "bool",
            displayName: "Pill",
            default: false, 
            ai_instructions: 'makes the input pill shaped',
        }
    }
};
