import React, { useContext, useState } from 'react';
import { DSContext } from 'pages/editor/DSContext';
import { Button, ButtonIcon, Divider, Heading, Icon } from '../../../../ui-kit/exports/react';
import EditTheme from './EditTheme';
import { updateThemeInDB } from 'api/ThemesAPI';
import UserContext from 'UserContext';
import Logos from './logos/Logos';
import ThemeSummary from './Summary';
import { EditorContext } from 'pages/editor/EditorContext';

export default function Themes() {
    
        const {user} = useContext(UserContext)
        const {ds, setDS, currentTheme, defaultTheme, selectTheme} = useContext(DSContext)
        const { project } = useContext(EditorContext);
        
        const [state, setState] = useState('summary')
        const themes = ds.themes
        
        
        const [selectedTheme, setSelectedTheme] = useState(null)

        function updateTheme(theme) {
            updateThemeInDB(theme, user?.token)
            setDS({...ds, themes: ds.themes.map(t=> t.id == theme.id ? theme : t)})
            selectTheme(theme.id)
        }

        function addTheme(theme) {
            
            // add theme: either copy or base on default theme
            const copyTheme = theme || defaultTheme
            
            // select new theme selectTheme(copyTheme.id)

            // edit theme
            setState('edit')
        }

        const headerMap = {
            'summary': 'Design System',
            'edit': 'Edit Theme',
            'add': 'New Theme',
            'assets': 'Edit Logos'
        }


        return (
            <div className="w-full flex flex-col h-full overflow-y-scroll">    
                    <div className='flex flex-row justify-start items-center w-full gap-2 p-3 bg-base-50 border-b border-base-200'>
                    <h1 className='text-md font-medium'>{headerMap[state]}</h1>
                    <div className={state != 'summary' ? '-order-1 flex gap-2 -ml-2' : 'opacity-0 flex gap-2'}>
                        <ButtonIcon
                            size={'small'}
                            style='ghost'
                            icon={'chevron-left'}
                            onClick={() => setState('summary')}
                        />
                    </div>
                    <div className={(state == 'edit' || state == 'add') ? 'flex gap-2 justify-end flex-grow' : 'hidden'}>
                        <ButtonIcon
                            size={'small'}
                            style='ghost'
                            icon={'shuffle'}
                        />
                    </div>
                    </div>
                    {state == 'edit' && <EditTheme 
                    onBack={() => setSelectedTheme(null)}
                    onSave={(theme) => updateTheme(theme)}
                    theme={currentTheme}
                    />}
                    {state == 'summary' && <ThemeSummary 
                        themes={themes} 
                        onSelect={selectTheme} 
                        currentTheme={currentTheme}
                        setState={setState}
                        onAdd={(theme) => addTheme(theme)}
                        project={project}
                        
                        />}

                    {state == 'assets' && <Logos />} 
                    
                    
                    
                </div>
        )
    }

