export const baseSaturation = 95
export const minFunctionalSaturation = 50

export const primaryColorDefault = {
    "primary": `hsl(195, 24%, 13%)`,
    "primary-focus": `hsl(195, 24%, 10%)`, 
    "primary-content": `hsl(195, 24%, 13%)`,
    "primary-surface": `hsl(195, 24%, 85%)`,
}

export const accentColorDefault = {
    "accent": `hsl(12, 98%, 32%)`,
    "accent-focus": `hsl(12, 98%, 27%)`,
    "accent-content": `hsl(12, 98%, 15%)`,
    "accent-surface": `hsl(12, 98%, 85%)`,
}

export const baseColorPresets = {
    tofu: {
        "base-0": `hsl(43, 8%, 100%)`,
        "base-50": `hsl(43, 8%, 97%)`,
        "base-100": `hsl(43, 8%, 92%)`,
        "base-200": `hsl(43, 8%, 84%)`,
        "base-300": `hsl(43, 8%, 70%)`,
        "base-400": `hsl(43, 8%, 60%)`,
        "base-500": `hsl(43, 8%, 50%)`,
        "base-600": `hsl(43, 8%, 40%)`,
        "base-700": `hsl(43, 8%, 30%)`,
        "base-800": `hsl(43, 8%, 20%)`,
        "base-content": `hsl(43, 4%, 15%)`,
        "base-900": `hsl(43, 8%, 10.0%)`, 
        
    },
    olive: {
        "base-0": `hsl(70, 12%, 100%)`,
        "base-50": `hsl(70, 12%, 97%)`,
        "base-100": `hsl(70, 12%, 92%)`,
        "base-200": `hsl(70, 12%, 84%)`,
        "base-300": `hsl(70, 12%, 70%)`,
        "base-400": `hsl(70, 12%, 60%)`,
        "base-500": `hsl(70, 12%, 50%)`,
        "base-600": `hsl(70, 12%, 40%)`,
        "base-700": `hsl(70, 12%, 30%)`,
        "base-800": `hsl(70, 21%, 20%)`,
        "base-content": `hsl(70, 4%, 15%)`,
        "base-900": `hsl(70, 12%, 0%)`
    },
    default: {
        "base-0": `hsl(210, 8%, 100%)`,
        "base-50": `hsl(210, 8%, 97%)`,
        "base-100": `hsl(210, 8%, 92%)`,
        "base-200": `hsl(210, 8%, 84%)`,
        "base-300": `hsl(210, 8%, 70%)`,
        "base-400": `hsl(210, 8%, 60%)`,
        "base-500": `hsl(210, 8%, 50%)`,
        "base-600": `hsl(210, 8%, 40%)`,
        "base-700": `hsl(210, 8%, 30%)`,
        "base-800": `hsl(210, 8%, 20%)`,
        "base-content": `hsl(210, 4%, 15%)`,
        "base-900": `hsl(210, 8%, 10%)`
    }, 
    marine: {
        'base-0': 'hsl(215, 15%, 100%)',
        'base-50': 'hsl(215, 15%, 97%)',
        'base-100': 'hsl(215, 15%, 92%)',
        'base-200': 'hsl(215, 15%, 84%)',
        'base-300': 'hsl(215, 15%, 70%)',
        'base-400': 'hsl(215, 15%, 60%)',
        'base-500': 'hsl(215, 15%, 50%)',
        'base-600': 'hsl(215, 15%, 40%)',
        'base-700': 'hsl(215, 15%, 30%)',
        'base-800': 'hsl(215, 15%, 20%)',
        'base-content': 'hsl(215, 5%, 15%)',
        'base-900': 'hsl(215, 15%, 10%)',
    },
    gray: {
        "base-0": `hsl(0, 0%, 100%)`,
        "base-50": `hsl(0, 0%, 97%)`,
        "base-100": `hsl(0, 0%, 92%)`,
        "base-200": `hsl(0, 0%, 84%)`,
        "base-300": `hsl(0, 0%, 70%)`,
        "base-400": `hsl(0, 0%, 60%)`,
        "base-500": `hsl(0, 0%, 50%)`,
        "base-600": `hsl(0, 0%, 40%)`,
        "base-700": `hsl(0, 0%, 30%)`,
        "base-800": `hsl(0, 0%, 20%)`,
        "base-content": `hsl(0, 0%, 15%)`,
        "base-900": `hsl(0, 0%, 10%)`
    }, 
    muddy: {
        "base-0": `hsl(52, 12%, 100%)`,
        "base-50": `hsl(52, 12%, 97%)`,
        "base-100": `hsl(52, 12%, 92%)`,
        "base-200": `hsl(52, 12%, 84%)`,
        "base-300": `hsl(52, 12%, 70%)`,
        "base-400": `hsl(52, 12%, 60%)`,
        "base-500": `hsl(52, 12%, 50%)`,
        "base-600": `hsl(52, 12%, 40%)`,
        "base-700": `hsl(52, 12%, 30%)`,
        "base-800": `hsl(52, 12%, 20%)`,
        "base-content": `hsl(52, 12%, 15%)`,
        "base-900": `hsl(52, 12%, 10%)`
    },
    murky: {
        "base-0": `hsl(189, 12%, 100%)`,
        "base-50": `hsl(189, 12%, 97%)`,
        "base-100": `hsl(189, 12%, 92%)`,
        "base-200": `hsl(189, 12%, 84%)`,
        "base-300": `hsl(189, 12%, 70%)`,
        "base-400": `hsl(189, 12%, 60%)`,
        "base-500": `hsl(189, 12%, 50%)`,
        "base-600": `hsl(189, 12%, 40%)`,
        "base-700": `hsl(189, 12%, 30%)`,
        "base-800": `hsl(189, 12%, 20%)`,
        "base-content": `hsl(189, 12%, 15%)`,
        "base-900": `hsl(189, 12%, 10%)`
    },
    raisin: {
        "base-0": `hsl(300, 4%, 0%)`,
        "base-50": `hsl(300, 4%, 3%)`,
        "base-100": `hsl(300, 4%, 8%)`,
        "base-200": `hsl(300, 4%, 16%)`,
        "base-300": `hsl(300, 4%, 30%)`,
        "base-400": `hsl(300, 4%, 40%)`,
        "base-500": `hsl(300, 4%, 50%)`,
        "base-600": `hsl(300, 4%, 60%)`,
        "base-700": `hsl(300, 4%, 70%)`,
        "base-800": `hsl(300, 4%, 80%)`,
        "base-content": `hsl(300, 4%, 85%)`,
        "base-900": `hsl(300, 4%, 90%)`
    },
    slytherin: {
        "base-0": `hsl(145, 8%, 0%)`,
        "base-50": `hsl(145, 8%, 3%)`,
        "base-100": `hsl(145, 8%, 8%)`,
        "base-200": `hsl(145, 8%, 16%)`,
        "base-300": `hsl(145, 8%, 30%)`,
        "base-400": `hsl(145, 8%, 40%)`,
        "base-500": `hsl(145, 8%, 50%)`,
        "base-600": `hsl(145, 8%, 60%)`,
        "base-700": `hsl(145, 8%, 70%)`,
        "base-800": `hsl(145, 8%, 80%)`,
        "base-content": `hsl(145, 8%, 85%)`,
        "base-900": `hsl(145, 8%, 90%)`
    },
    ash: {
        "base-0": `hsl(0, 0%, 0%)`,
        "base-50": `hsl(0, 0%, 3%)`,
        "base-100": `hsl(0, 0%, 8%)`,
        "base-200": `hsl(0, 0%, 16%)`,
        "base-300": `hsl(0, 0%, 30%)`,
        "base-400": `hsl(0, 0%, 40%)`,
        "base-500": `hsl(0, 0%, 50%)`,
        "base-600": `hsl(0, 0%, 60%)`,
        "base-700": `hsl(0, 0%, 70%)`,
        "base-800": `hsl(0, 0%, 80%)`,
        "base-content": `hsl(0, 0%, 85%)`,
        "base-900": `hsl(0, 0%, 90%)`
    },

}


export const functionColorsPresets = {
    light: {
        cool: {
            "error-surface": `hsl(30, 100%, 92%)`,
            "error": `hsl(30, 100%, 45%)`,
            "error-focus": `hsl(30, 90%, 50%)`,
            "error-content": `hsl(30, 90%, 25%)`,
            
            "warning-surface": `hsl(330, 100%, 92%)`,
            "warning": `hsl(330, 100%, 47%)`,
            "warning-focus": `hsl(330, 90%, 50%)`,
            "warning-content": `hsl(330, 90%, 25%)`,
            
            "success-surface": `hsl(140, 100%, 92%)`,
            "success": `hsl(140, 100%, 34%)`,
            "success-focus": `hsl(140, 90%, 40%)`,
            "success-content": `hsl(140, 90%, 25%)`,

            "info-surface": `hsl(210, 100%, 92%)`,
            "info": `hsl(220, 100%, 41%)`,
            "info-focus": `hsl(220, 90%, 50%)`,
            "info-content": `hsl(220, 90%, 25%)`,
            
        },
        default: {
            "error-surface": `hsl(40, 100%, 92%)`,
            "error": `hsl(40, 100%, 48%)`,
            "error-focus": `hsl(40, 90%, 53%)`,
            "error-content": `hsl(40, 90%, 25%)`,

            "warning-surface": `hsl(20, 100%, 92%)`,
            "warning": `hsl(20, 100%, 47%)`,
            "warning-focus": `hsl(20, 90%, 50%)`,
            "warning-content": `hsl(15, 90%, 25%)`,

            "success-surface": `hsl(100, 100%, 92%)`,
            "success": `hsl(110, 100%, 34%)`,
            "success-focus": `hsl(110, 90%, 40%)`,
            "success-content": `hsl(110, 90%, 25%)`,

            "info-surface": `hsl(210, 100%, 92%)`,
            "info": `hsl(210, 100%, 41%)`,
            "info-focus": `hsl(210, 90%, 50%)`,
            "info-content": `hsl(210, 90%, 25%)`,
        },
        warm: {
            "error-surface": `hsl(35, 100%, 92%)`,
            "error": `hsl(35, 100%, 48%)`,
            "error-focus": `hsl(35, 90%, 53%)`,
            "error-content": `hsl(35, 90%, 25%)`,

            "warning-surface": `hsl(16, 100%, 92%)`,
            "warning": `hsl(10, 100%, 47%)`,
            "warning-focus": `hsl(12,90%, 50%)`,
            "warning-content": `hsl(12, 90%, 25%)`,
            
            "success-surface": `hsl(80, 100%, 92%)`,
            "success": `hsl(80, 100%, 34%)`,
            "success-focus": `hsl(80, 90%, 40%)`,
            "success-content": `hsl(105, 90%, 25%)`,

            "info-surface": `hsl(204, 100%, 92%)`,
            "info": `hsl(204, 100%, 41%)`,
            "info-focus": `hsl(210, 90%, 50%)`,
            "info-content": `hsl(202, 90%, 25%)`,

        },
    }, 
    dark: {
        cool: {
        "error-surface": `hsl(30, 100%, 15%)`, 
        "error": `hsl(30, 90%, 40%)`, 
        "error-focus": `hsl(30, 90%, 20%)`,
        "error-content": `hsl(30, 100%, 90%)`, 

        "warning-surface": `hsl(330, 100%, 15%)`, 
        "warning": `hsl(330, 55%, 50%)`, 
        "warning-focus": `hsl(330, 90%, 20%)`,
        "warning-content": `hsl(330, 100%, 90%)`, 

        "success-surface": `hsl(140, 100%, 15%)`, 
        "success": `hsl(140, 90%, 40%)`, 
        "success-focus": `hsl(140, 90%, 20%)`,
        "success-content": `hsl(140, 100%, 90%)`, 

        "info-surface": `hsl(220, 100%, 15%)`, 
        "info": `hsl(220, 75%, 55%)`, 
        "info-focus": `hsl(220, 90%, 20%)`,
        "info-content": `hsl(220, 100%, 90%)`, 
    },
    default: {
        "error-surface": `hsl(40, 100%, 15%)`, 
        "error": `hsl(40, 90%, 40%)`, 
        "error-focus": `hsl(40, 90%, 20%)`,
        "error-content": `hsl450, 100%, 90%)`,

        "warning-surface": `hsl(25, 100%, 15%)`, 
        "warning": `hsl(25, 55%, 50%)`, 
        "warning-focus": `hsl(25, 90%, 20%)`,
        "warning-content": `hsl(25, 100%, 90%)`, 

        "success-surface": `hsl(110, 100%, 15%)`, 
        "success": `hsl(100, 90%, 40%)`, 
        "success-focus": `hsl(100, 90%, 20%)`,
        "success-content": `hsl(100, 100%, 90%)`, 

        "info-surface": `hsl(210, 100%, 15%)`, 
        "info": `hsl(210, 75%, 55%)`, 
        "info-focus": `hsl(210, 90%, 20%)`,
        "info-content": `hsl(210, 100%, 90%)`,
    },
        warm: {
            "error-surface": `hsl(50, 100%, 15%)`, 
            "error": `hsl(50, 90%, 40%)`, 
            "error-focus": `hsl(50, 90%, 20%)`,
            "error-content": `hsl(50, 100%, 90%)`,

            "warning-surface": `hsl(16, 100%, 15%)`, 
            "warning": `hsl(16, 55%, 50%)`, 
            "warning-focus": `hsl(16, 90%, 20%)`,
            "warning-content": `hsl(16, 100%, 90%)`, 

            "success-surface": `hsl(105, 100%, 15%)`, 
            "success": `hsl(80, 90%, 40%)`, 
            "success-focus": `hsl(80, 90%, 20%)`,
            "success-content": `hsl(80, 100%, 90%)`, 

            "info-surface": `hsl(204, 100%, 15%)`, 
            "info": `hsl(204, 75%, 55%)`, 
            "info-focus": `hsl(204, 90%, 20%)`,
            "info-content": `hsl(204, 100%, 90%)`, 

        }
    }, 
}


export const cornersPresets = {
    square: {
        "border-radius-xs": "0.125rem",
        "border-radius-sm": "0.125rem",
        "border-radius-base": "0.125rem",
        "border-radius-md": "0.125rem",
        "border-radius-lg": "0.125rem",
        "border-radius-xl": "0.125rem",
        "border-radius-2xl": "0.125rem",
        "border-radius-3xl": "0.125rem",
    }, 
    sharp: {
        "border-radius-xs": "0.125rem",
        "border-radius-sm": '0.125rem',
        "border-radius-base": '0.25rem',
        "border-radius-md": '0.375rem',
        "border-radius-lg": '0.5rem',
        "border-radius-xl": '0.75rem',
        "border-radius-2xl": '1rem',
        "border-radius-3xl": '1.5rem',
    },
    default: { // this is standard Tailwind's rounded class
        "border-radius-xs": '0.125rem',
        "border-radius-sm": '0.25rem',
        "border-radius-base": '0.375rem',
        "border-radius-md": '0.5rem',
        "border-radius-lg": '0.75rem',
        "border-radius-xl": '1rem',
        "border-radius-2xl": '1.5rem',
        "border-radius-3xl": '2rem',
    },
    soft: {
        "border-radius-xs": '0.125rem',
        "border-radius-sm": '0.25rem',
        "border-radius-base": '0.5rem',
        "border-radius-md": '0.75rem',
        "border-radius-lg": '1rem',
        "border-radius-xl": '1.5rem',
        "border-radius-2xl": '2rem',
        "border-radius-3xl": '3rem',
    },/*
    round: {
        "border-radius-xs": '0.25rem',
        "border-radius-sm": '0.5rem',
        "border-radius-base": '0.75rem',
        "border-radius-md": '1rem',
        "border-radius-lg": '1.5rem',
        "border-radius-xl": '2rem',
        "border-radius-2xl": '3rem',
        "border-radius-3xl": '4rem',
    }*/
}