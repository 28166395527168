import { useContext } from 'react';
import { EditorContext } from '../../../EditorContext';
import * as SampleLogos from '../../../../../ui-kit/local/assets/brand/assets.js';

// Import default assets
import UploadLogo from './uploadLogoAsset.js';
import DeleteLogo from './deleteLogoAsset.js';

export default function Brand() {
    const { project} = useContext(EditorContext);
    const assets = project?.assets;

    // Function to find asset by its API name and return both project and default assets
    const findAsset = (apiName, defaultImage) => {
        const projectAsset = assets?.find(asset => asset.api_name === apiName)
        return {
            default: defaultImage,
            projectAsset: projectAsset
        };
    };
    const useLogo = 'sample'
    const sample_symbol = SampleLogos[useLogo+'_symbol']
    const sample_logo = SampleLogos[useLogo]
    const sample_symbol_inverted = SampleLogos[useLogo+'_symbol_inverted']
    const sample_logo_inverted = SampleLogos[useLogo+'_inverted']

    // Define asset map
    const assetMap = {
        'logo': {
            displayName: 'standard logo', 
            asset: findAsset('logo', sample_logo),
            bgColor: 'base-0',
            height: 32
        },
        'logo_inverted': {
            displayName: 'inverted logo', 
            asset: findAsset('logo_inverted', sample_logo_inverted),
            bgColor: 'primary',
            height: 32
        },
        'symbol': {
            displayName: 'standard icon', 
            asset: findAsset('symbol', sample_symbol),
            bgColor: 'base-200',
            width: 40,
            height: 40
        },
        'symbol_inverted': {
            displayName: 'inverted icon', 
            asset: findAsset('symbol_inverted', sample_symbol_inverted),
            bgColor: 'accent',
            width: 40,
            height: 40
        }
    };

    const logos = ['magik_corp', 'hellodoc', 'sample'];
    
    return (
        <div className="w-full flex flex-col h-full pl-3">
            {/*<div className='w-32 mb-4'>
                <AddDefaults
                    user={user}
                    project={project}
                    assets={assets}
                
                />
            </div> */}
                
          <div className="flex flex-col w-full h-full bg-base-50 border border-base-100 rounded-lg">
            <div className="grid grid-cols-2 w-full h-full gap-6 p-6">
            
                {Object.entries(assetMap).map(([key, { asset, bgColor, width, height, displayName }]) => (
                    <LogoTile
                        key={key}
                        projectAsset={asset.projectAsset}
                        displayName={displayName}
                        defaultAsset={asset.default}
                        apiname={key}
                        bgColor={bgColor}
                        width={width}
                        height={height}
                    />
                ))}
            </div>
          </div>  
        </div>
    );
}


function LogoTile({bgColor, projectAsset, defaultAsset, apiname, displayName,  width, height}) {
    
    const color = !bgColor?.startsWith('base') ? bgColor : 'base'
    const imageSrc = projectAsset?.file_url || defaultAsset
    
    const cellClasses = `w-full h-full flex items-center justify-center rounded-md relative group bg-${bgColor} text-${color}-content`
    const isDefault = !projectAsset

    return (
        <div className={cellClasses}>
                <div className="absolute top-4 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-50 transition-all duration-150
                text-xs font-medium
                ">
                    {displayName}
                </div>
                <div className="relative p-3">
                

                <ComponentDecoration 
                    color={`color-mix(in srgb, var(--${color}-content) 24%, transparent)`} 
                    offset={1} 
                    
                />
                {imageSrc && (
                    <img 
                        src={imageSrc} 
                        alt="symbol_inverted"
                        style={{
                            height: height,  // Use the height prop
                            maxWidth: '100%', // Limit width to 100% of its container
                            width: width ? width : 'auto'  // Use width if provided, otherwise 'auto'
                        }}
                    />
                )}
                </div>

                {/* buttons */}
                <div className="absolute bottom-4 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100
                transition-all duration-150 flex flex-row gap-2 items-center
                ">
                    <UploadLogo color={color} api_name={apiname} currentAsset={projectAsset} />
                    {!isDefault && <DeleteLogo color={color} assetId={projectAsset.id} />}
                </div>
        </div>
    )
}


function ComponentDecoration({color='var(--base-300)', offset=3}) {
    
    return (
        <>
            <div className="absolute top-0 left-0 z-50 opacity-50 group-hover:opacity-100 transition-all" 
                style={{
                    width: `calc(100% + ${offset * 2}rem)`, marginLeft: `-${offset}rem`, height: 1, 
                    background: `linear-gradient(to right, transparent, ${color} 10%, ${color} 90%, transparent)`
                }} />
            <div className="absolute bottom-0 left-0 z-50 opacity-50 group-hover:opacity-100 transition-all" 
                style={{
                    width: `calc(100% + ${offset * 2}rem)`, marginLeft: `-${offset}rem`, height: 1, 
                    background: `linear-gradient(to right, transparent, ${color} 10%, ${color} 90%, transparent)`
                }} />
            <div className="absolute top-0 left-0 z-50 opacity-50 group-hover:opacity-100 transition-all"  
                style={{
                    height: `calc(100% + ${offset * 2}rem)`, marginTop: `-${offset}rem`, width: 1, 
                    background: `linear-gradient(to bottom, transparent, ${color} 10%, ${color} 90%, transparent)`
                }} />
            <div className="absolute top-0 right-0 z-50 opacity-50 group-hover:opacity-100 transition-all" 
                style={{
                    height: `calc(100% + ${offset * 2}rem)`, marginTop: `-${offset}rem`, width: 1, 
                    background: `linear-gradient(to bottom, transparent, ${color} 10%, ${color} 90%, transparent)`
                }} />
        </>
    );   
}



