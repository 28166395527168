import { Select } from "ui-kit/exports/react";

export default function FontPicker({ theme, setTheme, baseFonts, accentFonts }) {

    return (
        <div className='flex flex-row gap-2 items-start relative'>
            <Select
                label='Base Font'
                width='full'
                options={baseFonts.map(font => ({ value: font }))}
                value={theme.font}
                bgColor='base-0'
                hasOutline
                // helperText='Used in Body Text'
                onChange={(val) => setTheme({...theme, font: val})}
            />
            <Select
                label='Accent Font'
                width='full'
                bgColor='base-0'
                hasOutline
                // helperText='Used in Headings'
                options={accentFonts.map(font => ({ value: font }))}
                value={theme.accent_font}
                onChange={(val) => setTheme({...theme, accent_font: val})}
            />
        </div>
    );
}