import { useEffect, useRef, useState } from 'react';
import { Icon } from '../';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus, coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { hi } from 'date-fns/locale';

type MiniSnippetProps = {
    bgColor?: 'base-50' | 'base-100' | 'base-200' | 'current-2',
    size?: 'small' | 'medium',
    text?: string,
    width?: 'auto' | '1/2' | 'full',
    whiteSpace?: 'wrap' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'pre' | 'normal',
    highlightSyntax?: 'javascript' | 'python' | 'html' | 'jsx',
    copyButton?: boolean,
    hasOutline?: boolean,
    maxHeight?: number,
    __juno?: any,
}

export default function MiniSnippet({
        bgColor = 'base-50',
        size = 'small',
        text = `Hello, World!`,
        width = 'full',
        whiteSpace = 'pre',
        highlightSyntax,
        copyButton = true,
        hasOutline = false,
        maxHeight,
        __juno = {},
    }: MiniSnippetProps) {
    
    const widthStyle = width == 'auto' ? `w-auto` : `w-${width} max-w-${width}`
    const sizeStyles =  size == 'small' ? `py-1.5 px-3 gap-2 text-xs` : `py-2.5 px-4 gap-3 text-sm`;
    const cornerStyles = size == "small" ? "rounded" :  "rounded-md"
    const bgStyles = `bg-${bgColor}` 
    const borderStyles = hasOutline ? `ring-[0.5px] ring-inset ring-current-20` : ``

    const classes = `flex flex-col ${widthStyle} ${borderStyles} ${cornerStyles} ${bgStyles} relative group`

    let contentClasses = `flex flex-row justify-between font-medium items-start overflow-y-scroll relative group flex-shrink-0 font-mono select-text  ${sizeStyles} w-full`
    contentClasses += ` !select-none` /* remove line */


    const [copied, setCopied] = useState(false)

    const copyToClipboard = () => {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); 
      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
    }
    const buttonOffsets = size == 'small' ? 'top-1 right-1.5' : 'top-2 right-2.5'

    const [expanded, setExpanded] = useState(false); 
    const [isOverflowing, setIsOverflowing] = useState(false);

    const contentRef = useRef(null);

    useEffect(() => {  
       if (!maxHeight || maxHeight == null) return;
        if (contentRef.current) {
            setIsOverflowing(contentRef.current.scrollHeight > maxHeight);
        }
    }, [maxHeight, text]);



    return (
        <div
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
        >
            {highlightSyntax ? <SyntaxHighlighter
                language={highlightSyntax}
                style={coy}
                customStyle={{ backgroundColor: 'transparent', margin: 0, }}
                codeTagProps={{
                    style: {
                    backgroundColor: 'transparent',
                    fontFamily: 'IBM Plex Mono, monospace',
                    },
                }}
                >
                {text}
                </SyntaxHighlighter> :
            <pre 
                style={{ whiteSpace, maxHeight: expanded ? 'none' : maxHeight, overflowX: 'scroll', overflowY: 'hidden', minHeight: '1.5em'}} className={contentClasses}
                ref={contentRef}>
                    <code>
                {text}
                </code>
            </pre>}

            {/* COPY BUTTON */}
            {copyButton && <div 
                className={`absolute ${buttonOffsets} ${bgStyles} transition-all rounded z-10 flex items-center justify-center cursor-pointer group p-0.5`}
                /* replace to '                onClick={copyToClipboard}' */
            >
                <Icon icon={copied ? 'check' : 'copy'} className={`hover:scale-110 transition-all duration-150 ${size == "small" ? "w-4 h-4" : "w-5 h-5"}`} size={size =='small' ? '16px' : '20px'} />
            </div>}

             {/* SHOW MORE BUTTON */}
            {maxHeight && isOverflowing &&  (
                <button 
                    className={`py-2 self-center mx-auto text-xs text-primary opacity-50 hover:opacity-100 transition-all duration-150`} 
                    onClick={() => setExpanded(!expanded)}
                >
                    {!expanded ? 'Expand' : 'Collapse'}
                </button>
            )}
        </div>
    );
}



/* ignore rest */

MiniSnippet.definitions = {
    apiName: 'MiniSnippet',
    displayName: 'Mini Snippet',
    description: 'A compact snippet component for displaying brief code or commands. Offers customizable background and size options, with editable content. Ideal for concise code representation and easy copying.',
    ai_instructions: 'mini snippet component for brief code or command display. Suitable for concise code snippets. Contains a small copy icon.',
    type: 'code',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'stable',
    package: 'Starter',
    propDefinitions: {
        width: {
            type: 'width',
            options: ['auto', '1/2', 'full'],
            displayName: 'Width',
            default: 'auto', 
            tile: '1/2'
        },
        size: {
            type: 'size',
            options: ['small', 'medium'],
            displayName: 'Font Size',
            default: 'small', 
            tile: '1/2'
        },
        bgColor: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200'],
            displayName: 'Background',
            default: 'base-100', 
        },
        text: {
            type: 'longString',
            displayName: 'Code',
            default: 'Hello, World!',
            ai_instructions: 'sample code usually no more than 200 characters', 
            editable: true
        }, 
        copyButton: {
            type: 'bool',
            displayName: 'Copy Button',
            default: true
        },
        maxHeight: {
            type: 'number',
            displayName: 'Max Height',
            default: null
        },
        whiteSpace: {
            type: 'oneOf',
            options: ['wrap', 'nowrap', 'pre-wrap', 'pre-line', 'pre', 'normal'],
            displayName: 'Text Wrap',
            default: 'wrap'
        },
        hasOutline: {
            type: 'bool',
            displayName: 'Outline',
            default: true
        },
        highlightSyntax: {
            type: 'oneOf',
            options: ['javascript', 'python', 'html', 'jsx'],
            displayName: 'Syntax Highlight',
            default: null
        }
    }
};