import React, { useEffect, useRef, useState } from 'react';
import { baseColorPresets, functionColorsPresets } from './presets';
import { Select } from '../../../../ui-kit/exports/react';

// const baseColorNames = ['base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'base-400', 'base-500', 'base-600', 'base-700', 'base-content', 'base-800', 'base-900'];

const recognizePalette = (paletteObject, baseColorPresets) => {
    for (const [paletteName, preset] of Object.entries(baseColorPresets)) {
        if (
            Object.keys(paletteObject).length === Object.keys(preset).length && // Check if lengths match
            Object.entries(paletteObject).every(
                ([key, value]) => preset[key] === value // Compare each key-value pair
            )
        ) {
            return paletteName; // Return the matching key
        }
    }
    return 'custom'; // No match found
};

export default function BaseColorPicker({ theme, onSave}) {
    
    const elementRef = useRef(null);    
    const paletteObject = Object.fromEntries(Object.entries(theme.variables).filter(([key]) => key.includes('base-')));
    const setOptions = Object.keys(baseColorPresets).map(paletteName => ({label: paletteName, value: paletteName}));
    const displayFilter = ['base-100', 'base-300', 'base-500', 'base-content']    
    const paletteName = recognizePalette(paletteObject, baseColorPresets);

    const handleSelect = (setName) => {
        const darkPalettes = ['ash', 'raisin', 'slytherin']
        const newIsDark = darkPalettes.includes(setName);
        const currentIsDark = theme.isDark;

        let newFunctionalColors = {}
        if (newIsDark !== currentIsDark) {
            const pullSet = newIsDark ? functionColorsPresets.dark : functionColorsPresets.light;
            newFunctionalColors = pullSet['default'];
        }

        const newBaseColors = baseColorPresets[setName];
        onSave({
            ...theme,
            isDark: newIsDark,
            variables: {
                ...theme.variables,
                ...newBaseColors,
                ...newFunctionalColors
            }
        })
    };

    return (
        <div className='flex gap-2 flex-grow h-7 justify-between' ref={elementRef}>
            {/* Selected item display */}
            <div className="flex flex-row gap-0 h-full w-full transition-all rounded-md justify-between">
                {Object.entries(paletteObject)
                    .filter(([shade, color]) => displayFilter.includes(shade))
                    .map(([shade, color], index) => ( // Include the index parameter here
                        <div
                            key={shade}
                            className="w-1/4 h-full first:rounded-l-md last:rounded-r-md"
                            style={{
                                backgroundColor: color as string,
                            }}
                            title={`${paletteName} - ${shade}`}
                            aria-label={`Select ${paletteName} shade ${shade}`}
                        ></div>
                    ))}
                    </div>

        
        <span className="w-1/2 flex-shrink-0"><Select 
            size='small'
            bgColor='base-0'
            hasOutline
            width='full'
            value={paletteName}
            options={setOptions}
            onChange={(setName) => handleSelect(setName)}
        />
    
         </span>
        
        </div>
        
    );
}


/*    {/* Dropdown for other options 
{isDropdownOpen && (
    <div className="absolute -bottom-2 left-1/2 -translate-x-2/3 translate-y-full
    bg-base-0 border border-base-200 rounded-lg p-1 shadow-lg z-10">
        palettes
        {Object.keys(allPalettes)
            // .filter(setName => setName !== currentSet)
            .map(setName => (
                <div key={setName} 
                    className={`flex flex-row gap-2 items-center p-1 justify-between w-full rounded-md transition-all
                    ${setName == currentSet ? 'bg-base-100' : 'bg-base-0 hover:bg-base-100'}`}
                    // onMouseOver={() => currentSet !== setName && handleSelect(setName)}
                    // onMouseLeave={() => currentSet !== setName && handleSelect(savedSet || currentSet)}
                    onClick={() => {
                        setSavedSet(setName);
                        setDropdownOpen(false);
                        handleSelect(setName);
                    }}
                >
                    {/*<div className="flex flex-row gap-0 overflow-hidden flex-grow">
                        {Object.entries(allPalettes[setName])
                        .filter(([shade, color]) => displayFilter.includes(shade))
                        .map(([shade, color], index) => ( // Include the index parameter here
                            <div
                                key={shade}
                                className="rounded-full"
                                style={{
                                    backgroundColor: color as string,
                                    width: circleSize * 0.8,
                                    height: circleSize * 0.8,
                                    marginLeft: index > 0 ? `-${(circleSize * 0.8 )/ 3}px` : 0
                                }}
                                title={`${setName} - ${shade}`}
                                aria-label={`Select ${setName} shade ${shade}`}
                            ></div>
                        ))}
                    </div>}
                    <span className='truncate'>
                        {setName}
                    </span>
                </div>
            ))
    </div>
)}
*/