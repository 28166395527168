import { ArrowRight, EditPencil } from "iconoir-react";
import { convertThemeVariablesToStyles } from "../../canvas/helpers";

export default function ThemePreview({
  theme,
  isSelected = false,
  onSelect,
  onEdit,
}) {
  
  const variables = theme?.variables || [];
  const themeStyles = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [`--${key}`, value])
);
  const colorSizing = `w-1/5 h-8`

  return (
    <div
      className={`w-full flex flex-col items-center justify-start gap-2.5 text-xs rounded-md cursor-pointer
        transition-all duration-75 text-base-content overflow-hidden relative group font-light
        ${isSelected ? 'ring-1 ring-primary' : ''} bg-base-50 px-3 pt-3 pb-2
        hover:bg-base-100 text-base-content
        `}  
        
      onClick={() => onSelect(theme.id)}
      onDoubleClick={() => {onSelect(theme.id); onEdit()}}
    >
      <div className="flex flex-row w-4/5 mx-auto flex-shrink-0 items-start rounded overflow-hidden shadow"
     style={{...themeStyles}} 
      >
        <div className={`${colorSizing} bg-primary`} />
        <div className={`${colorSizing} bg-accent`} />
        <div className={`${colorSizing} bg-base-300`} />
        <div className={`${colorSizing} bg-base-100`} />
        <div className={`${colorSizing} bg-base-0`} />
        
        
      </div>
      {theme.display_name}
    </div>
  );
}
