import { SegmentedSwitch, Select } from "../../../../ui-kit/exports/react";
import IconsPicker from "./IconsPicker";

const baseFonts = ['Inter', 'Lato', 'Montserrat', 'Geologica', 
  'Merriweather', 'Poppins', 'Noto Sans', 'Work Sans', 'Manrope', 'Baloo 2', 'IBM Plex Sans', 
  'Onest', 'Sora', 'Kanit', 'Parkinsans', 'Plus Jakarta Sans', 'Geist', 'Nunito', 
]
const accentFonts = ['Inter', 'Lato', 'Montserrat', 'Geologica', 
  'Merriweather', 'Poppins', 'Noto Sans', 'Work Sans', 'Manrope', 'Baloo 2', 'IBM Plex Sans', 
  'Onest', 'Sora', 'Kanit', 'Parkinsans', 'Plus Jakarta Sans', 'Geist', 'Nunito']

const monoFonts = ['IBM Plex Mono', 'Space Mono', 'PT Mono']

const weightOptions = [
    {"label":"Light","value":"light"},
    {"label":"Normal","value":"default"},
    {"label":"Bold","value":"bold"}
  ]

export default function Typography({theme, onSave}) {

  const bodyFont = theme.variables['font-body'] || 'Inter';
  const headingFont = theme.variables['font-accent'] || 'Inter';
  const monoFont = theme.variables['font-mono'] || 'IBM Plex Mono';
  const weight = theme.config.fontWeight || 'default';

return (
<div className="flex flex-col flex-nowrap w-full text-auto gap-3 items-start justify-start  p-3">
  <h1 className="text-ellipsis font-accent whitespace-pre-wrap text-base font-medium text-left">
    Typography
  </h1>
  <div className="flex flex-col flex-nowrap w-full text-auto gap-3 items-start justify-start">
    <div className="flex gap-3 text-xs items-center w-full">
    <span className="w-1/4 flex-shrink-0">Body</span>
    <div className="flex-grow ">
    <Select 
      options={baseFonts.map(font => ({ label: font, value: font }))} 
      value={bodyFont}
      onChange={(value) => onSave({...theme, variables: {...theme.variables, 'font-body': value}})}
      width="full" 
      hasOutline 
      bgColor="base-0" 
      size="small"
    /></div>
    </div>
    <div className="flex gap-3 text-xs items-center w-full">
    <span className="w-1/4 flex-shrink-0">Headings</span>
    <div className="flex-grow ">
    <Select 
      options={accentFonts.map(font => ({ label: font, value: font }))}
      value={headingFont}
      onChange={(value) => onSave({...theme, variables: {...theme.variables, 'font-accent': value}})}
      size="small"
      width="full" 
      hasOutline 
      bgColor="base-0" 
    />
    </div>
    </div>
      <div className="flex gap-3 text-xs items-center w-full">
        <span className="w-1/4 flex-shrink-0"></span>
        <div className="flex-grow ">
          <SegmentedSwitch 
            size="small"
            width="full"
            options={weightOptions}
            value={weight}
            onChange={(value) => onSave({...theme, config: {...theme.config, fontWeight: value}})}
            />
          </div>
      </div>

      <div className="flex gap-3 text-xs items-center w-full">
        <span className="w-1/4 flex-shrink-0">Icons</span>
        <div className="flex-grow">
          <IconsPicker theme={theme} onSave={onSave} />
        </div>
      </div>
      
      
  </div>
</div>)
}