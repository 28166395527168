// Import each asset at the top
import { logo, logo_inverted, symbol, symbol_inverted } from '../assets/brand/assets.js';

export type ThemeConfig = {
    is_rounded?: boolean,
    outlines?: 'light' | 'strong' | 'none',
    iconset: "feather" | "ionic" | "material" | "heroicons" | "iconoir",
    brand: {
        logo: string,
        logo_inverted: string,
        symbol: string,
        symbol_inverted: string
    },
    fontWeight: 'light' | 'default' | 'bold'
}

export const config = {
    is_rounded: false,
    outlines: 'none',
    iconset: 'iconoir',
    brand: {
        logo: logo,
        logo_inverted: logo_inverted, 
        symbol: symbol,
        symbol_inverted: symbol_inverted
    },
    fontWeight: 'default'
};