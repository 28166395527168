import React from 'react';
import { spacingMap } from '../helpers';

type SidebarProps = {
    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'base-700' | 'base-content' | 'primary' | 'accent' | string,
    width?: 'auto' | '64px' | '240px' | '280px' | '320px' | '360px' | '480px' | '100%',
    paddingX?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px',
    paddingY?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px',
    gap?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch',
    justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly',
    position?: 'left' | 'right',
    hasOutline?: boolean,
    textSize?: 'sm' | 'base' | 'md',
    display?: boolean,
    children?: React.ReactNode,
    __juno?: any,
}

export default function Sidebar({
        background = 'base-0',

        paddingX,
        paddingY,
        gap,
        
        position = 'left',
        hasOutline = true,
        
        alignItems = 'stretch',
        justifyContent = 'start',
        display = true,
        width = '280px',
        textSize = 'base',
        children,
        __juno = {}
      }: SidebarProps) {
    
    // STYLES
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';

    const fontSize = `text-`+textSize
    const bgStyles = background ? `bg-${background}` : '';
    const borderStyles = !hasOutline ? '' : position == 'left' ? 'border-r' : 'border-l';
    
    const borderColor = `color-mix(in srgb, var(--base-content) 12%, transparent)`

    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    const fontColor = (!background || background == 'none') ? '' : (background?.startsWith('base') && background != 'base-content' && background != 'base-700') ? 'text-base-content' : `text-base-0`
    
    const classes = `hidden @lg:flex flex-col relative flex-grow-0 z-40 flex-shrink-0 transition-all 
    ${paddingStyles} ${fontColor} ${fontSize} ${bgStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles} ${borderStyles} `

    if (display) return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        
        style={{
            order: position == 'left' ? '-2' : 2, 
            width: width,
            maxWidth: width,
            minWidth: width,
            borderColor: borderColor,
            minHeight: '100%',
        }}>
        {children}
        </div>
    );
}




/* ignore rest */

Sidebar.definitions = {
    apiName: 'Sidebar',
    displayName: 'Sidebar',
    description: 'A flexible and customizable sidebar component for various layouts, supporting features like adjustable padding, background color, border positioning, gap, alignment, and width.',
    ai_instructions: 'vertical navigation bar with icon buttons. has flex-col property',
    type: 'section',
    relativeSize: 'large',
    package: null,
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['auto', '64px', '240px', '280px', '320px', '360px', '480px', '100%'],
            displayName: 'Width',
            default: '280px', 
            tile: '1/2'
        }, 
        position: {
            type: 'oneOf',
            options: ['left', 'right'],
            displayName: 'Position',
            default: 'left', 
            tile: '1/2'
        },
        background: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'base-700', 'none', 'base-content', 'primary', 'accent'],
            displayName: 'Background',
            default: 'base-0'
        },
        paddingX: {
            type: "spacing",
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right.',
            default: null, 
            tile: '1/2'
        },
        paddingY: {
            type: "spacing",
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom.',
            default: null, 
            tile: '1/2'
        },
        gap: {
            type: 'gap',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px"],
            displayName: 'Gap',
            default: null,
            tile: '1/2'
        },
        textSize: {
            type: 'oneOf',
            options: ['sm', 'base', 'md'],
            default: 'base', 
            tile: '1/2'
        },
        alignItems: {
            type: 'alignItems',
            options: ['start', 'end', 'center', 'stretch'],
            displayName: 'Align Items',
            default: 'stretch', 
            tile: '1/2'
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'end', 'center', 'between',],
            displayName: 'Justify Content',
            default: 'start', 
            tile: '1/2'
        },
        hasOutline: {
            type: 'bool',
            ai_instructions: 'adds subtle outline on one side of the sidebar, e.g. if Sidebar position is left the outline will be on the right',
            displayName: 'Border',
            default: true, 
        },
        display: {
            type: 'bool',
            default: true,
            displayName: 'Display', 
            defaultOnMobile: false
        },
        
    }
};

/*Sidebar.propTypes = {
    background: PropTypes.oneOfType([    
        PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200', 'base-700', 'base-content', 'primary', 'accent']),
        PropTypes.string]),
    width: PropTypes.oneOf(['auto', '64px', '240px', '280px', '320px', '360px', '480px', '100%']),
    
    paddingX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px"]),
    paddingY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px"]),
    gap: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px"]),

    alignItems: PropTypes.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'end', 'center', 'between', 'around', 'evenly']),
    hasOutline: PropTypes.bool,
    textSize: PropTypes.oneOf(['sm', 'base', 'md']),
    display: PropTypes.bool,    
    children: PropTypes.node
};*/