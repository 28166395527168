import React from 'react';
import { spacingMap } from '../helpers';

type Props = {
    position?: 'top' | 'bottom',
    direction?: 'flex-col' | 'flex-row',
    width?: 'full' | 'auto' | '1/2' | '1/3' | '1/4' | '2/3' | '3/4' | string,
    height?: 'full' | '1/2' | '1/3' | '1/4' | '2/3' | '3/4' | 'auto' | string,
    paddingX?: '0px' | '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    paddingY?: '0px' | '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    gap?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    hasDivider?: boolean,
    bgColor?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'primary' | 'accent' | 'base-900',
    hasOutline?: boolean,
    display?: boolean,
    children: React.ReactNode,
    __juno?: any
}

export default function Sticky({
        direction = "flex-col",
        position = "top",
        width = "full",
        height,
        paddingX,
        paddingY,
        bgColor,
        hasOutline,
        hasDivider,
        // corners,
        gap,
        display = true,
        children,
        __juno = {}
      }: Props) {

    const mobile_props = __juno?.mobile_props || {};

    let bgStyles = mobile_props?.background ? `bg-${mobile_props?.background}` : '';
    bgStyles = bgColor ? bgStyles+` ${bgStyles == '' ? '' : '@md:'}bg-${bgColor}` : bgStyles;

    // let cornerStyles = mobile_props?.corners ? `rounded-${mobile_props?.corners}` : '';
    // cornerStyles = corners ? cornerStyles+` ${cornerStyles == '' ? '' : '@md:'}rounded-${corners}` : cornerStyles;

    let widthStyles = mobile_props?.width ? `w-${mobile_props?.width}` : '';
    widthStyles = width ? widthStyles+` ${widthStyles == '' ? '' : '@md:'}w-${width}` : widthStyles;

    let heightStyles = mobile_props?.height ? `h-${mobile_props?.height}` : '';
    heightStyles = height ? heightStyles+` ${heightStyles == '' ? '' : '@md:'}h-${height}` : heightStyles

    let borderStyles = mobile_props?.hasOutline ? 'border border-current-20' : '';
    borderStyles = hasOutline ? borderStyles+` ${mobile_props?.hasOutline == false ? '@md:' : ''}border border-current-20` : borderStyles;

    let dividerStyles = hasDivider ? position == 'top' ? 'border-b border-current-20' : 'border-t border-current-20' : '';

    let gapStyles = mobile_props?.gap ? `gap-${spacingMap[mobile_props?.gap]}` : '';
    gapStyles = gap ? gapStyles+` ${gapStyles == '' ? '' : '@md:'}gap-${spacingMap[gap]}` : gapStyles;

    let paddingXStyles = mobile_props?.paddingX ? `px-${spacingMap[mobile_props?.paddingX]}` : '';
    paddingXStyles = paddingX ? paddingXStyles+` ${paddingXStyles == '' ? '' : '@md:'}px-${spacingMap[paddingX]}` : paddingXStyles;

    let paddingYStyles = mobile_props?.paddingY ? `py-${spacingMap[mobile_props?.paddingY]}` : '';
    paddingYStyles = paddingY ? paddingYStyles+` ${paddingYStyles == '' ? '' : '@md:'}py-${spacingMap[paddingY]}` : paddingYStyles;
    
    const paddingStyles = `${paddingXStyles} ${paddingYStyles}`;


    const classes = `!sticky ${position}-0 left-0 right-0 
        flex ${direction} ${dividerStyles}
        ${widthStyles}
        ${bgStyles} 
        ${borderStyles} 
        ${gapStyles} ${paddingStyles} 
        ${heightStyles}`


    if (display) return (
        <div 
            className={`${classes} ${__juno?.tagStyle} ${__juno?.outlineStyle}`}
            {...__juno?.attributes}
        >
            {children}
        </div>
    );
}




/* ignore rest */

Sticky.definitions = {
    apiName: "Sticky",
    displayName: "Sticky",
    description: "",
    ai_instructions: '',
    type: 'container',
    relativeSize: 'various',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    status: 'stable',
    package: null, 
    propDefinitions: {
        position: {
            type: 'oneOf',
            options: ['top', 'bottom'],
            displayName: 'Position',
            default: 'top',
        },
        width: {
            type: "oneOf",
            options: ["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4'],
            displayName: "Width",
            default: "full", 
            tile: '1/2',
        },
        height: {
            type: 'oneOf',
            options: ['full', '1/2', '1/3', '1/4', '2/3', '3/4', 'auto'],
            default: null,
            displayName: "Height",
            tile: '1/2', 
        }, 
        bgColor: {
            type: "colors",
            options: ["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900",],
            displayName: "Background",
            default: null,
            tile: '1/2',
        },
        paddingX: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: 'base', 
            tile: '1/2',
        },
        paddingY: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null,
            tile: '1/2',
        },
             
        /*corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl', '2xl', "full"],
            displayName: "Corners",
            default: null, 
            tile: '1/2',
        },*/
        gap: {
            type: "gap",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        }, 
        hasDivider: {
            type: "bool",
            displayName: "Divider",
            default: false, 
            ai_instructions: 'adds top or bottom border',
            tile: '1/2',
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds outline',
            tile: '1/2',
        },
        
        display: {
            type: "bool",
            displayName: "Display",
            default: true
        },
            }
};
