import { useContext, useRef, useState } from "react";
import { EditorContext } from "../../EditorContext";
import Search from "./search/Search";
import { makePrimitives, makeTemplates } from "./helpers";
import Results from "./search/Results";
import ComponentTemplates from "./templates/ComponentTemplates";
import { TemplatesContext } from "../../TemplatesContext";
import { SegmentedSwitch } from "../../../../ui-kit/exports/react";
import Pages from "./pages/Pages";
import { DSContext } from "pages/editor/DSContext";


export default function Library() {
    const { view, setView, selector, handleAction, set  } = useContext(EditorContext)
    const { templates } = useContext(TemplatesContext)
    const { styleVariables } = useContext(DSContext);
    
    const [selectedOption, setSelectedOption] = useState('components');
    const switchOptions = 
      [
        { label: 'UI-Kit', value: 'components', },
        { label: 'Pages', value: 'pages'},
        // { label: 'Pages', value: 'pages'},
      ] 
      
      function handleSelect(value) {
        setSelectedOption(value)
        setView({ ...view, uikit: value })
      }
      const [showSearch, setShowSearch] = useState(false)
      const [searchResults, setSearchResults] = useState([])
    
    const templateCategories = makeTemplates(templates);
    const primitiveCategories = makePrimitives(templates)
    const searchItems = [...templateCategories, ...primitiveCategories].flatMap(cat => cat.items)
    const combinedCategories = [...primitiveCategories, ...templateCategories]

    const cssVars = Object.fromEntries(
      Object.entries(styleVariables).map(([key, value]) => [`--${key}`, value])
    );
    return (<>
      <div className='flex flex-row justify-between items-end w-full gap-2 mb-3 pt-3 pb-2 px-2.5 bg-base-50 border-b border-base-200'>
                    <h1 className='text-md font-medium'>Libary</h1>

                    <div className="w-1/2">
                    {<SegmentedSwitch
              value={selectedOption}
              options={switchOptions}
              size={'small'}
              hasOutline={false}
              width="full"
              optionLayout="equal"
          onChange={handleSelect}/> }
          </div>
                    </div>
        <div className="w-full h-full flex flex-col gap-3 p-2">
        {<Search
                searchResults={searchResults} 
                setSearchResults={setSearchResults} 
                showSearch={showSearch} 
                setShowSearch={setShowSearch} 
                items={searchItems}
          />}
          {
            showSearch ? 
              <Results 
                items={searchResults}
              />
             : selectedOption === 'components' ? 
              <ComponentTemplates 
                key={selectedOption}
                categories={combinedCategories}   
                cssVars={cssVars} 
                />
             : selectedOption === 'pages' ? 
              <Pages 
                set={set} 
                view={view} 
                selector={selector} 
                handleAction={handleAction} 
                cssVars={cssVars} 
              />
             : null
          }
            
            </div>
            </>
            )

}

