import { useState, useContext } from "react";
import { CodeSnippet, SegmentedSwitch } from "../../../../ui-kit/exports/react";
import { makeImportString, prepareHybridJSX } from "./code/helpers";
import pretty from 'pretty';
import Fiddle from "./code/Fiddle";
import { EditorContext } from "pages/editor/EditorContext";

export default function Inspector() {
    
    const {selector} = useContext(EditorContext)
    const frame = selector?.frame
    const tabs = [
      {value: 'inspector', label: 'Inspect', icon: 'cursor'},
      {value: 'fiddle', label: 'Create', icon: 'flare'}
    ]
    
    const [selectedTab, setSelectedTab] = useState('inspector')

    const formatObjects = selectedTab == 'inspector' ? selector : {...selector, object: null}
    
    let importString = makeImportString(formatObjects, true)
    
    const modifiedSelector = selectedTab == 'inspector' ? selector : {...selector, object: null}
    
    let jsx = prepareHybridJSX(modifiedSelector)
    jsx = pretty(jsx, {ocd: true});
    jsx = selectedTab != 'inspector' && importString ? importString + `\n\n` + jsx : jsx;

    

    if (frame) return (
      <div
        className="w-full h-full overflow-hidden flex flex-col gap-2"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
      <div
        className={`flex flex-col text-sm gap-2 overflow-hidden`}
        style={{ maxHeight: "100%" }}
      >
        <div className='flex flex-row justify-between items-end w-full gap-2 mb-3 pt-3 pb-2 px-2.5 
                    bg-base-50 border-b border-base-200
                    '>
                    <h1 className='text-md font-medium'>Inspect Code</h1>
          <div className="w-1/2">
          <SegmentedSwitch 
            options={tabs}
            value={selectedTab}
            onChange={(value) => setSelectedTab(value)}
            size="small"
            width="full"
          />
            </div>
        </div>

        
  
      
        
        <div className="overflow-y-auto select-text text-xs rounded-lg flex flex-col gap-4 px-2">
        {/*selectedTab == 'inspector' &&
        <Alert
            text={`Select a node on the canvas to view its JSX`}
            size="small"
            type="info"
          
          />*/}
        {importString && selectedTab == 'inspector' &&
        <div className="w-full flex flex-col gap-1">
        <CodeSnippet
        language="jsx"
        fontSize="xs"
        title="imports"
        text={importString}
        />
        
        </div>}
        <div id="code-block"  className="flex flex-col w-full gap-2 code-block">
          

          {selectedTab == 'fiddle' ?
            <Fiddle
            jsx={jsx}
            selector={selector}
            />
            :
            <CodeSnippet
              title="jsx"
              text={jsx}
              language="jsx"
              fontSize="xs"
              />}
          </div>
        </div>
        </div>
      </div>
    );
  };
  
  

  