import React, { useEffect, useState } from 'react';
import { 
    baseColorPresets, 
    functionColorsPresets, 
    primaryColorDefault, 
    accentColorDefault,
    cornersPresets, 
} from './presets';
import { v1 as uuidv1 } from 'uuid';
import ColorPicker from './ColorPicker';
import CornersPicker from './CornersPicker';
import { ArrowLeft, NavArrowLeft, Refresh, Trash } from 'iconoir-react';
import IconsPicker from './IconsPicker';
import FontPicker from './FontPicker';
import { convertTheme, 
    makeDefaultTheme,
    buildThemeObject
 } from './helpers';
import { Button, Checkbox, SegmentedSwitch, Select, ToggleSwitch } from '../../../../../../ui-kit/exports/react';


// Presets for easy setup
const baseFonts = ['Inter', 'Lato', 'Montserrat', 'Geologica', 
    'Merriweather', 'Poppins', 'Noto Sans', 'Work Sans', 'Manrope', 'Baloo 2', 'IBM Plex Sans', 
    'Onest', 'Sora', 'Kanit', 'Parkinsans', 'Plus Jakarta Sans', 'Geist', 'Nunito', 
]
const accentFonts = ['Inter', 'Lato', 'Montserrat', 'Geologica', 
    'Merriweather', 'Poppins', 'Noto Sans', 'Work Sans', 'Manrope', 'Baloo 2', 'IBM Plex Sans', 
    'Onest', 'Sora', 'Kanit', 'Parkinsans', 'Plus Jakarta Sans', 'Geist', 'Nunito']

const iconSets = ['feather', 'ionic', 'material', 'heroicons', 'iconoir']

export default function ThemeEditor({theme, onClose, onSave, onDelete}) {
    
    const [originalTheme, setOriginalTheme] = useState(theme);    

    const handleCancel = () => {
        onSave(originalTheme)
        onClose()
    }

    const handleSave = (theme) => {
        onSave(buildThemeObject(theme))
    }

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this theme?')) {
            onDelete(theme?.id);
            onClose();
        }
    }
    // console.log(theme?.colors)
    const convertedTheme = convertTheme(theme);
    console.log(convertedTheme)
    return (
        <div className='w-full gap-3 flex flex-col h-full px-3 py-2'>
            <div className='flex flex-col gap-3 items-stretch flex-grow'>
            <ThemeName theme={convertedTheme} onSave={handleSave} onClose={onClose} />
            <ColorPicker theme={convertedTheme} setTheme={handleSave} baseColorPresets={baseColorPresets} functionColorsPresets={functionColorsPresets}/>
            <FontPicker theme={convertedTheme} setTheme={handleSave} baseFonts={baseFonts} accentFonts={accentFonts}/>
            <IconsPicker theme={convertedTheme} setTheme={handleSave} iconSets={iconSets}/>
            <CornersPicker theme={convertedTheme} setTheme={handleSave} cornersPresets={cornersPresets}/>
            <ToggleSwitch 
                checked={convertedTheme?.config?.is_rounded}
                onChange={(e) => handleSave({
                    ...convertedTheme,
                    config: {
                        ...convertedTheme.config,
                        is_rounded: e.target.checked
                    }
                })}
                style='rectangle'
                label='Pill buttons and inputs'
            />
            
            
            <Config theme={convertedTheme} setTheme={handleSave} />
            <ul className='text-xs text-gray-400'>
                <li>add controls for font weights: affect headers, buttons, inputs, etc. </li>
                <li>limit headers to 4 types: display, large, medium, small</li>
                <li>push iconset into config</li>
                <li>push assets into config</li>
                
            </ul>
            </div>

            
            {/* Save, Cancel, Delete buttons */}
            <div className='flex flex-row gap-2 items-start'>
                <Button size='small' style='light' text='cancel'  onClick={handleCancel} leftIcon='refresh' />
                <Button size='small' style='light' color='warning' text='delete' onClick={handleDelete} leftIcon='trash'/>
            </div>
                
                
        </div>
    );
}



function ThemeName({theme, onSave, onClose}) {
    const handleNameChange = (event) => {
        onSave({
            ...theme,
            display_name: event.target.value
        });
    };

    return (
        
                <div className='flex flex-col gap-2 items-start mb-4'>
                    
                        <Button 
                            leftIcon='arrow-left'
                            text='back'
                            size='small'
                            onClick={()=>onClose()}
                        />

                    <input
                        type="text"
                        className='bg-transparent focus:bg-base-100 w-full text-3xl font-medium appearance-none border border-red-300 transition-all'
                        defaultValue={theme.display_name}
                        onBlur={handleNameChange}
                        maxLength={32}
                        style={{fontFamily: theme.font}}
                        placeholder="Enter theme name"
                    />
                </div>
    );
}

function Config({theme, setTheme}) {

    const outlineOptions = [
        {label: 'None', value: 'none'},
        {label: 'Light', value: 'subtle'},
        {label: 'Strong', value: 'strong'},
    ]
    
    return (
        <div className='flex flex-col w-full flex-wrap gap-6 gap-2 py-4 items-start'>
            <SegmentedSwitch 
            options={outlineOptions}
            width='full'
            label='Outlines'
            value={theme?.config?.outlines || 'none'}
            onChange={(val) => {
                setTheme({
                    ...theme,
                    config: {
                        ...theme.config,
                        outlines: val
                    }
                })
            }}
            />
        </div>
    );
}