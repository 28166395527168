import React, { useRef } from "react";
import { getIconComponent, IconType, LibraryType, allIconNames } from "../iconMap";
import { config } from '../config';

// most probably Color and Size should be shared among multiple components
type Color =
    | "base-100"
    | "base-200"
    | "base-300"
    | "base-500"
    | "base-700"
    | "primary"
    | "accent"
    | "base-content"
    | "info"
    | "warning"
    | "success"
    | "error"
    | "auto"  // last two are for backwards-compatibility
    | "none"; // to be refactored away

type Size = "auto" | "12px" | "16px" | "20px" | "24px" | "32px";


// TODO. Ideally we'd better remove 'none' from here, but it's in templates all over the places?
type Props = {
    icon?: IconType;
    library?: LibraryType;
    color?: Color;
    size?: Size;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    __juno?: any; // FIXME: router to export __juno type
};


const iconNoirSizeMap = {
    '12px': 'w-3 h-3',
    '16px': 'w-4 h-4',
    '20px': 'w-5 h-5',
    '24px': 'w-6 h-6',
    '32px': 'w-8 h-8',
}

/**
 * Icon
 * Make sure you have a proper icon library (react-icons or iconoir) installed
 */
export default function Icon({
    icon = 'circle',
    library,
    color = 'none',
    size = "auto",
    className,
    onClick,
    __juno = {},
}: Props) {

    const localConfig = { ...config, ...(__juno?.designConfig || {}) };
    const set = library || localConfig?.iconset || 'iconoir'
    const libraryToUse = set as  LibraryType;

    if (!icon) return null;
    
    const IconComponent = getIconComponent(icon, libraryToUse);
    if (!IconComponent) return null;

    const classes = color ? `text-${color}` : "";

    const iconSize = size === "auto" ? `1.5em` : size;
    
    return (
        <div
            className={`${classes} ${__juno?.outlineStyle}`}
            onClick={onClick}
            {...__juno?.attributes}
        >
        <div className='absolute w-0 h-0 invisible'/>
            <IconComponent size={iconSize} color={color} className={`${className} ${iconNoirSizeMap[size]} `} />
        </div>
    );
}

/* ignore rest */

Icon.definitions = {
    apiName: "Icon",
    displayName: "Icon",
    description: "Dynamic icon that can switch between libraries",
    ai_instructions: "icon component that can switch between libraries",
    type: "media",
    acceptedChildren: "none",
    relativeSize: "small",
    status: "stable",
    package: "Starter",
    propDefinitions: {
        icon: {
            type: "icon",
            options: [ 'none', ...allIconNames ],
            displayName: "Icon",
            default: "circle",
        },
        color: {
            type: "colors",
            options: [
                "base-100",
                "base-200",
                "base-300",
                "base-500",
                "base-700",
                "primary",
                "accent",
                "base-content",
                "info",
                "warning",
                "success",
                "error",
            ],
            displayName: "Color",
            default: null,
        },
        size: {
            type: "oneOf",
            options: ["auto", "12px", "16px", "20px", "24px", "32px"],
            displayName: "Size",
            default: "auto",
        },
        library: {
            type: "oneOf",
            options: ["feather", "ionic", "material", "heroicons", "iconoir"],
            displayName: "Library",
            ai_instructions: `dont change this prop unless user is requesting a specific library`,
            default: null,
        },
        
    },
};

