import React, { useContext, useState, useEffect, useRef } from 'react';
import { EditorContext } from '../../EditorContext';
import { useNavigate } from 'react-router-dom';
import Folders from './Folders';
import AddFolderPlus from './AddFolderPlus';
import { Divider, OrgMenu, SidebarLink } from 'ui-kit/exports/react';

import UserContext from '../../../../UserContext';

export default function Navigation() {
    const { view, setView, project, assets, selector, setSelector } = useContext(EditorContext)
    const {handleLogout} = useContext(UserContext)
    const navigate = useNavigate();
    const isOpen = view.overviewSidebar
    const setIsOpen = (value) => setView({...view, overviewSidebar: value})

    const navigationRef = useRef(null);
    
    function useOutsideClick(ref, callback) {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            callback();
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, callback]);
    }
    const folders = project.folders?.filter(f => !f.is_archived) || []
    useOutsideClick(navigationRef, () => setView({...view, leftSide: false}));

    const tooltipStyle = `opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap text-base-100
    w-auto bg-base-800 border border-base-800  font-normal rounded-md px-2 py-0.5 text-2xs ${isOpen ? 'absolute md:hidden' : 'absolute'}
    top-1/2 mb-2 -right-3 transform -translate-y-1/2 translate-x-3/4 group-hover:translate-x-full transition-all`

    const projectName = project?.project?.name
    const projectSymbol = assets?.find(a => a.api_name == 'symbol')?.file_url
    
    const links = [
      {icon: 'home', text: 'Overview', target: '/overview' },
      // {icon: 'mindmap', text: 'Router', target: '/config/router'},
      // {icon: 'database', text: 'Database', target: '/config/database'},
      // {icon: 'post', text: 'Docs', target: '/config/docs'},
      // {icon: 'layout', text: 'Layouts', target: '/ds/layouts'},
      // {icon: 'colors', text: 'Themes', target: '/ds/themes'},
      // {icon: 'flower', text: 'Brand', target: '/ds/brand'},
      // {icon: 'box', text: 'UI Kit', target: '/ds/uikit'},
      
    ]

    const overviewLink = links.find(l => l.target?.startsWith('/overview'))
    const config = links.filter(l => l.target?.startsWith('/config'))
    const designSystem = links.filter(l => l.target?.startsWith('/ds'))


    return(
      <div className={`flex flex-col h-full flex-shrink-0 gap-1 relative transition-all duration-75 relative select-none
      py-3 pt-2 px-3 ${isOpen ? 'w-[52px] md:w-60 ' : 'w-[52px]'} duration-75`}
      onClick={() => !isOpen && setIsOpen(true)}
      style={{
        height: `100%`,
        maxHeight: `100vh`,
        cursor: 'default', 
        zIndex: 1000,
      }}
      ref={navigationRef} 
      >
        <div className='w-full flex flex-col gap-1 items-start justify-start'>
          <div className='flex flex-col gap-1 items-start justify-start z-10 w-full mb-1.5'>
          
            <OrgMenu  isCollapsed={!isOpen} icon={'arrows-up-down'} width={'full'} size={'small'} imageSrc={projectSymbol} name={projectName}>
              <SidebarLink size={'small'} onClick={(e) =>  navigate('/projects')} 
              leftIcon={'arrow-left'} text={'All Projects'}hoverEffect={true} /> 
              <Divider margins={'6px'}color='base-100'/>
              <SidebarLink  size={'small'} onClick={handleLogout} leftIcon={'log-out'} text={'Logout'}hoverEffect={true}/>  
            </OrgMenu>

          </div>
          <div className='flex flex-col gap-1 items-start justify-start z-0 w-full'>
          
            <SidebarLink
              size={'small'}
              onClick={() => setView({...view, inOverview: overviewLink.target})}
              leftIcon={overviewLink.icon}
              isActive={view.inOverview == overviewLink.target}
              text={overviewLink.text}
              isCollapsed={!isOpen}
              hoverEffect={true}
            />
          <SidebarLink
              
              size={'small'}
              onClick={() => setView({...view, inOverview: '/config'})}
              isActive={view.inOverview == '/config'}
              leftIcon={'code'}
              text={'Config'}
              isCollapsed={!isOpen}
              hoverEffect={true}
            />
          {/* Config *
          <SidebarLink
            size={'small'}
            leftIcon={'code'}
            text={'Config'}
            isActive={true}
            innerTree={true}
            // alwaysOpen={true}
            hoverEffect={false}
          >
          {config.map((link, index) => (
            <SidebarLink
              key={index}
              size={'small'}
              onClick={() => setView({...view, inOverview: link.target})}
              isActive={view.inOverview == link.target}
              text={link.text}
              isCollapsed={!isOpen}
              hoverEffect={true}
            />
          ))}
        </SidebarLink>/}

        {/* Design System 
        <SidebarLink
          size={'small'}
          leftIcon={'colors'}
          text={'Design System'}
          isActive={true}
          innerTree={true}
          // alwaysOpen={true}
          hoverEffect={false}
        >
          {designSystem.map((link, index) => (
            <SidebarLink
              key={index}
              size={'small'}
              onClick={() => setView({...view, inOverview: link.target})}
              isActive={view.inOverview == link.target}
              text={link.text}
              isCollapsed={!isOpen}
              hoverEffect={true}
            />
          ))}
        </SidebarLink>*/}

        </div>
        </div>

        {/* overflow-hidden  take out */}
        <div className='flex flex-col w-full flex-grow w-full text-xs gap-1
        overflow-y-auto'>
          <div className={` gap-1 items-center justify-between mt-3 px-0 pr-1 py-1 group relative font-medium
          overflow-hidden flex-shrink-0
          ${isOpen ? 'hidden md:flex md:flex-row' : '!hidden'} 
          `}>
              <h2 className={`opacity-60 w-full text-xs font- items-center justify-between px-1`}>
                  ROUTES
            </h2>
            <div></div>
            <AddFolderPlus folders={folders}/>
          </div>
          <Folders sidebarOpen={isOpen} 
          tooltipStyle={tooltipStyle}
          />
        </div>
        </div>
    )
  }
