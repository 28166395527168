import React, { useState, useEffect, useContext } from 'react';
import { EditorContext } from '../../../EditorContext';
import DnDContext from '../../../dragNdrop/DnDContext';
import { NavArrowLeft } from 'iconoir-react';
import Folders from './Folders';
import FramesPerGroup from './FramesPerGroup';
import { createNewFrame } from 'utilities/helpers';
import { DSContext } from 'pages/editor/DSContext';

export default function Pages({set, view, selector, handleAction, cssVars}) {
  const { assets } = useContext(EditorContext);
  const { styleVariables } = useContext(DSContext);
  const { handleDragEnd, handleDragStart } = useContext(DnDContext);
  const [selected, setSelected] = useState(null);

  function insertNewFrame({objects=[]}) {
      
    const page = selector.page
    if (!page) return
    const frames = page?.frames
    const insertIndex = frames?.length > 0 
      ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
      : 1;
  
    let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
    newFrame = { ...newFrame, index: insertIndex };

    let newObjects = objects.map(obj => ({...obj, frame: newFrame.id}));
    newObjects = newObjects.map(obj => obj.parent == 'rootObject' ? {...obj, parent: newFrame.id} : obj); 
    
    const action = {type: 'INSERT_FRAME', frame: {...newFrame, objects: newObjects}}
    handleAction(action);
  }


  const folders =
  set?.folders
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .filter(folder => folder.name !== 'Layouts')
    .map(folder => {
      const frames = (folder.pages || [])
        .flatMap(page => page.frames || [])
        .sort((a, b) => a.index - b.index);
      return { ...folder, frames };
    }) || [];

  // console.log(set.folders);
  // If you need a flat list:
  // const flows = folders.flatMap(folder => folder.frames);

  return (
    <div className='w-full h-full flex flex-col gap-2' style={{  overflow: 'scroll', userSelect: 'none' }}>
      <div className='w-full h-full flex flex-col gap-2'
        style={{ 
          // ...cssVars, inject css variables here to simplify logic, force JUNO colors
          overflow: 'scroll', userSelect: 'none' }}>
            {folders?.length > 0 && !selected && 
            <Folders
              folders={folders} 
              variables={cssVars}
              onSelect={setSelected}
              />}
            {selected && 
            <>
            <div className='flex flex-row gap-1  items-center justify-start text-sm py-1 font-medium capitalize -ml-1'>
                <NavArrowLeft onClick={()=>setSelected(null)} className='cursor-pointer hover:scale-125 stroke-[1.8px] transition-all duration-75' width={16} height={16}/>
                <h1>{selected.name}</h1>
              </div>
            {<FramesPerGroup
              flow={selected}
              assets={assets}
              view={view}
              insertNewFrame={insertNewFrame}
              variables={styleVariables}
              handleDragEnd={handleDragEnd}
              handleDragStart={handleDragStart}
            />}
            </>}
          </div>

      
      </div>
  );
}


