import React from 'react';
import { spacingMap } from '../helpers'; 

type MainProps = {
    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'primary' | 'accent' | 'base-content' | string,
    width?: '100%' | '390px' | '560px' | '780px' | '960px' | '1020px' | '1080px' | '1200px' | '1440px',
    paddingX?: '6px' | '8px' | '12px' | '16px' | '20px' | '24px' | '32px' | '48px' | '64px',
    paddingY?: '6px' | '8px' | '12px' | '16px' | '20px' | '24px' | '32px' | '48px' | '64px',
    direction?: 'flex-col' | 'flex-row',
    gap?: '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    marginX?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px' | '64px',
    marginY?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px' | '64px',
    corners?: 'none' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'iphone',
    selfAlign?: 'start' | 'center' | 'end',
    alignItems?: 'start' | 'center' | 'end' | 'stretch',
    hasOutline?: boolean,
    justifyContent?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly',
    textSize?: 'sm' | 'base' | 'md',
    children?: React.ReactNode,
    __juno?: any,
}

function Main({
        background,
        direction = 'flex-col',

        marginX,
        marginY,
        paddingX = '48px',
        paddingY = '48px',
        gap = '32px',
        
        alignItems = 'start',
        justifyContent = 'start',
        textSize = 'base',
        width = '100%',
        hasOutline,
        selfAlign = 'center',
        corners = 'none',
        children,
        __juno = {}
      }: MainProps) {

    const bgStyles = background ?`bg-${background}` : '';

    const marginStyles = `${marginX ? `px-0 @md:px-${spacingMap[marginX]}` : ''}${marginY ? ` py-0 @md:py-${spacingMap[marginY]}` : ''}`;
    
    const paddingStyles = `${paddingX ? `px-5 @md:px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-5 @md:py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    
    const fontSize = `text-`+textSize
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    
    const fontColor = (!background || background == 'none') ? '' : (background?.startsWith('base') && background != 'base-content' && background != 'base-700') ? 'text-base-content' : `text-base-0`
    const alignMain = `items-${selfAlign}`
    const cornerStyles = corners === 'none' ? '' : `@md:rounded-${corners}`;
    const ringStyles = hasOutline ? 'ring-1 ring-inset ring-current-10' : '';
    
    
    const outerClasses = `flex flex-col flex-grow w-full relative ${alignMain} ${fontColor} ${fontSize} ${marginStyles}`
    const innerClasses = `flex ${direction} flex-grow w-full z-0 relative ${ringStyles} ${bgStyles} ${paddingStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles} ${cornerStyles}`
    
    
    return (
        <div 
        className={`${outerClasses} ${__juno?.outlineStyle}`}
        {...__juno?.attributes}
        style={{ minHeight: '100%',  // 'overflow: 'auto'' it should be managed by AppShell
            }}
        >
        <div 
        className={`${innerClasses} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        data-tag={__juno?.attributes?.['data-tag']}   
        style={{width: '100%', maxWidth: width}}>
        {children}
        </div>
        </div>
    );
}

Main.__juno_name = "Main";
export default Main;


/* ignore rest */

Main.definitions = {
    apiName: 'Main',
    displayName: 'Main Content',
    description: 'A versatile and customizable main content area for different layouts, featuring adjustable padding, background color, gap, alignment, text size, and minimum height.',
    ai_instructions: 'main content area. cannot be deleted',
    type: 'section',
    relativeSize: 'large',
    package: null,
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        
        width: {
            type: 'oneOf',
            tile: '1/2',
            options: ['100%', '390px', '560px', '780px', '960px', '1020px', '1080px', '1200px', '1440px'],
            displayName: 'Width',
            ai_instructions: 'main content area stretches within appshell, but it has inner container with max-width',
            default: '100%'
        },
        textSize: {
            type: 'oneOf',
            tile: '1/2',
            options: ['sm', 'base', 'md'],
            displayName: 'Text Size',
            default: 'base'
        },
        direction: {
            type: "direction",
            options: ["flex-col", "flex-row"],
            displayName: "Direction",
            tile: '1/2',
            default: "flex-col"
        },
        selfAlign: {
            type: 'alignItems',
            options: ['start', 'center', 'end'],
            displayName: 'Align Self',
            tile: '1/2',
            default: 'center'
        }, 
        alignItems: {
            type: 'alignItems',
            options: ['start', 'center', 'end', 'stretch'],
            displayName: 'Align Items Inside',
            default: 'start', 
            tile: '1/2',
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'center', 'end', 'between'],
            displayName: 'Justify Innner Content',
            default: 'start', 
            tile: '1/2',
        },
        
        background: {
            type: 'colors',
            options: ['base-0',  'base-50', 'base-100', 'base-200', 'primary', 'accent', 'base-content'],
            displayName: 'Background',
            default: null,
        },
        
        paddingX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: '48px'
        },
        paddingY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: '48px'
        },
        marginX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Margin X",
            default: null
        },
        marginY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Margin Y",
            default: null
        },
        gap: {
            type: 'gap',
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: '32px',
            tile: '1/2',
        },
        corners: {
            type: "corners",
            options: ["none", "sm", "base", "md", "lg", 'xl', '2xl', '3xl', 'iphone'],
            displayName: "Corners",
            tile: '1/2',
            default: 'none'
        },
        
        
        hasOutline: {
            type: 'bool',
            displayName: 'Outline',
            default: false, 
            ai_instructions: 'adds thin base-300 border around the main content area'
        },


        
        
        
        
        
    }
};