import { useState, useEffect, useRef } from 'react';
import { ButtonIcon } from '../../ui-kit/exports/react';


type Props = {
  isPlaying?: boolean;
  progress?: number | null;  
  color?: 'primary' | 'accent' | 'warning' | 'info' | 'success' | 'error' | 'current';
  style?: 'filled' | 'ghost' | 'light';
  state?: 'default' | 'disabled' | 'active';
  size?: 'small' | 'medium' | 'large';
onClick?: () => void;
  __juno?: any;
};

export default function ProgressButton({
  progress,
  color = 'current',
  style = 'light',
  state = 'default',
  isPlaying,
  size = 'medium',
  onClick,
  __juno = {},
}: Props) {

  const progressColor = style == 'filled' ? 'var(--base-0)' : color == 'current' ? 'currentColor' : `var(--${color})`

  const strokeWidth = size === 'small' ? 8 : size === 'large' ? 10 : 10;
  const circlePadding = 0 //size == 'small' ? '1px' : size == 'large' ? '2px' : '1.5px'
  // top-[1px] top-[1.5px] top-[2px] left-[1px] left-[1.5px] left-[2px] right-[1px] right-[1.5px] right-[2px] bottom-[1px] bottom-[1.5px] bottom-[2px]


  const sizeStyles = size == 'small' ? `h-7 w-7` : size == 'large' ? `w-12 h-12` : `h-9 w-9`;

  return (
    <div
      className={`relative flex items-center ${sizeStyles} justify-center  ${__juno?.tagStyle}`}
      {...__juno?.attributes}
    >
      {/* Background Circle */}
      <svg className={`absolute 
        pointer-events-none z-10`}
        viewBox="0 0 100 100"
        style={{
          // Force integer pixel or consistent padding so Safari doesn't get confused
          top: circlePadding,
          bottom: circlePadding,
          left: circlePadding,
          right: circlePadding
        }}
      >
        <circle cx="50" cy="50" r="45" stroke={progressColor} strokeWidth={strokeWidth}
          className={`${progress ? 'opacity-20' : 'opacity-0'} transition-al duration-150`}
          fill="none" />
          <circle cx="50" cy="50" r="45" stroke={progressColor} strokeWidth={strokeWidth}
            className={`${progress > 0 ? 'opacity-70' : 'opacity-0 transition-all duration-150'}`}
            fill="none" transform="rotate(-90 50 50)" strokeLinecap="round"
            strokeDasharray={`${2 * Math.PI * 45}`}
            strokeDashoffset={`${2 * Math.PI * 45 - (progress / 100) * 2 * Math.PI * 45}`}
            // style={{ transition: 'stroke-dashoffset 0.1s ease, opacity 0.1s ease'}}
          />
      </svg>

      {/* Button */
      <ButtonIcon
        icon={isPlaying? 'pause' : 'play'} 
        color={color} style={style} state={state} size={size} isPill
        onClick={onClick}
      />}
    </div>
  );
}
    