import React, { useContext, useEffect, useState } from 'react';
import { EditorContext } from '../EditorContext';
import PropEditor from './prop-editor/PropEditor';
import Themes from './themes/Themes';
import Inspector from './inspect/Inspector';
import FrameEditor from './frame-editor/FrameEditor';
import Library from './elements/Library';
import Iconbar from './Iconbar';
import SuperChat from '../chat/Chat';
import { isEqual } from 'lodash';
import { SegmentedSwitch } from 'ui-kit/exports/react';

export default function Sidebar() {
  const { view, setView, streaming,  } = useContext(EditorContext);

  // Calculating width based on visibility
  const widthMapMap = {
    editor: 280,
    assets: 280,
    elements: 280,
    theme: 280,
    chat: 280,
    inspect: 440,
    notes: 280
  }
  const sidebarWidth = view?.rightSide ? widthMapMap[view.rightSide] : 0
  
  
  const sidebarVisible = sidebarWidth > 0


  useEffect(() => {
    // Determine the desired width based on 'rightSide' condition
    if (view.rightSide === 'inspect') {
      setView({...view, sidebarWidth: 440})
    } else {
      setView({...view, sidebarWidth: 280})
    }
  }, [view.rightSide]);

  const closedWidth = 52
  return (
    
    <div className={`relative flex flex-row flex-shrink-0 transition-all text-base-content h-full
    self-start duration-150 ${sidebarVisible ? 'bg-base-0 border-l border-t rounded-tl-sm border-base-200' : ''}`}
    style={{
        height: `100%`,
        zIndex: 100, marginTop: 6,
        minWidth: closedWidth, 
        animation: 'fadeInGrow 75ms ease'
      }}
      >
        
        
      {/* SIDEBAR */}
      <div  className={`h-full flex flex-col flex-shrink-0 transition-all relative rounded-l-sm select-none transition-all duration-75
      overflow-y-scroll 
      `}
      style={{ width: sidebarWidth}} >
        
          
        <EditingPage />
        
        
      </div>
      {<Iconbar sidebarVisible={sidebarVisible} isDisabled={streaming} width={closedWidth} />}
      </div>  
  );
};

function EditingPage() {
  const { view, selector, 
    handleAction, set, 
    streaming, setStreaming, 
    actionsHistory, 
    effectiveVariables, 
    notifications, setNotifications

   } = useContext(EditorContext);

return (
  <div className={`flex flex-col flex-grow w-full h-full`}>
                    
                
                {view.rightSide === 'editor' && <PropEditor />}
                {view.rightSide === 'notes' && <FrameEditor selector={selector} />}
                {view.rightSide === 'elements' && (<Library />)}
                {view.rightSide === 'chat' && (<ChatWrapper 
                  selector={selector}
                  handleAction={handleAction}
                  set={set}
                  view={view}
                  streaming={streaming}
                  setStreaming={setStreaming}
                  actionsHistory={actionsHistory}
                  effectiveVariables={effectiveVariables}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  
                
                />)}
                {view.rightSide === 'theme' &&<Themes />}
                {view.rightSide === 'inspect' && (<Inspector />)}

</div>


)

}

const ChatWrapper = React.memo((props) => {

  const {selector, handleAction, set, view, streaming, setStreaming, actionsHistory, effectiveVariables, notifications, setNotifications} = props

  const [modelPreference, setModelPreference] = useState('gpt-4o-mini');
  return (
    <><div className='flex flex-row justify-between items-end w-full gap-2 mb-3 pt-3 pb-2 px-2.5 bg-base-50 border-b border-base-200'>
    <h1 className='text-md font-medium'>Copilot</h1>
    
    <div className='w-1/2'>
    <SegmentedSwitch
    size={'small'}
    value={modelPreference}
    options={[
      {label: 'Faster', value: 'gpt-4o-mini'},
      {label: 'Smarter', value: 'gpt-4o'},
    ]}
    onChange={(value) => setModelPreference(value)}
    />
    </div>
    
</div>

  <div
      className="w-full h-full overflow-hidden flex flex-col gap-2 p-3"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      
      <SuperChat 
        selector={selector}
        handleAction={handleAction}
        set={set}
        view={view}
        streaming={streaming}
        setStreaming={setStreaming}
        actionsHistory={actionsHistory}
        effectiveVariables={effectiveVariables}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </div>
    </>
)
}, compareState);

function compareState(prevProps, nextProps) {
  // This will return false (triggering a re-render) only if the props have actually changed
  return isEqual(prevProps, nextProps);
}