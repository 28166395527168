import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { getDescendants } from "utilities/helpers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PreviewRouter from "components/PreviewRouter";
import { StepperArray, ButtonIcon, Button, Heading, Icon } from "ui-kit/exports/react";


export default function SharePage({type, noControls}) {
  const [frame, setFrame] = useState(null);
  const [searchParams] = useSearchParams();
  const id = useParams().id;  
  
  const [theme, setTheme] = useState(null);
  const [assets, setAssets] = useState(null);
  
  const cssVariables = theme?.variables ? Object.fromEntries(
    Object.entries(theme?.variables).map(([key, value]) => [`--${key}`, value])
  ) : {}

  const headerProps = {
    frame,
    setFrame,
    setTheme,
    setAssets,
    id, 
    noControls
  }
  
  if (noControls) return (
    <div className="flex flex-col w-screen h-screen bg-base-200" 
    style={{
      ...cssVariables,
      color: 'var(--base-content)',
      fontFamily: `var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    }}
    >
      {type == 'frame' && <HeaderFrame {...headerProps}/>}
      {frame &&
      <Preview 
        frame={frame}
        assets={assets}
        designConfig={theme?.config}
        noControls={noControls}
      />}
    </div>
  ) 
  else {
    return (
    <div className="flex flex-col w-screen h-screen overflow-scroll pb-2 pt-1 px-2 gap-1">
      
      {type == 'frame' && <HeaderFrame {...headerProps}/>}
      {type == 'feature' && <HeaderFeature {...headerProps}/>}

      <div className="flex h-full w-full bg-base-0 overflow-scroll rounded-sm relative border border-current-20"
      style={{
        ...cssVariables,
        color: 'var(--base-content)',
        fontFamily: `var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
      }}
      >
      {frame &&
      <Preview 
        frame={frame}
        assets={assets}
        designConfig={theme?.config}
      />}
      </div>
    </div>
  );}
}


function Preview ({ frame, assets=[], designConfig, noControls }){
  const frameObjects = frame?.objects || [];
  const rootObject = frameObjects.find(obj => obj.parent === obj.frame);
  const objects = frameObjects.filter(obj => obj.id !== rootObject?.id) || [];
  
  
  if (noControls && rootObject) {
    return (
      <PreviewRouter
        key={rootObject.id}
        self={rootObject}
        assets={assets}
        designConfig={designConfig}
        descendants={getDescendants(rootObject.id, objects)}
      />
    );
  } else {
    return (
      <div className="relative flex flex-grow flex-col items-center justify-center user-select-none ">
        <PreviewRouter
          key={rootObject.id}
          self={rootObject}
          assets={assets}
          designConfig={designConfig}
          descendants={getDescendants(rootObject.id, objects)}
        />
      </div>
    );
  }
}

const fetchTheme = async (designSystemID, projectID, themeId) => {
  try {
    const response = await axios.post(`/design-system/${designSystemID}`, {
      userId: null, 
      projectId: projectID || null
    })
    
    const designSystem = response.data;
    const defaultTheme = designSystem?.themes?.find(theme => theme.id == designSystem.designSystem?.default_theme_id)
    const currentTheme = designSystem?.themes?.find(t => t.id == themeId)
    const theme = currentTheme || defaultTheme
    
    return theme
  } catch (error) {
    console.error("Error fetching design system data:", error);
  }
};

function HeaderFrame({ frame, setFrame, setTheme, setAssets, id, noControls }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!id) return;
    const fetchData = async () => {
      try {
        // Fetch frame data
        const frameResponse = await axios.get(`/api/share/frame/${id}`);
        const frameData = frameResponse.data;
        const themeId = frameData.project?.current_theme_id;
        
        setFrame(frameData.frameWithObjects);
        setAssets(frameData.assets);  // Set the assets state
  
        // If designSystem is linked, fetch its data
        const theme = await fetchTheme(frameData.designSystem?.id, frameData.project?.id, themeId);
        setTheme(theme);
      } catch (error) {
        console.error("Error fetching frame data:", error);
      }
    };
  
    fetchData();
  }, [id]);

  if (!noControls) return (
    <div className="relative flex flex-row items-center justify-start h-6 top-0 left-0 w-full text-sm gap-1.5 font-light">
      <div className="-ml-2">
        <Button text="Home" leftIcon="arrow-left" size="small" style="link"
        onClick={() => navigate("/")}
        />
      </div>
      <span className="absolute left-1/2 transform -translate-x-1/2">{frame?.name || "No frame data"}</span>
    </div>
  )
}

function HeaderFeature({ frame, setFrame, setTheme, setAssets, id }) {
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await axios.get(`/api/share/page/${id}`);
        const projectID = response.data?.project?.id;
        const themeId = response.data?.project?.current_theme_id;
        // Fetch design system data
        const theme = await fetchTheme(response.data?.designSystem?.id, projectID, themeId);
        setTheme(theme);
        setProject(response.data);

        // Set selectedFrame here after project data is loaded
        const initialFolder = response.data?.folders?.[0];
        const folderPages = response.data?.pages?.filter(p => p.folder_id === initialFolder?.id) || [];
        const initialPage = folderPages[0];
        const firstFrame = initialPage?.frames?.reduce((prev, current) => {
          return (prev.index < current.index) ? prev : current;
        }, initialPage?.frames[0]);
        
        setFrame(firstFrame);
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    };
    fetchPageData();
  }, [id]);
  
  const selectedPage = frame && project?.pages?.find(p => p.id == frame.page_id)
  let selectedFolder = selectedPage && project?.folders?.find(f => f.id == selectedPage.folder_id)
  selectedFolder = selectedFolder && {...selectedFolder, pages: project?.pages?.filter(p=>p.folder_id == selectedFolder.id)}

  return (
    <div className="relative flex flex-row items-center justify-between h-6 top-0 left-0 w-full text-sm gap-1.5 font-light">
      <div className="-ml-2">
        <Button text="Home" leftIcon="arrow-left" size="small" style="link"
        onClick={() => navigate("/")}
        />
      </div>
      <span className="absolute left-1/2 transform -translate-x-1/2">{frame?.name || "No frame data"}</span>
      <Navigator project={project} selectedFrame={frame} setSelectedFrame={setFrame} />
    </div>
  )
}


export function Navigator({project, selectedFrame, setSelectedFrame}) {
  const allFolders = project?.folders.filter(folder => !folder.is_archived).sort((a, b) => a.index - b.index);  
  const allFlows = project?.pages.filter(page => !page.is_archived && allFolders.map(f => f.id).includes(page.folder_id)).sort((a, b) => a.index - b.index);
  const allFrames = allFlows?.flatMap(f => f.frames).filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index);
  

  const framesByOrder = allFolders?.flatMap(folder => allFlows.filter(flow => flow.folder_id === folder.id).flatMap(flow => allFrames.filter(frame => frame.page_id === flow.id))) || []
  
  const currentIndex = framesByOrder.findIndex(frame => frame.id === selectedFrame?.id);
  
    let previousFrame = null;
    let nextFrame = null;
    
    if (currentIndex > 0) {
        previousFrame = framesByOrder[currentIndex - 1];
    }
    
    if (currentIndex < framesByOrder.length - 1) {
        nextFrame = framesByOrder[currentIndex + 1];
    }

    function selectNextFrame() {if (nextFrame) {setSelectedFrame(nextFrame)}}
    function selectPreviousFrame() {if (previousFrame) {setSelectedFrame(previousFrame)}}
    
    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'ArrowRight') {
          selectNextFrame();
        } else if (event.key === 'ArrowLeft') {
          selectPreviousFrame();
        }
      };
  
      window.addEventListener('keydown', handleKeyPress);
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [nextFrame, previousFrame]); 

  return (
      <div className="flex flex-row gap-2 items-center">
        <ButtonIcon 
          size={'small'}
          icon={'chevron-left'}
          style='ghost'
          onClick={selectPreviousFrame}
          state={previousFrame ? 'default' : 'disabled'}
        />
        
        <span className="w-8 bg-base-0 justify-center flex flex-row">
          {currentIndex + 1}
          /
          {allFrames?.length}
        </span>
        <ButtonIcon 
          size={'small'}
          icon={'chevron-right'}
          style='ghost'
          onClick={selectNextFrame}
          state={nextFrame ? 'default' : 'disabled'}
        />
     </div>
    )
}