import { useContext, useEffect } from "react";
import { EditorContext } from "../EditorContext";
import * as IconoirIcons from 'iconoir-react';

export default function Iconbar({ sidebarVisible, isDisabled, width }) {
    const { view, setView, streaming } = useContext(EditorContext);
  
    const handleClick = (viewName) => {

      if (isDisabled) return
      
      if (!sidebarVisible) { // open if sidebar is closed
        
        setView({ ...view, rightSide: viewName })
      } else if (viewName == view.rightSide) { // close if we're clicking on the same icon
        setView({ ...view, rightSide: null })
      } else { // redirect to new view if we're clicking on a different icon
        setView({ ...view, rightSide: viewName })
      }
      
    };
  
    const allIcons = [
      {
        icon: 'Plus',
        name: 'Library',
        value: 'elements'
      },
      {
        icon: 'DesignPencil',
        name: 'Editor',
        value: 'editor'
      }, 
      {
        icon: 'Sparks',
        name: 'Agents',
        value: 'chat'
      },
      {
        icon: 'Code',
        name: 'Code',
        value: 'inspect'
      },
      {
        icon: 'ColorWheel',
        name: 'Theme',
        value: 'theme'
      },
      {
        icon: 'Settings', 
        name: 'Notes',
        value: 'notes'
      }
    ]
  
    const icons = view.mode == 'editor' ? allIcons : allIcons.filter(i => i.value != 'editor' && i.value != 'elements' && i.value != 'assets')
    const selectedIndex = view.rightSide ? icons.findIndex(i => i.value == view.rightSide) : null

    return (
      <div className={`flex-shrink-0 flex flex-col py-3 gap-5 items-stretch justify-start text-base-content transition-all duration-100
      relative pb-48 ${streaming ? 'grayscale' : ''}`}
        style={{
          // borderLeftWidth: sidebarVisible ? 1 : 0,
          width: width, 
          pointerEvents: streaming ? 'none' : 'all',
          // background: sidebarVisible ? `var(--base-0)` : `linear-gradient(to right, var(--base-100), transparent)`
          
        }}>

<div
    className="absolute top-0 left-0 h-full transition-all duration-75"
    style={{
      width: sidebarVisible ? 1 : 0,
      background: sidebarVisible ? `var(--base-200)` : `linear-gradient(to bottom, transparent, var(--base-200), transparent)`
    }}
  />
          
          {/*selectedIndex != null ? <div className="absolute left-0 -translate-y-1/2 bg-primary rounded-sm w-0.5 h-8 transition-all duration-150"
            style={{top: 12 + selectedIndex * 12 + (selectedIndex + 0.5) * 32}} /> : null*/}
          
         {icons.map(({ icon, name, value }) => (
          <IconButton
            key={value}
            styles='text-xs font-semibold hover:opacity-100 transition-all duration-150 relative'
            icon={icon}
            name={name}
            value={value}
            sidebarVisible={sidebarVisible}
            handleClick={() => handleClick(value)}
            isSelected={sidebarVisible && view.rightSide === value}
          />
        ))}
        
        
        {/*sidebarVisible && <OpenCloseRightSide isDisabled={isDisabled} />*/}
      </div>
    );
  }
  
  function IconButton({ styles, icon, name, value, handleClick, isSelected, sidebarVisible}) {
    
    const selectedStyles = isSelected ? 'opacity-100 text-primary' : 'opacity-70 hover:opacity-100 hover:text-primary'
    const DynamicIcon = IconoirIcons[icon] || null;
    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap text-gray-100
    w-auto bg-slate-800 border border-gray-800  font-normal rounded-md px-2 py-0.5 text-2xs 
    top-1/2 mb-2 -left-4 transform -translate-y-1/2 -translate-x-full transition-all`
    
    const iconSize = icon == 'Plus' ? isSelected ? 28 : 24 : isSelected ? 24 : 20
    return (
      <div className='flex flex-row justify-center items-center relative'>
         <div className={` left-0 top-1/2 -translate-y-1/2 bg-primary rounded-sm w-[1.5px] h-8 transition-all duration-150
        ${isSelected ? 'absolute' : 'hidden'}  transition-all duration-150
         `}
             />
      <div 
        className={`${styles} ${selectedStyles}
        flex cursor-pointer transition-all w-8 h-8 flex rounded-lg flex-col items-center justify-center select-none gap-1
        group relative hover:scale-110 duration-150`}
        
        onClick={() => handleClick(value)}
        
      >
        {DynamicIcon && <DynamicIcon height={iconSize} width={iconSize} 
        
        />}
        
        {!sidebarVisible && <span className={tooltipStyle} style={{ zIndex: 1001 }}>{name}</span>}
        
      </div>
      
      </div>
    );
  }
  
  

  
  function OpenCloseRightSide({isDisabled}) {
    
    const { view, setView } = useContext(EditorContext);
    
    return (
      <div className='w-full flex flex-row items-center justify-center cursor-pointer mb-3'
        
      >
        <div
        className={`w-8 h-8 select-none rounded-full font-normal text-xl text-gray-700 transition-all flex items-center justify-center cursor-pointer`}
         
         onClick={() => !isDisabled && setView({...view, rightSide: null})}>
  
      <IconoirIcons.TransitionRight width={20} height={20} />

      </div>
  
      </div>
    );
  }