import * as Iconoir from 'iconoir-react';
import { gridDescendants } from "./grid"
import { useContext, useEffect, useRef, useState } from "react"
import { nanoid } from 'nanoid';
import DnDContext from "../dragNdrop/DnDContext";
import { EditorContext } from "../EditorContext";
import { canPasteSimplified } from 'utilities/pasteRules';

export default function Toolbar() {
    
    const { handleDragStart, handleDragEnd } = useContext(DnDContext);
    const { selector, view, handleAction } = useContext(EditorContext);
    
    const iconAttributes = {
        width: 18, 
        height: 18,
        className: 'stroke-[1.6px] group-hover:stroke-[1.8px] flex-shrink-0 transition-all duration-75'
    }

    const list = [
        { icon: <Iconoir.ViewColumns3 {...iconAttributes} className='stroke-[1.6px] rotate-90' />, title: 'Stack', componentAPIName: 'FlexBox', props: {direction: 'flex-col', paddingX: '12px', paddingY: '12px', gap: '12px'},
            items: [
                { icon: <Iconoir.ViewColumns3 {...iconAttributes} className='rotate-90' />, title: 'v stack', componentAPIName: 'FlexBox', props: {direction: 'flex-col', paddingX: '12px', paddingY: '12px', gap: '12px'} },
                { icon: <Iconoir.ViewColumns3 {...iconAttributes} />, title: 'h stack', componentAPIName: 'FlexBox', props: {direction: 'flex-row', paddingX: '12px', paddingY: '12px', gap: '12px'} },
                { icon: <Iconoir.RubikCube {...iconAttributes} />, title: 'Grid', componentAPIName: 'Grid', props: {}, descendants: gridDescendants },
            ]
        },
        { icon: <Iconoir.Text {...iconAttributes} />, title: 'Text', componentAPIName: 'Text', props: {}, 
            items: [
                { icon: <Iconoir.Text {...iconAttributes} />, title: 'heading', componentAPIName: 'Heading', props: {} },
                { icon: <Iconoir.AlignLeft {...iconAttributes} />, title: 'paragraph', componentAPIName: 'Paragraph', props: {} },
                { icon: <Iconoir.TextBox {...iconAttributes} />, title: 'text', componentAPIName: 'Text', props: {} },
                { icon: <Iconoir.Link {...iconAttributes} />, title: 'link', componentAPIName: 'Link', props: {} },
            ]
        },
        { icon: <Iconoir.PanoramaEnlarge {...iconAttributes} />, title: 'Button', componentAPIName: 'Button', props: {}, 
            items: [
                { icon: <Iconoir.PanoramaEnlarge {...iconAttributes} />, title: 'light', componentAPIName: 'Button', props: {} },
                { icon: <Iconoir.PanoramaEnlarge {...iconAttributes} />, title: 'filled', componentAPIName: 'Button', props: {style: 'filled'} },
                { icon: <Iconoir.Iconoir {...iconAttributes} />, title: 'icon btn', componentAPIName: 'ButtonIcon', props: {} },
            ]
        },
        { icon: <Iconoir.InputField {...iconAttributes} />, title: 'Input', componentAPIName: 'InputText', props: {}, 
        items: [
            { icon: <Iconoir.InputField {...iconAttributes} />, title: 'input', componentAPIName: 'InputText', props: {} },
            { icon: <Iconoir.ArrowDownTag {...iconAttributes} />, title: 'select', componentAPIName: 'Select', props: {} },
            { icon: <Iconoir.CheckSquare  {...iconAttributes} />, title: 'check', componentAPIName: 'Checkbox', props: {} },  
            { icon: <Iconoir.Calendar  {...iconAttributes} />, title: 'calendar', componentAPIName: 'MiniCalendar', props: {} },
            // toggle, switch,
        ]}, 
        { icon: <Iconoir.MediaImage {...iconAttributes} />, title: 'Media', componentAPIName: 'Image', props: {}, 
            items: [
                { icon: <Iconoir.MediaImage {...iconAttributes} />, title: 'image', componentAPIName: 'Image', props: {} },
                { icon: <Iconoir.Emoji  {...iconAttributes} />, title: 'avatar', componentAPIName: 'Avatar', props: {} },
                { icon: <Iconoir.Label {...iconAttributes} />, title: 'badge', componentAPIName: 'Badge', props: {} },
                { icon: <Iconoir.Flower  {...iconAttributes} />, title: 'logo', componentAPIName: 'Logo', props: {} },
                { icon: <Iconoir.XrayView {...iconAttributes} />, title: 'icon', componentAPIName: 'Icon', props: {} },
                
                // icon
            ]
        },
        { icon: <Iconoir.Table2Columns  {...iconAttributes} />, title: 'Data', componentAPIName: 'TableWidget', props: {}, 
        items: 
            [
                { icon: <Iconoir.Table2Columns  {...iconAttributes} />, title: 'TData', componentAPIName: 'DataTable', props: {} },
                { icon: <Iconoir.TableRows  {...iconAttributes} />, title: 'TWidget', componentAPIName: 'DataWidget', props: {} },
                { icon: <Iconoir.Reports  {...iconAttributes} />, title: 'bars', componentAPIName: 'BarChart', props: {} },
                { icon: <Iconoir.GraphUp  {...iconAttributes} />, title: 'lines', componentAPIName: 'LineChart', props: {} },
            ] 
        },
        
    
        // need indicators
    ]

    if (view.mode == 'eye') return null;

    return (
      <div className={`flex flex-row w-auto h-18 absolute top-1 transition-all duration-150
        left-1/2 -translate-x-1/2 items-start`}

      style={{zIndex: 2000, animation: 'fadeIn 100ms ease-in-out'}}>
            {list.map((item, index) => (
                <Wrapper
                key={index}
                mainChild={<Element
                    item={item}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    frame={selector?.frame}
                    isMainChild={true}
                    handleAction={handleAction}
                />}
                >
                {item.items && item.items.map((item, index) => (
                <Element
                    key={index}
                    item={item}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    frame={selector?.frame}
                    selectedObject={selector?.object}
                    isMainChild={false}
                    handleAction={handleAction}
                />))}
                </Wrapper>
            ))}
      </div>
    )
  }


  function Wrapper({mainChild, children}) {
    const [isOpen, setIsOpen] = useState(false);
    
    const myRef = useRef(null);
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', (e) => {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    setIsOpen(false);
                }
            })
        }
    }
    , [isOpen])

    return(
        <div className="relative"
        ref={myRef}
        >   
            <div onClick={() => setIsOpen(!isOpen)}>
            {mainChild}
            </div>
            {isOpen && <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 translate-y-full z-50"
            // 
            >
            <div className='flex flex-row gap-1 p-1.5
            min-w-10 min-h-10 bg-base-0 rounded-lg ring-[0.5px] ring-current-20 shadow-lg transition-all duration-150'
            style={{animation: 'fadeInGrowDown 150ms ease-in-out'}}
            >
            {children}
            </div>
            </div>}
        </div>
    )
}

function Element({item, 
    selectedObject, handleAction,
    handleDragStart, handleDragEnd, frame, isMainChild
}) {
    const frameId = frame?.id
   const buttonContainer = `relative group hover:bg-current-5 cursor-grab
    select-none w-14 flex flex-col rounded p-1 pb-0.5 items-center justify-center text-[11px] gap-0.5 font-normal
   transition-all duration-75 hover:scale-105 capitalize 
   `
    const myRef = useRef(null);

    const rootObject = {
        id: nanoid(10),
        APIName: 'div',
        componentAPIName: item.componentAPIName,
        index: 1, 
        parent: 'rootObject',
        frame: frameId,
        mobile_props: {},
        text: '',
        object_props: item.props || {},
    }

    const descendants = item?.descendants && item.descendants.length > 0 
        ? item.descendants.map(descendant => ({...descendant, frame: frameId, parent: rootObject.id}))
        : [];

    const self = {
        source: 'library', 
        componentAPIName: rootObject?.componentAPIName,
        objects: [...descendants, rootObject],
    }

    const canDrag = true;

    // if not isMainChild, add function to add onClick
    let canPaste = !selectedObject ? false : canPasteSimplified(selectedObject?.componentAPIName, item.componentAPIName) || false

    function paste() {

        if (!canPaste) return

        const parent = selectedObject
        const siblings = frame.objects.filter((o) => o.parent == parent.id);
        
        const action = {
        type: "INSERT_OBJECT",
        object: rootObject,
        parent: parent,
        index: siblings.length + 1,
        descendants,
        };
        handleAction(action);
    }

    return(
    <div className={buttonContainer}
    ref={myRef}
    draggable={canDrag}
    onClick={()=>canPaste && paste()}
    onDragStart={(event) => {canDrag && event.stopPropagation(); handleDragStart(event, self, myRef)}}
    onDragEnd={(event) => {canDrag && event.stopPropagation(); handleDragEnd(event, self, myRef)}}
    >
              {item.icon}
              {item.title}
    </div>
    )
    
  }

