import { prepareFrameJSXwithIDs } from "pages/editor/chat/helpers";
import { useContext, useRef, useState } from "react";
import { ChatInput } from "ui-kit/exports/react";
import UserContext from "UserContext";
import { preparePrimitives } from "../elements/helpers";
import * as UIKit from 'ui-kit/local/index'
import { allIconNames } from "ui-kit/local/components/iconMap";
import { EditorContext } from "pages/editor/EditorContext";
import { Loader, TextArea, ButtonIcon, Icon, Select, SegmentedSwitch } from "ui-kit/exports/react";
import { uploadImage } from "utilities/files";

export default function TableEditor({selector, handleAction}) {
    const jsx = selector ? prepareFrameJSXwithIDs(selector) : '';
    const {user} = useContext(UserContext);
    const {streaming, setStreaming} = useContext(EditorContext);
    const token = user ? user.token : null;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ fileURL, setFileURL ] = useState('');
    const [ status, setStatus ] = useState('');
    const primitives = preparePrimitives(UIKit)

    const inputRef = useRef(null);
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const fileName = Date.now()+file.name
                const uploadedImageUrl = await uploadImage(file, fileName);
                setFileURL(uploadedImageUrl);
                
            } catch (err) {
                console.error("Failed to upload image:", err);
            }
        }
    };

    async function handleSubmit(e, message) {
      e.preventDefault();
      setStreaming(true);
      setIsLoading(true);
    
      const tableObject = selector?.object;
      const prompt = `User prompt:
      ${message}
    
      User is looking at: 
      ${jsx}`;
    
      try {
        const payload = {
          primitives: primitives,
          icons: allIconNames,
          table: tableObject.object_props,
          image: fileURL,
          prompt,
          model
        };
    
        const response = await fetch(`/api/agents/table-edit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });
    
        // Use response.body.getReader() with a while loop
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';
    
        while (true) {
          const { done, value } = await reader.read();
          
          if (done) {
            // Process any remaining buffer
            if (buffer) {
              processChunk(buffer);
            }
            console.log('Stream complete');
            setStreaming(false);  
            setIsLoading(false);
            setStatus('Table updated');
            break;
          }
    
          // Decode the chunk
          buffer += decoder.decode(value, { stream: true });
          
          // Process complete lines
          const lines = buffer.split('\n');
          
          // Keep any incomplete line in the buffer
          buffer = lines.pop();
          
          // Process each complete line
          for (const line of lines) {
            if (line.trim()) {
              processChunk(line);
            }
          }
        }
    
        function processChunk(chunk) {
          try {
            // console.log('Raw chunk:', chunk); // This will log in real-time
            const parsed = JSON.parse(chunk);
            // console.log('Parsed Message:', parsed); // This will log in real-time
    
            if (parsed.status === 'complete') {
              const action = {
                type: 'UPDATE_OBJECT',
                currentObject: tableObject,
                newObject: { ...tableObject, object_props: parsed.data },
              };
    
              handleAction(action);
              setIsLoading(false);
              setStreaming(false);
              setStatus('Table updated');
            } else {
              setStatus(parsed?.message || 'Processing...');
            }
          } catch (err) {
            console.error('Error parsing message:', err);
            setStatus('Something went wrong');
            setIsLoading(false);
            setStreaming(false);
          }
        }
      } catch (error) {
        console.error('Error generating table:', error);
        setStatus('Something went wrong');
        setIsLoading(false);
        setStreaming(false);
      }
    } 
    const handleStop = () => {
        setIsLoading(false);
        setStreaming(false);
    }


    const chainOptions = [
      {label: 'auto', value: 'auto'},
      {label: 'new_table', value: 'new_table'},
      {label: 'edit_table', value: 'edit_table'},
      {label: 'edit_config', value: 'edit_config'},
      {label: 'edit_data', value: 'edit_data'}
    ]
    const [chain, setChain] = useState(chainOptions[0].value)

    const modelOptions = [
      {label: 'Faster', value: 'gpt-4o-mini'},
      {label: 'Smarter', value: 'gpt-4o'}
    ]
    const [model, setModel] = useState(modelOptions[0].value)


    return (
        <div className="w-full text-sm gap-2 flex flex-col mb-8">
            <div className="flex justify-between gap-2 relative">
            <SegmentedSwitch 
              value={model}
              options={modelOptions}
              onChange={val => setModel(val)}
              size='small'
              width="1/2"
            />
            {/*<Select
            placeholder="Chain"
            size='small'
            width="full"
            value={chain}
            options={chainOptions}
            onChange={val => setChain(val)}

            />*/}
            
            {fileURL ? <div 
              
              style={{width: '60px', height: '40px', backgroundImage: `url(${fileURL})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
              className="absolute top-0 right-0 rounded ring-1 ring-current-20 shadow-lg z-10 rotate-1 "
            >
              <Icon icon="xmark" size="20px"  className="absolute -top-2 -right-2 z-20 hover:scale-110" onClick={() => setFileURL(null)}/>
            </div> :
            <ButtonIcon 
                icon="image"
                size='small'  
                style="ghost"
                onClick={() => inputRef.current.click()}
              />}
            </div>
            <TextArea
              value={message}
              onChange={e => { setMessage(e.target.value); setStatus(''); }}
              placeholder="Describe table changes here"
              state={isLoading ? "loading" : "default"}
              size='small'
              onKeyDown={e => e.key === 'Enter' && handleSubmit(e, message)}
              rows={4}
            />
            <div className="flex justify-between gap-2 items-start">
                <span className="text-xs pl-2 text-opacity-50 flex gap-2 items-center">
                
                </span>
                <input
                type="file"
                ref={inputRef}
                className="w-1/2 h-12 bg-base-0 rounded-lg hidden"
                onChange={handleImageUpload}
              />
              <div className="flex gap-2 items-center">
              {isLoading && <Loader  size="16px" type="pulse" color='primary' opacity='70' />}
              <ButtonIcon
                onClick={e => isLoading ? handleStop() : handleSubmit(e, message)}
                state={message === '' ? "disabled" : "default"}
                size='small'
                isPill
                icon={isLoading ? "pause" : "arrow-up"}
                style="filled"
                />
                </div>
            </div>
        </div>
        
    )
}