import { useContext, useState } from 'react';
import { Button, ButtonIcon, Divider, Heading, Icon, Logo } from '../../../../ui-kit/exports/react';
import ThemePreview from "./ThemePreview"
import Logos from './logos/Logos';

export default function ThemeSummary({themes, project, currentTheme, onSelect, onAdd, setState}) {
    
    return (
        <>
                    <ThemeList
                    themes={themes}
                    currentTheme={currentTheme}
                    selectTheme={onSelect}
                    onAdd={onAdd}   
                    onEdit={() => setState('edit')}
                    />
                    <Divider />
                    <Brand 
                        project={project} 
                        onEdit={() => setState('assets')}
                        />
                    <Divider />
                    <Install />
                    </>
    )
}


function ThemeList({themes, currentTheme, selectTheme, onAdd, onEdit}) {
    function renderList(themes) {
        return <div className="grid grid-cols-2 w-full gap-3 items-stretch p-3">
        {themes
        .sort((a, b) => a.display_name.localeCompare(b.display_name))
        .sort((a, b) => b.is_default - a.is_default)
        .map(theme=>
        <ThemePreview
            key={theme.id} 
            theme={theme} 
            isSelected={currentTheme?.id == theme.id}
            onSelect={selectTheme}
            onEdit={onEdit}
        />
        )}
        </div>
    }
    return (
        <>
                    <div className='p-3 pb-0 flex flex-row justify-between items-end'>
                        <Heading size='small' text='Themes' />
                        <div className={'flex gap-2'}>
                        <ButtonIcon
                            size={'small'}
                            icon='edit'
                            style='ghost'
                            onClick={onEdit}
                        />
                        <ButtonIcon
                            size={'small'}
                            icon='plus'
                            onClick={() => onAdd()}
                        />
                        </div>
                    </div>
                    {renderList(themes)}
                    </>
    )
}


function Brand({project, onEdit}) {
    const projectConfig = project?.project?.config || {}
    const brand = projectConfig?.brand || {}

    // Define asset map
    const modifiedConfig = {brand: brand}
    const __juno = {designConfig: modifiedConfig}
    
    const tooltip = `absolute bottom-1.5 left-1/2 -translate-x-1/2 text-xs text-base-500 opacity-0 group-hover:opacity-100 transition-opacity duration-150`

    return (
        <>
        <div className='p-3 flex flex-row justify-between items-center'>
             
             <Heading size='small' text='Brand' />
             <ButtonIcon
             icon={'edit'}
             size='small'
            style='ghost' 
             onClick={onEdit}
              />
        </div> 
        <div className={`flex flex-row gap-2 pt-0 p-3`}>
            <div className='p-2 h-20 bg-base-50 rounded-md flex items-center justify-center w-2/3 overflow-hidden relative group'>
                <Logo type='logo' size='16px' __juno={__juno} />
                <span className={tooltip}>
                    logo
                </span>
            </div>
            <div className='p-2 h-20 bg-base-50 rounded-md flex items-center justify-center w-1/3 overflow-hidden relative group'>
                <Logo type='symbol' size='28px' __juno={__juno} />
                <span className={tooltip}>
                    symbol
                </span>
            </div>
        </div>
        </>
    )
}

function Install({}) {
    return (
        <div className='p-3 flex flex-row justify-between items-end'>
             <Heading size='small' text='Install' />
        </div>   
    )
}