import React, { isValidElement, useLayoutEffect, useRef } from "react";
import { spacingMap } from "../helpers";
import { ButtonIcon } from "../";
import { useMediaQuery } from "react-responsive";
import { IconType, allIconNames } from '../iconMap';

type Props = {
    height?: 'auto' | '30%' | '50%' | '320px' | '80%' | (string & {}),
    isClosed?: boolean;
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
    overlay?: 'dark' | 'light' | 'none' | null;
    children?: React.ReactNode;
    rightButton?: any | IconType;
    leftButton?: any | IconType;
    title?: string;
    paddingX?: '0px' | '4px' | '8px' | '12px' | '16px' | '20px' | '24px', 
    paddingY?: '0px' | '4px' | '8px' | '12px' | '16px' | '20px' | '24px',
    gap?: '4px' | '8px' | '12px' | '16px' | '20px' | '24px',
    bgColor?: 'transparent' | 'base-0' | (string & {}),
    __juno?: any
  }
  
export default function BottomSheet({  
    height = 'auto',
    isClosed,
    corners = '2xl', 
    rightButton,
    leftButton, 
    title, 
    overlay,
    children,
    paddingX = '20px',
    paddingY = '12px',
    gap = '12px',
    bgColor = 'base-0',
    __juno = {}
  }: Props) {
    const sheetRef = useRef<HTMLDivElement>(null)
    
    // rounded-t-none rounded-t-sm rounded-t-base rounded-t-md rounded-t-lg rounded-t-xl rounded-t-2xl rounded-t-3xl
    const cornersStyles = corners ? `rounded-t-${corners}` : ''
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : ''
    const paddingStyles = `${paddingX ? `px-${spacingMap[paddingX]}` : ''} ${paddingY ? `py-${spacingMap[paddingY]}` : ''}`
    const bgStyles = bgColor ? `bg-${bgColor}` : ''
    
    const originalPaddingRef = useRef<string>('')
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  
    useLayoutEffect(() => {
      if (overlay) return
      if (!isMobile) return
      const mainElement = document.getElementById('main-test')
      if (!mainElement) return
  
      // Store original padding only once
      if (!originalPaddingRef.current) {
        originalPaddingRef.current = window.getComputedStyle(mainElement).paddingBottom
      }
  
      // Get the boundingClientRect of the bottom sheet
      const rect = sheetRef.current?.getBoundingClientRect()
      
      if (rect) {
        // How tall the sheet is from the bottom edge
        const offsetFromBottom = window.innerHeight - rect.top
        const newPadding = `calc(${originalPaddingRef.current} + ${offsetFromBottom}px)`
        mainElement.style.paddingBottom = newPadding
      }
  
      return () => {
        mainElement.style.paddingBottom = originalPaddingRef.current
      }
    }, [height, isClosed, overlay, isMobile])
  
    const overlayClasses = `${!overlay ? 'hidden' : 'fixed flex top-0 left-0  flex-col'} @md:hidden w-full h-full ${overlay == 'dark' ? 'bg-current-20' : 'bg-current-10'}`
     
     let stickyChildren = []
      let normalChildren = []
      React.Children.forEach(children, (child) => {
        if (isValidElement(child)) {
        const { type } = child;
    
        // Determine the default name of the component
        let typeName: string =
            typeof type === 'string' // For intrinsic elements like 'div', 'span'
            ? type
            : (type.name || 'Unknown'); // For function or class components
    
        
        // Override with props.self.componentAPIName if it exists
        typeName = child.props?.self?.componentAPIName || typeName;

        if (typeName === 'Sticky') {
          stickyChildren.push(child)
        } else {
          normalChildren.push(child)
        }
        }
      }
      )

    
    return (
      <>
      {overlay && overlay !== 'none' && 
      <div 
      className={overlayClasses} 
      style={{zIndex: 9}}
      />}
      
      <div
        className={`!fixed bottom-0 left-0 right-0 flex flex-col transition-all duration-150 @md:hidden
          ${cornersStyles}  ${bgStyles}
          outline outline-[0.5px] outline-current-20 ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        ref={sheetRef}
        // data-tag={__juno?.attributes?.['data-tag'] /* remove line */} // for drag & drop to pick on this element
        {...__juno?.attributes}

        style={{
          boxShadow: '0 -2px 12px rgba(0,0,0,0.05)',
          zIndex: 10,
          maxHeight: height,
        }}
      >
        {/* title area */}
        {(title || leftButton || rightButton) && <div className={`${paddingStyles} flex-shrink-0 w-full flex-row justify-between items-center max-h-16 gap-3 border-b border-current-20`}>
                <div className={`${!leftButton ? 'opacity-0 pointer-events-none' : 'flex-shrink-0'} -ml-2`}>
                  <ButtonIcon icon={leftButton} size="small" style="ghost" />
                </div>
                <h2 className={`text-lg font-medium w-full whitespace-nowrap truncate text-center`}>
                  {title} 
                </h2>
                <div className={`${!rightButton ? 'opacity-0 pointer-events-none' : 'flex-shrink-0'}  -mr-2`}>
                  <ButtonIcon icon={rightButton} size="small" style="ghost" />
                </div>
          </div>}
        <div className="overflow-y-auto transition-all duration-150">
          <div className={`whitespace-pre-wrap text-left overflow-y-auto  transition-all duration-150 ${isClosed ? 'translate-y-full hidden' : `flex flex-col w-full ${paddingStyles} ${gapStyles}`}`}
          style={{height: isClosed ? '0px' : '100%'}}>
            {normalChildren}
          </div>
            {stickyChildren}
        </div>
        
      </div>
      </>
    )
  }
  
  BottomSheet.definitions = {
    apiName: 'BottomSheet',
    displayName: 'BottomSheet',
    description: `BottomSheet`,
    ai_instructions: `used on mobile devices to show additional content that can be hidden`,
    type: 'dialogs',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    relativeSize: 'large',
    status: 'prototype',
    package: 'Starter',
    propDefinitions: {
      title: {
        type: 'string',
        displayName: 'Title',
        description: 'Title of the bottom sheet',
        default: null, 
        editable: true
      },
      bgColor: {
        type: 'oneOf',
        description: 'Background color',
        defaultValue: 'base-0',
        options: ['transparent', 'base-0', 'base-50', 'base-100'], 
        tile: '1/2'
    },
      height: {
        type: 'oneOf',
        description: 'Height of the bottom sheet',
        default: 'auto',
        displayName: 'Max Height',
        options: ['auto', '30%', '50%', '320px', '80%'],
        tile: '1/2'
      },
      overlay: {
        type: 'oneOf',
        description: 'Whether to show an overlay',
        default: null,
        options: ['dark', 'light', 'none', null],
        tile: '1/2'
      },
      
      corners: {
        type: 'oneOf',
        description: 'Corner radius of the bottom sheet',
        default: '2xl',
        displayName: 'Corners',
        options: ['none', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'],
        tile: '1/2'
      },
      paddingX: {
        type: 'oneOf',
        description: 'Horizontal padding',
        default: '20px',
        displayName: 'Padding X',
        options: ['0px', '4px', '8px', '12px', '16px', '20px', '24px'], 
        tile: '1/2'
      },
      paddingY: {
        type: 'oneOf',
        description: 'Vertical padding',
        default: '12px',
        displayName: 'Padding Y',
        options: ['0px', '4px', '8px', '12px', '16px', '20px', '24px'],
        tile: '1/2'
      },
      isClosed: {
      type: 'bool',
      displayName: 'Is closed',
      description: 'Whether the bottom sheet is closed',
      default: false, 
      tile: '1/2'
    },
    leftButton: {
      type: 'icon',
      options: allIconNames,
      displayName: 'Left Icon',
      default: null
  },
  rightButton: {
      type: 'icon',
      options: allIconNames,
      displayName: 'Right Icon',
      default: null
  },
    }
  }
  
  