import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../../EditorContext";
import { Alert, Button, ButtonIcon, Checkbox, InputText, MiniSnippet, TextArea, ToggleSwitch } from "../../../../ui-kit/exports/react";

export default function FrameEditor({selector}) {
    const { handleAction, isAdmin, project } = useContext(EditorContext);
    
    const currentFrame = selector?.frame;

    // State for input and textarea
    const [frameName, setFrameName] = useState(currentFrame?.name);
    const [frameNotes, setFrameNotes] = useState(currentFrame?.notes || '');
    const [isTemplate, setIsTemplate] = useState(currentFrame?.use_template || false);

    useEffect(() => {
        setFrameName(currentFrame?.name);
        setFrameNotes(currentFrame?.notes || '');
        setIsTemplate(currentFrame?.use_template || false);
    }, [currentFrame]); // Only currentFrame in the dependency array
      

    function updateFrameName() {
        const action = { type: 'UPDATE_FRAME', currentFrame: currentFrame, newFrame: { ...currentFrame, name: frameName }};
        handleAction(action);
    }

    function updateFrameNotes() {
        const action = { type: 'UPDATE_FRAME', currentFrame: currentFrame, newFrame: { ...currentFrame, notes: frameNotes }};
        handleAction(action);
    }

    function handleUseTemplate() {
        // Toggle the checked state
        const newCheckedState = !isTemplate;
        setIsTemplate(newCheckedState);
    
        // Dispatch the action with the new state
        const action = { 
            type: 'UPDATE_FRAME', 
            currentFrame: currentFrame, 
            newFrame: { ...currentFrame, use_template: newCheckedState }
        };
        handleAction(action);
    }
    

    function toggleFavorite() {
        
        // Dispatch the action with the new state
        const action = { 
            type: 'UPDATE_FRAME', 
            currentFrame: currentFrame, 
            newFrame: { ...currentFrame, is_favorite: !currentFrame.is_favorite }
        };
        handleAction(action);
    }
    
    return (
        <>
        <div className='flex flex-row justify-between items-end w-full gap-2 mb-3 pt-3 pb-2 px-2.5 
                    bg-base-50 border-b border-base-200
                    '>
                    <h1 className='text-md font-medium'>
                        {currentFrame?.name || 'Frame'}
                    </h1>
                    <ButtonIcon 
                        size="small"
                        style="ghost"
                        icon='menu'
                    />
          </div>
        <div className="flex flex-col items-start gap-3 text-sm px-2.5">
            {/*<InputText 
                value={frameName}
                label={null}
                placeholder="Frame Name"
                bgColor={'base-50'}
                hasOutline={false}
                onChange={(e) => setFrameName(e.target.value)}
                width={'full'}
                onBlur={(e) => updateFrameName()}

            />*/}
            
            <TextArea 
                value={frameNotes}
                label={null}
                bgColor={'base-0'}
                hasCharacterCount={false}
                onChange={e => setFrameNotes(e.target.value)}
                onBlur={()=>updateFrameNotes()}
                hasOutline
                placeholder="Add page notes, e.g. description or notes for the developer"
                rows={4}
                width={'full'}
            />

            <div className="grid grid-cols-2 w-full gap-3">
            <ToggleSwitch
                    label={'Template'}
                    type={'primary'}
                    style="rectangle"
                    checked={isTemplate}
                    onChange={handleUseTemplate}
                />

                <ToggleSwitch
                    checked={currentFrame.is_favorite}  
                    type={'primary'}
                    style="rectangle"
                    onChange={toggleFavorite}
                    label={'Favorite'}
                />
            
            
                {isAdmin &&  
                <Button 
                    text='Copy ID' 
                    leftIcon="copy"
                    width="auto"
                    size="small"
                    onClick={() => navigator.clipboard.writeText(selector.frame?.id)}
                />}
                </div>
                
        </div>
        </>
    );
}


