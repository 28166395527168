import { useState } from "react";
import { RecorderButton, ButtonIcon } from "../";

type Props = {
    size?: 'small' | 'medium',
    state?: 'default' | 'disabled' | 'error' | 'success' | 'loading',
    hasOutline?: boolean,    
    mini?: boolean,
    acceptFiles?: boolean,
    acceptAudio?: boolean,
    isPill?: boolean,
    buttonColor?: 'primary' | 'accent',

    placeholder?: string, 
    value?: string,   
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onSend?: (e: React.KeyboardEvent<HTMLTextAreaElement>, content: string) => void,

    __juno?: any,
    
}

// extra props to consider: styling (bgcolor, outline, size)

export default function ChatInput({
    state,
    mini, 
    size = 'medium',
    acceptFiles, 
    acceptAudio,
    isPill,
    buttonColor, 
    placeholder,
    value, 
    onFocus,
    onSend,
    __juno,
    
}: Props) {

    const [content, setContent] = useState('');
    const [files, setFiles] = useState([]);

    const alignStyles = mini ? 'items-center' : 'items-end'
    const cornerStyles = isPill ? mini ? 'rounded-full' : 'rounded-2xl' : 'rounded-lg'
    const direction = mini || (!acceptFiles && !acceptAudio) ? 'flex-row' : 'flex-col items-stretch'
    const bgStyles = 'bg-current-5 focus-within:bg-current-10'
    const sizeStyles = mini ? acceptFiles ? 'py-2.5 px-2.5' : 'py-2.5 pl-3 pr-2.5' : 'py-3 px-3'
    const gapStyles = 'gap-2'

    const classes = `flex ${direction} w-full text-auto bg-current-5 ${alignStyles} justify-start ${cornerStyles} relative
        ${bgStyles} ${sizeStyles} ${gapStyles}`

    function handleSubmit(e) {
        e.preventDefault();
        if (content.length == 0) return
        onSend && onSend(e, content);
        setContent('');
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
        if (event.key === "Enter") {
          handleSubmit(event);
        }
    }

    const textSize = size === 'small' ? 'text-sm' : 'text-base'

    return (
        <div className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`} {...__juno?.attributes}>
        <div className='absolute right-0 top-0 bottom-0 left-0' /> {/* remove line */}
        {acceptFiles && mini &&
            <div className="flex flex-shrink-0 text-auto gap-2 items-start justify-end">
                <ButtonIcon icon="add" size="small" style="light" isPill />
            </div>}
        
        {mini ? <input 
            type="text" 
            className={`w-full flex-grow focus:outline-none ${textSize}`}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            disabled={state === 'loading' || state === 'disabled'}
            placeholder="Write a message" />
            :
        <textarea 
            className={`w-full flex-grow focus:outline-none resize-none ${textSize}`}
            value={content}
            style={{minHeight: '1.5em'}}
            disabled={state === 'loading' || state === 'disabled'}
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder="Write a message" />}
        
        <div className="flex flex-row flex-nowrap flex-shrink-0 text-auto gap-2 items-start justify-between">
            {acceptFiles && !mini &&
            <div className="flex flex-shrink-0 text-auto gap-2 items-start justify-end">
                <ButtonIcon icon="add" size="small" style="link" isPill />
            </div>}
            
            <div className="flex flex-row flex-nowrap w-full text-auto gap-2 items-start justify-end">
                {acceptAudio && <RecorderButton size="small" state="default" style="ghost" timer={5000} showTimer />}
                <ButtonIcon 
                    icon="arrow-up" 
                    size="small" 
                    style="filled" isPill 
                    color={buttonColor}
                    state={ state === 'loading' ? 'loading' :
                        content.length > 0 ? 'default' : 'disabled'}
                    onClick={(e) => handleSubmit(e)}

                />
            </div>
        </div>
        </div>
    )
}



/* ignore rest */

ChatInput.definitions = {
    apiName: 'ChatInput',
    displayName: 'Chat Input',
    description: 'Input component designed for chat messages. Supports text, voice recording, and file attachments',
    ai_instructions: 'This component is used for chat messages. It supports text, voice recording, and file attachments. It is designed to be used in chat interfaces.',
    type: 'chat',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'stable',
    package: 'Starter',
    propDefinitions: {
        buttonColor: {
            type: 'colors',
            displayName: 'Button Color',
            options: ['primary', 'accent'],
            default: true,
        },
        mini: {
            type: 'bool',
            displayName: 'Mini',
            default: true,
        },
        size: {
            type: 'enum',
            displayName: 'Size',
            options: ['small', 'medium'],
            default: 'medium',
        },
        acceptFiles: {
            type: 'bool',
            displayName: 'Accept Files',
            default: true,
        },
        acceptAudio: {
            type: 'bool',
            displayName: 'Accept Audio',
            default: true,
        },
        isPill: {
            type: 'bool',
            displayName: 'Pill Style',
            default: false,
        },
        
    }
}