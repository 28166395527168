import { useState, useEffect, useRef } from 'react';
import { ButtonIcon } from '../';

type Props = {
  color?: 'primary' | 'accent' | 'warning' | 'info' | 'success' | 'error' | 'current';
  style?: 'filled' | 'ghost' | 'light';
  state?: 'default' | 'disabled' | 'active';
  size?: 'small' | 'medium' | 'large';
  onStartRecording?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onEndRecording?: (blob: Blob) => void;
  timer?: number;
  showTimer?: boolean;
  __juno?: any;
};

export default function RecorderButton({
  color = 'current',
  style = 'filled',
  state = 'default',
  size = 'medium',
  onStartRecording = () => {},
  onEndRecording = () => {},
  timer = 5000, // 5 seconds
  showTimer = true,
  __juno = {},
}: Props) {
  
  const progressColor = style === 'filled' ? 'var(--base-0)' : color === 'current' ? 'currentColor' : `var(--${color})`;
  const strokeWidth = size === 'small' ? 8 : size === 'large' ? 10 : 10;

  const [progress, setProgress] = useState(0);
  const [isRecording, setIsRecording] = useState(false);

  {/* remove line */ /*

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const startTimeRef = useRef<number | null>(null);

  const startRecording = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isRecording) return;
    setIsRecording(true);
    setProgress(0);
    onStartRecording(e);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          autoGainControl: true,
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 48000,
        },
      });

      mediaStreamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
        onEndRecording(audioBlob);
      };

      mediaRecorderRef.current.start();
      startTimeRef.current = Date.now();

      if (timer) {
        // Use actual elapsed time instead of incrementing by fraction
        timerRef.current = setInterval(() => {
          if (!startTimeRef.current) return;
          const elapsed = Date.now() - startTimeRef.current;
          const newProgress = (elapsed / timer) * 100;
          // console.log('New progress:', newProgress);
          setProgress(newProgress);

          if (newProgress >= 100) {
            // console.log('Recording finished');
            finishRecording(); // no event needed
          }
        }, 100);
      }
    } catch (err) {
      console.error('Error starting recording:', err);
      setIsRecording(false);
    }
  };

  const finishRecording = (e?: React.MouseEvent<HTMLButtonElement>) => {
    setIsRecording(false);

    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    setProgress(0);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);
  /* remove line */ }
  
  const sizeStyles = size == 'small' ? `h-7 w-7` : size == 'large' ? `w-12 h-12` : `h-9 w-9`;

  return (
    <div
      className={`relative flex items-center ${sizeStyles} justify-center ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
      {...__juno?.attributes}
    >
      {isRecording && (
        showTimer && timer ? (
          <ProgressCircle
            progress={progress}
            size={size}
            strokeWidth={strokeWidth}
            color={progressColor}
          />
        ) : (
          <Indicator size={size} />
        )
      )}

      <ButtonIcon
        icon={isRecording ? 'pause' : 'microphone'}
        color={color}
        style={style}
        state={state}
        size={size}
        /* replace to 'onClick={isRecording ? finishRecording : startRecording}' */
        
        isPill
      />
    </div>
  );
}

function ProgressCircle({progress, size, color, strokeWidth}) {
  const circlePadding = size === 'small' ? '[1px]' : size === 'large' ? '[1.5px]' : '[1px]';

  return (
    <svg className={`absolute 
      pointer-events-none
      top-${circlePadding} bottom-${circlePadding} left-${circlePadding} right-${circlePadding} z-10`}
      viewBox="0 0 100 100"
    >
      <circle cx="50" cy="50" r="45" stroke={color} strokeWidth={strokeWidth}
        className={`${progress? 'opacity-20' : 'opacity-0'} transition-al duration-150`}
        fill="none" />
        <circle cx="50" cy="50" r="45" stroke={color} strokeWidth={strokeWidth}
          className={`${progress > 0 ? 'opacity-70' : 'opacity-0 transition-all duration-150'}`}
          fill="none" transform="rotate(-90 50 50)" strokeLinecap="round"
          strokeDasharray={`${2 * Math.PI * 45}`}
          strokeDashoffset={`${2 * Math.PI * 45 - (progress / 100) * 2 * Math.PI * 45}`}
          style={{ transition: 'stroke-dashoffset 0.1s ease, opacity 0.1s ease'}}
        />
    </svg>
  )
}

function Indicator({size}) {
  const indicatorSize = size === 'small' ? 'w-2 h-2' : size === 'large' ? 'w-3 h-3' : 'w-2.5 h-2.5';
  return (
<div className={`absolute top-0 right-0
    flex items-start justify-end
    rounded-full ${indicatorSize}
     bg-warning animate-heartbeat 
    z-10 pointer-events-none`}
    
    />
  )
}
    
  /* ignore rest */

  RecorderButton.definitions = {
    apiName: 'RecorderButton',
    displayName: 'Recorder Button',
    description: 'button used in voice recording',
    ai_instructions: '',
    type: 'buttons',
    acceptedChildren: 'none',
    relativeSize: 'small',
    status: 'stable',
    package: 'Starter',
    propDefinitions: {
        
        color: {
            type: 'colors',
            options: ['primary', 'accent', 'warning', 'info', 'success', 'error'],
            displayName: 'Color',
            default: 'current'
        },
        style: {
            type: 'oneOf',
            options: ['filled', 'ghost', 'light'],
            displayName: 'Style',
            default: 'filled'
        },
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'active'],
            displayName: 'State',
            default: 'default'
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            default: 'medium'
        },
        timer: {
            type: 'number',
            displayName: 'Timer',
            default: 5000
        },
        showTimer: {
            type: 'boolean',
            displayName: 'Show Timer',
            default: true
        }
    }
  }
    