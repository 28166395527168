import { useEffect, useRef, useState } from 'react';
import { ButtonIcon, Divider } from '../../../../ui-kit/exports/react';
import Colors from "./Colors";
import Typography from "./Typography";
import { ThemeConfig } from '../../../../ui-kit/local/components/config';
import Surfaces from './Surfaces';


export type Theme = {
  id: string,
  display_name: string,
  is_default?: boolean,
  variables?: {[key: string]: string}
  config?: ThemeConfig
}

export default function EditTheme({theme, onCancel, onSave, onBack}) {
    // console.log(theme)
    
    return (
        <>
        <div className='flex flex-row justify-between items-end w-full gap-2 p-3 text-md font-medium border-b border-base-100'>
            <Rename currentName={theme.display_name} onBlur={(newName) => onSave({...theme, display_name: newName})} />
        </div>
        <div className="flex flex-col w-full">
        <Colors theme={theme as Theme} onSave={onSave} />
        <Divider />
        <Typography theme={theme as Theme} onSave={onSave} />
        <Divider />
        <Surfaces theme={theme as Theme} onSave={onSave} />
        </div>
        </>
    )
}

function Rename({currentName, onBlur}) {

    const [name, setName] = useState(currentName);
  
    useEffect(() => {
      setName(currentName);
    }, [currentName]);
  
    function handleBlur(e) {
      e.stopPropagation();
      onBlur(name);
    }
    const inputRef = useRef(null);

    return (
        <div className="flex flex-row w-full justify-between items-center gap-2">
        <input 
          type="text"
          value={name}
          ref={inputRef}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => handleBlur(e)}
          // onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
          className={`bg-transparent focus:bg-base-100 hover:bg-base-100 placeholder-gray-700 text-gray-800 focus:text-gray-900
          px-1 -ml-1 overflow-hidden whitespace-nowrap ellipsis truncate transition-all cursor-text w-full
          cursor-default select-none focus:cursor-text placeholder-base-300
          `}
          placeholder='Theme Name'
          style={{  
            minWidth: 200,
            textAlign: 'left'
          }}
        />
        {/*<ButtonIcon icon="edit" 
        onClick={() => inputRef.current.focus()}
        size='small' style='ghost' />*/}
        </div>
  
      )
    }
