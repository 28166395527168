import { useEffect, useState } from 'react';
// import * as SampleLogos from '../../assets/brand/index';
import { config } from '../config';

type LogoProps = {
    type?: 'logo' | 'symbol',
    size?: '12px' | '16px' | '20px' | '24px' | '28px' | '36px' | '40px' | '48px' | '60px' | '96px',
    isInverted?: boolean,
    customWidth?: number,
    customHeight?: number,
    onClick?: () => void,
    selfAlign?: 'auto' | 'start' | 'center' | 'end',
    assets?: any,
    __juno?: any
}

/* TODO needs auto swap for inverted logo when dark mode is enabled */

export default function Logo({
        type = 'symbol',
        size = '28px',
        customWidth,
        customHeight,
        selfAlign,
        isInverted,
        onClick,
        __juno = {},
      }: LogoProps) {
    
    const localConfig = { ...config, ...(__juno?.designConfig || {}) };

    const [isDarkMode, setIsDarkMode] = useState(false);

    /*/ Effect to detect theme changes
    useEffect(() => {
        const themeClass = document.documentElement.classList.contains('dark');
        setIsDarkMode(themeClass);
    }, []);*/

    const logo = localConfig?.brand?.logo || config?.brand?.logo
    const logo_inverted = localConfig?.brand?.logo_inverted || config?.brand?.logo_inverted
    const symbol = localConfig?.brand?.symbol || config?.brand?.symbol
    const symbol_inverted = localConfig?.brand?.symbol_inverted || config?.brand?.symbol_inverted
    
    const useNormal = !isInverted ? !isDarkMode : isInverted
    const imageOptions = [
        {option: 'symbol', url: isInverted ? symbol_inverted : symbol},
        {option: 'logo', url: isInverted ? logo_inverted : logo}
    ]
    
    const imageURL = imageOptions.find(option => option.option === type)?.url || null;
    
    const classes = `flex-shrink-0 ${selfAlign ? 'self-'+selfAlign : ''}`
    
    const wrapperInlineStyles = {
        width: customWidth ? customWidth : type == 'symbol' && size,
        height: customHeight || size,
        objectFit: 'contain' as React.CSSProperties['objectFit'], 
    }

    return (
        <div
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
            style={wrapperInlineStyles}
            onClick={onClick}
            > 
            
            <img 
                src={imageURL}
                style={wrapperInlineStyles} 
                draggable={false} 
            />
        </div>   
    )
}

