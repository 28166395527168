import {useContext, useRef } from "react";
import ComponentPreviewObjects from "../../../../../components/ComponentPreviewObjects";
import { createNewIds } from "../../../../../utilities/helpers";
import { convertThemeVariablesToStyles } from "../../../canvas/helpers";

export default function Template({ 
    template, 
    frameId, 
    effectiveVariables, 
    view, 
    dndState,
    handleDragStart, handleDragEnd
  
  }) {
  
  const { width, height, thumbnail } = template;
  
  // Prepare objects
  const templateObjects = [...template?.objects] || [] // copy from template
  const rootObject = templateObjects.find(obj => obj.id === template?.root_object_id); // find rootObject
  let objects = rootObject ? createNewIds(templateObjects, rootObject?.parent, 'rootObject') : []// create new ids
  // update frame id for all objects and detach rootObject (set parenе = 'rootObject')
  objects = objects.map((obj) => obj.id === rootObject?.id ? { ...obj, parent: 'rootObject', frame: frameId } : { ...obj, frame: frameId });
  
  
  const myRef = useRef(null);
  const tileSize = 240
  const scaleFactor = Math.min(1, tileSize / Math.max(width, height))

  // for drag & drop
  const self = {
    source: 'library', 
    componentAPIName: rootObject?.componentAPIName,
    objects
  }
  
  const tranformWidth = scaleFactor * width
  const tranformHeight = scaleFactor * height
  
  
  const canDrag = !view?.disableDnD && view?.mode == 'editor'
  const themeStyles = convertThemeVariablesToStyles(effectiveVariables);
  
  return (
    <div
      className="flex flex-col relative font-normal select-none items-center justify-center cursor-grab bg-base-0"
      ref={myRef}
      style={{
        ...themeStyles,
        color: 'var(--base-content)',
        fontFamily: `var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        overflow: 'hidden', 
        width: tileSize, 
        height: 'auto',
        maxHeight: tileSize,
      }}
    >
      <div className={`overflow-hidden my-px`} // ring-1 ring-gray-200
      style={{ width: tranformWidth, height: tranformHeight}} >
        <div 
          className="flex flex-col items-center justify-center text-left"
          style={{ width: width+'px', height: height+'px', transform: `scale(${scaleFactor})`, transformOrigin: 'top left'}}
          draggable={canDrag}
          onDragStart={(event) => {canDrag && event.stopPropagation(); handleDragStart(event, self, myRef)}}
          onDragEnd={(event) => {canDrag && event.stopPropagation(); handleDragEnd(event, self, myRef)}}
        >
        
          {rootObject && <ComponentPreviewObjects
            width={width} 
            height={height} 
            objects={objects}
            thumbnail={thumbnail}
            themeVariables={effectiveVariables}
            />}
          
        </div>
      </div>
    </div>
  );
}