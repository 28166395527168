import { spacingMap } from '../helpers';

type EmailProps = {
    width?: 'stretch' | '560' | '640' | '780' | '960',
    pageBackground?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'base-300' | 'primary' | 'accent',
    emailBackground?: 'none' | 'base-50' | 'base-0' | 'base-100' | 'base-200' | 'base-300' | 'primary' | 'accent',
    hasOutline?: boolean,
    corners?: 'none' | 'sm' | 'md' | 'lg',
    
    marginTop?: "24px" | "32px" | "48px" | "64px",
    paddingX?: "4px" | "6px" | "8px" | "10px" | "12px" | "16px" | "24px" | "32px" | "48px"
    paddingY?: "4px" | "6px" | "8px" | "10px" | "12px" | "16px" | "24px"    | "32px" | "48px" 
    gap?: "4px" | "6px" | "8px" | "10px" | "12px" | "16px" | "24px",
    alignItems?: 'start' | 'center' | 'end' | 'stretch',
    children: React.ReactNode,
    __juno?: any
}

export default function Email({ 
        pageBackground='base-100', 
        emailBackground='base-0',
        corners='sm',
        paddingX=null,
        paddingY=null,
        alignItems='start', 
        gap=null, 
        marginTop=null, 
        hasOutline=false,
        width='560',
        children, 
        __juno = {}
    }: EmailProps) {
    
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;

    const fontColor = emailBackground.startsWith('base') ? 'text-base-content' : `text-${emailBackground}-content`
    const cornerStyles = corners != 'none' && `rounded-${corners}`
    const borderStyles = hasOutline ? 'ring-[0.5px] ring-current-10' : '';

    const outerClasses = `flex flex-col flex-grow w-full h-auto bg-${pageBackground} ${fontColor}`
    
    const innerClasses = `flex flex-col w-full h-auto mx-auto ${gapStyles} ${borderStyles} bg-${emailBackground} ${cornerStyles} ${paddingStyles} items-${alignItems}`

    return (
        <div 
        className={`${outerClasses} ${__juno?.outlineStyle}`}
        {...__juno?.attributes}
        style={{paddingTop: marginTop}}>
            <div 
                className={`${innerClasses} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
                style={{maxWidth: width != 'stretch' ? `${width}px` : '100%'}} 
                data-tag={__juno?.attributes?.['data-tag']}   
                >
                {children}
            </div>
        </div>
    );
}





/* ignore rest */

Email.definitions = {
    apiName: 'Email',
    displayName: 'Email Template',
    description: 'A flexible email template component designed for building responsive email layouts. It offers customizable options for page and email backgrounds, corner styles, and padding.',
    ai_instructions: 'email template with flex-col layout.',
    type: 'layout',
    relativeSize: 'large',
    package: null,
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['stretch', '560', '640', '780', '960',],
            displayName: 'Width',
            default: '560', 
            tile: '1/2'
        },
        
        corners: {
            type: 'corners',
            options: ['none', 'sm', 'md', 'lg'],
            displayName: 'Corners',
            default: 'sm', 
            tile: '1/2'
        },

        emailBackground: {
            type: 'colors',
            options: ['none', 'base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'accent'],
            displayName: 'Email Background',
            default: 'base-0'
        },

        pageBackground: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'accent'],
            displayName: 'Page Background',
            default: 'base-100'
        },
        paddingX: {
            type: "spacing",
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            default: null,
            tile: '1/2'
        },
        paddingY: {
            type: "spacing",
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            default: null,
            tile: '1/2'
        },
        marginTop: {
            type: 'spacing',
            displayName: 'Top Margin',
            options: ["24px", "32px", "48px", "64px"],
            default: null,
            tile: '1/2'
        },
        alignItems: {
            type: "alignItems",
            options: ["start", "center", "end", "stretch"],
            default: "start", 
            tile: '1/2'
        },
        gap: {
            type: "gap",
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", ],
            displayName: "Gap",
            default: null,
            tile: '1/2'
        },
        hasOutline: {
            type: "bool",
            displayName: "Add outline",
            ai_instructions: 'adds base-300 1px outline',
            default: false, 
            tile: '1/2'
        },
    }
};

/*Email.propTypes = {
    width: PropTypes.oneOf(['stretch', '560', '640', '780', '960']),
    pageBackground: PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'accent']),
    emailBackground: PropTypes.oneOf(['none', 'base-50', 'base-0', 'base-100', 'base-200', 'base-300', 'primary', 'accent']),
    hasOutline: PropTypes.bool,
    corners: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
    
    marginTop: PropTypes.oneOf(["24px", "32px", "48px", "64px"]),
    paddingX: PropTypes.oneOf([ "4px", "6px", "8px", "10px", "12px", "16px", "24px",]),
    paddingY: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px",]),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", ]),

    alignItems: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
    
    
    
};*/