import { cornersPresets } from './presets';
import { Tooltip } from './IconsPicker';

const recognizeSet = (setObject, cornersPresets) => {
    
    for (const [setName, preset] of Object.entries(cornersPresets)) {
        if (
            Object.keys(setObject).length === Object.keys(preset).length && // Check if lengths match
            Object.entries(setObject).every(
                ([key, value]) => preset[key] === value // Compare each key-value pair
            )
        ) {
            return setName; // Return the matching key
        }
    }
    return 'custom'; // No match found
};

export default function CornersPicker({
    theme, 
    onSave
}) {
    
    const setObject = Object.fromEntries(Object.entries(theme.variables).filter(([key]) => key.includes('border-radius-')));
    const currentSetName = recognizeSet(setObject, cornersPresets);
    
    function handleSelect(setName) {
        const newCornerVars = cornersPresets[setName];
        onSave({
            ...theme,
            variables: {
                ...theme.variables,
                ...newCornerVars
            }
        });
    }

    return (
        
        <div className='w-full flex flex-row justify-between gap-2 '>
            {Object.keys(cornersPresets).map((setName) => (
            <div className='flex flex-col flex-grow relative group' key={setName}>
            <div className={`flex flex-col flex-grow items-start relative overflow-hidden border group relative
            hover:scale-105 transition-all rounded-md h-7 bg-base-0 duration-75 cursor-pointer 
            
            ${currentSetName === setName ? 'border-primary text-primary' : 'hover:text-primary border-current-20 hover:border-primary'}`}
            
            onClick={()=>handleSelect(setName)}
            style={{borderRadius: cornersPresets[setName]['border-radius-md']}}
            >
            <div className={`absolute w-full h-full transition-all duration-75 
             w-full left-1/3 top-1/2 border bg-current-20 border-current-50 `}
                style={{borderRadius: cornersPresets[setName]['border-radius-md']}}
                >
            
            </div>
            
            </div>
            <Tooltip name={setName} />
            </div>
        ))}
        </div>
    );
}