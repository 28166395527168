// BASE IMPORTS
import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getChildren, getDescendants } from "../../utilities/helpers";
import LightRouter from "../../components/LightRouter";
import { convertThemeVariablesToStyles } from "../editor/canvas/helpers";

// ADD DROPDOWN FOR SELECTING FOLDER / PAGE / FRAME FROM SUBLINGS
// ADD MOBILE VIEW

export default function TakeScreenshot() {
  const { frameId } = useParams();  // Retrieve frameId from URL
  const [searchParams] = useSearchParams();
  const canvasRef = useRef(null);
  const [ isMobile, setIsMobile ] = useState(false)
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      if (width < 600) {
        setIsMobile(true)
      } else {setIsMobile(false)}
      
    });
  
    const currentRef = canvasRef.current;
  
    if (currentRef) {
      resizeObserver.observe(currentRef);
    }
  
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      resizeObserver.disconnect();
    };
  }, [canvasRef]); 

  const [project, setProject] = useState(null);
  const [designSystem, setDesignSystem] = useState(null);
  const [frameWithObjects, setFrameWithObjects] = useState(null);
  const [assets, setAssets] = useState(null);
  
  const objectId = searchParams.get("objectId");
  const width = searchParams.get("width");
  const height = searchParams.get("height");
  const background = searchParams.get("background") || 'white';

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch frame data
        const frameResponse = await axios.get(`/api/share/frame/${frameId}`);
        const frameData = frameResponse.data;
  
        setProject(frameData.project);
        setDesignSystem(frameData.designSystem);
        setFrameWithObjects(frameData.frameWithObjects);
        setAssets(frameData.assets);  // Set the assets state
  
        // If designSystem is linked, fetch its data
        if (frameData.project && frameData.project.ds) {
          const response = await axios.post(`/design-system/${frameData.project.ds}`, {
            userId: null, 
            projectId: frameData.project.id || null
          })

          setDesignSystem(response.data);
        }
      } catch (error) {
        console.error("Error fetching frame data:", error);
      }
    };
  
    fetchData();
  }, [frameId]);
  
  const children = getChildren(frameWithObjects?.id, frameWithObjects?.objects) || [];
  
  const currentThemeId = project?.current_theme_id || designSystem?.default_theme_id;
  const defaultTheme = designSystem?.themes?.find(t => t.id == designSystem.designSystem.default_theme_id)
  const currentTheme = designSystem?.themes?.find(t => t.id == project.project?.current_theme_id)
  const effectiveVariables = {} 
  
  const themeStyles = convertThemeVariablesToStyles(effectiveVariables);
  
  /*if (objectId) { 
    const rootObj = frameWithObjects?.objects?.find(obj => obj.id == objectId)
    const backgroundStyle = background == 'transparent' ? '' : `bg-${background}`
    const classes = `flex flex-col w-screen h-screen items-start ${backgroundStyle}`
    
    if (rootObj) return (
      <div className={classes} ref={canvasRef}>
    <div 
    className="flex flex-col flex-shrink-0"
    style={{ 
      ...themeStyles,
      color: 'var(--base-content)',
      width: width+'px', 
      height: height+'px' 
    }}>
      <Router
        key={rootObj.id}
        self={rootObj}
        environment={environment}
      />
    </div></div>
  )}*/
  
  return (
    <div className="flex w-screen h-screen"
              style={{
                ...themeStyles,
                color: 'var(--base-content)',
                fontFamily: `var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
            }}>
            {children.length != 0 &&
                children
                    .sort((a, b) => a.index - b.index)
                    .map((object) => (
                        
                        <LightRouter
                            key={object.id} 
                            self={object} 
                            assets={assets} 
                            descendants={getDescendants(object.id, frameWithObjects.objects)}
                        />
                            
                    ))}
      </div>
  );
}
