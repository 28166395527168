import { useState } from 'react';
import { Button } from '../';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus, coy } from 'react-syntax-highlighter/dist/esm/styles/prism';

type Props = {
    text?: string;
    language?: 'javascript' | 'python' | 'html' | 'jsx' | 'css' | (string & {});
    hasOutline?: boolean,
    // style?: 'light' | 'dark';
    copyButton?: boolean;
    fontSize?: 'xs' | 'sm' | 'md'
    title?: string;
    maxHeight?: number | string;
    __juno?: any
  };

export default function CodeSnippet({
        title='',    
        text = '',    
        language = 'jsx',
        // style = 'light',
        fontSize = 'sm',
        copyButton = true,
        hasOutline = true,
        maxHeight = 480,
        __juno = {},
    }: Props) {

    const widthStyle = `w-full`
    // const sizeStyles =  size == 'small' ? `py-1 px-1.5 gap-1.5 text-xs` : `py-1.5 px-2 gap-3 text-sm`;
    const cornerStyles = "rounded-md"
    const headerCorners = "rounded-t-md"
    // const bgStyles = style == 'dark' ? `bg-base-content text-base-0` : `bg-current-2 text-base-content`
    const bgStyles = `bg-current-2 text-base-content`
    const outlineStyles = hasOutline ? `ring-[0.5px] ring-inset ring-current-20` : ''
    const syntaxTheme = coy //style === 'dark' ? vscDarkPlus : 
    const fontSizeStyles = `text-${fontSize}`

    const classes = `flex flex-col ${fontSizeStyles} ${widthStyle}  ${cornerStyles} ${bgStyles} relative group ${outlineStyles}`

    const [copied, setCopied] = useState(false)

    const copyToClipboard = () => {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); 
      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
    }

    return (
        <div
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
        >
            <div className={`flex flex-row items-center justify-between w-full px-3 py-1 ${headerCorners}
            ${hasOutline ? 'border-b border-current-10' : ''}`}>    
            <span className="font-normal text-xs">{title}</span>
            {copyButton && (
                <Button
                    onClick={copyToClipboard}
                    size='small'
                    style='ghost'
                    className='-mr-2'
                    leftIcon={copied ? 'check' : 'copy'}
                    text={'copy'}
                /> 
                )}
            </div>
            <div className="overflow-auto px-3 pt-3" style={{maxHeight: maxHeight}}>
                <SyntaxHighlighter
                language={language}
                style={syntaxTheme}
                customStyle={{ backgroundColor: 'transparent', margin: 0, }}
                codeTagProps={{
                    style: {
                    backgroundColor: 'transparent',
                    fontFamily: 'IBM Plex Mono, monospace',
                    },
                }}
                >
                {text}
                </SyntaxHighlighter>
            </div>
        </div>
    );
}


/* ignore rest */

CodeSnippet.definitions = {
    apiName: 'CodeSnippet',
    displayName: 'Code Snippet',
    description: 'A code snippet component that displays coding samples in various languages. Offers customizable themes and font sizes, with features like code copying. Ideal for showcasing code examples in different programming languages.',
    ai_instructions: 'code snippet component for displaying large code samples. Includes sample snippets, does not accept code (yet).',
    type: 'code',
    acceptedChildren: 'none',
    relativeSize: 'medium to large',
    status: 'prototype',
    package: 'Pro',
    propDefinitions: {
        title: {
            type: 'string',
            displayName: 'Title',
            default: '',
            tile: '1/2'
        },
        size: {
            type: 'size',
            options: ['small', 'medium'],
            displayName: 'Size',
            default: 'medium', 
            tile: '1/2'
        },
        text: {
            type: 'longString',
            default: '', 
            displayName: 'Code',
        }, 
        hasOutline: {
            type: 'bool',
            displayName: 'Outline',
            default: true
        },
        language: {
            type: 'oneOf',
            options: ['javascript', 'python', 'html', 'jsx'],
            displayName: 'Language',
            default: 'jsx',
            tile: '1/2'
        },
        /*style: {
            type: 'oneOf',
            options: ['light', 'dark'],
            displayName: 'Theme',
            default: 'light'
        },*/
        copyButton: {
            type: 'bool',
            displayName: 'Copy Button',
            default: true
        }
        
        
    }
};
