import React, { memo } from "react";
import ComponentPreviewObjects from "../../../../../components/ComponentPreviewObjects";
import { createNewIds } from "../../../../../utilities/helpers";
import useWhyDidYouUpdate from "../helpers";
import { isEqual } from "lodash";

function compareState(prevProps, nextProps) {
  
  if (!isEqual(prevProps.categories, nextProps.categories)) {
    console.log('categories changed')
    return false; 
  }

  if (!isEqual(prevProps.effectiveVariables, nextProps.effectiveVariables)) {
    console.log('theme changed')
    return false; 
  }

  return true;
}

const Categories = React.memo(({onSelect, categories, effectiveVariables}) => {

  return (
      <>
      <div className='flex flex-row gap-2 items-center justify-start text-sm py-1 font-medium'>
          <h1>Choose category</h1>
      </div>
      <div className='grid grid-cols-2 gap-2 overflow-scroll'>
        {categories.map(cat => (
              <div key={cat.name} className='flex flex-col w-full gap-1'>
                <div className={`flex flex-col gap-2 w-full h-32 bg-base-50 hover:bg-base-100 text-center rounded-md p-2 text-xs items-center justify-center
              border border-base-200 hover:border-base-300 transition-all duration-75 text-base-content
              hover:shadow-inner-lg hover:shadow-base-300 group cursor-default relative 
              `}
                onClick={() => onSelect(cat)}
                >
                  {cat.items?.length > 0 && (
                    <CatThumbnail 
                      template={
                        cat.items.find(item => item.is_thumbnail) || cat.items[0]
                      } 
                      effectiveVariables={effectiveVariables} 
                    />
                  )}
                  <div className="opacity-0 group-hover:opacity-100 transition-all duration-75
              rounded-md bg-base-0 py-1 px-3 ring-1 ring-base-200 shadow-md z-50 text-base-content font-medium
              ">
                view {cat.items?.length || 0} items
              </div>
              <h4 className='capitalize text-base-900 flex-grow truncate absolute bottom-1 opacity-70 group-hover:opacity-100 transition-all duration-75'>
                  {cat.name}
                </h4>
                </div>
              </div>
            ))}
          
        </div>
        </>
    )
}, compareState);

export default Categories;

  function CatThumbnail({ 
    template, 
    effectiveVariables, 
  }) {
  
  const { width, height } = template;
  const frameId = 'frame'
  
  // Prepare objects
  const templateObjects = [...template?.objects] || [] // copy from template
  const rootObject = templateObjects.find(obj => obj.id === template?.root_object_id); // find rootObject
  let objects = rootObject ? createNewIds(templateObjects, rootObject?.parent, 'rootObject') : []// create new ids
  // update frame id for all objects and detach rootObject (set parenе = 'rootObject')
  objects = objects.map((obj) => obj.id === rootObject?.id ? { ...obj, parent: 'rootObject', frame: frameId } : { ...obj, frame: frameId });
  
  
  const tileSize = 92
  const scaleFactor = Math.min(1, tileSize / Math.max(width, height))
  
  const tranformWidth = scaleFactor * width
  const tranformHeight = scaleFactor * height
  
  return (
    <div
      className="flex flex-col absolute font-normal select-none items-center justify-center group-hover:grayscale transition-all duration-75
      left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-75"
      style={{
        overflow: 'hidden', 
        top: `calc(50% - 8px)`,
        width: tileSize, 
        height: 'auto',
        maxHeight: tileSize,
      }}
    >
      <div className={`overflow-visible`}
      style={{ width: tranformWidth, height: tranformHeight}} >
        <div 
          className="flex flex-col items-center justify-center text-left"
          style={{ width: width+'px', height: height+'px', transform: `scale(${scaleFactor})`, transformOrigin: 'top left'}}
        >
        
          {rootObject && <ComponentPreviewObjects
            width={width} 
            height={height} 
            objects={objects}
  
            themeVariables={effectiveVariables}
            />}
          
        </div>
      </div>
    </div>
  );
  }
