
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart, LabelList } from 'recharts';
import { Icon } from '../';

const dummyData = {
    keys: ['name', 'temperature', 'humidity', 'pressure'],
    colors: ["primary", "accent", "base-500"],
    values: [
        ["Aug 12", 45, 30, 52],
        ["Aug 19", 34, 40, 48],
        ["Aug 26", 52, 35, 15],
        ["Sep 2", 51, 50, 80],
        ["Sep 9", 52, 45, 73],
        ["Sep 16", 60, 55, 75],
        ["Sep 23", 80, 60, 80],
    ]
}

type DataPoint = {
    [key: string]: string | number;
}

function transformData(keys: string[], values: (string | number)[][]): DataPoint[] {
    return values?.map((values) =>
      values?.reduce<DataPoint>((obj, val, index) => {      
        obj[keys[index]] = val;
        return obj;
      }, {}),
    );
  }


type BarChartProps = {
    type?: 'grouped' | 'stacked',
    width?: 'auto' | 'full' | '1/2',
    height?: '92px' | '120px' | '160px' | '200px' | '240px' | '360px' | '100%',
    barColor?: 'primary' | 'accent' | 'base-content' | 'base-0', 
    barWidth?: 24 | 32 | 36 | 48 | number,
    barCorners?: 'sm' | 'md' | 'lg',
    emptyState?: boolean,
    emptyMessage?: string,
    showGrid?: boolean,
    showLabels?: boolean,
    showYAxis?: boolean,
    showXAxis?: boolean,
    bottomDomain?: number | 'auto',
    topDomain?: number | 'auto',
    data?: {
        keys: string[],
        values: any[],
        colors?: string[],
    },
    __juno?: any
}

export default function BarChartComponent({
        width = 'full',
        type = 'stacked',
        height = '100%',
        barColor = 'primary',
        barWidth = 32,
        barCorners = 'sm',
        showLabels,
        showYAxis,
        showXAxis = true,
        showGrid = true,
        data = dummyData,
        emptyState = false,
        bottomDomain = 'auto',
        topDomain = 'auto',
        
        emptyMessage = 'Data may take up to 24 hrs to show',
        __juno = {}
      }: BarChartProps) {

    const widthStyles = `w-${width}`;
    const heightStyles = height === '100%' ? 'h-full' : `h-auto`;
    const classes = `flex flex-col items-stretch ${heightStyles} relative ${widthStyles}`
    
    const emptyStyles = `flex flex-col justify-center items-center px-sm text-sm font-medium gap-2 rounded-md `
    const sampleData = data ? transformData(data?.keys, data?.values) : []
    const sideMargins = (!showYAxis && !showLabels && !showXAxis) ? 0 : 20
    const roundCorners = type != 'stacked' && barCorners ? `inset(0 0 0 0 round var(--border-radius-${barCorners}))` : ""

    return (
        <div
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
        >
            {/* CHART */}
            {(!emptyState && data?.keys?.length && data?.values?.length) ?
            <div className='flex flex-row' style={{ 
                    width: '100%', 
                    height: height,  }}>
                <ResponsiveContainer width={'100%'} height="100%" >
                <BarChart 
                data={sampleData} 
                barSize={barWidth}
                margin={{ top: 20, right: sideMargins, bottom: 0, left: sideMargins }}>
                    {showGrid && <CartesianGrid strokeDasharray="1 3" />}
                    {showXAxis && <XAxis dataKey={data?.keys[0]} tick={{ fontSize: '12px', color: `red` }}/>}
                    <YAxis width={20} tick={{ fontSize: '12px', color: `red` }} domain={[bottomDomain, topDomain]} hide={!showYAxis} />
                    <Tooltip />
                    
                    {/* Dynamically generate <Bar> components for each key in keyPairs, using the same stackId */}
                    {data.keys.slice(1).map((key, index) => (
                        <Bar
                        key={key}
                        dataKey={key}
                        stackId={type === 'stacked' ? 'a' : undefined}
                        fill={data.colors?.[index] ? `var(--${data.colors[index]}` : `var(--${barColor})`}
                        style={{
                            clipPath: roundCorners
                        }}
                        >
                        {showLabels && 
                        <LabelList dataKey={key} position="top" style={{ fontSize: 10 }} />}
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>
            </div>
        :
        <>
        <div className={emptyStyles} style={{ height: height, backgroundColor: `color-mix(in srgb, currentColor 8%, transparent)`}}>
            <Icon icon='chart-up' />
            <span className='font-normal text-sm'>
                {emptyMessage}
            </span>
        </div>
        </>
        }
        </div>
    );
}


