import { Button, Icon } from '../';

type PopoverProps = {
    text?: string,
    title?: string,
    backdrop?: 'dark' | 'blurred' | 'none',
    primaryAction?: string,
    secondaryAction?: string,   
    __juno?: any
}

{/* NEED TO ADD ONCLICK HANDLERS */}

export default function Popover({
        text = 'Longer message containing important information..',
        title = 'Popover Title',
        backdrop = 'dark',
        primaryAction = 'Confirm',
        secondaryAction,
        __juno = {}
    }: PopoverProps) {

    // OVERLAY STYLES
    const darkBackground = `color-mix(in srgb, var(--base-content) 24%, transparent)`
    const lightBackground = `color-mix(in srgb, var(--base-content) 12%, transparent)`
    const overlayClasses = `fixed top-0 flex flex-col w-full h-full`

    // Determine background styles
    const bgStyles = `bg-base-0 text-base-content`;
    const borderStyles = `md:border md:border-base-300`;
    const sizeStyles = `w-full md:max-w-[400px] h-full md:h-auto min-h-[200px] md:max-h-[2/3] md:rounded-md`

    const classes  = `flex flex-col relative items-stretch md:mt-32 justify-start shadow-md mx-auto ${bgStyles} ${sizeStyles} ${borderStyles}`

    const titleClasses = `flex flex-row items-start text-lg md:text-base  font-medium w-full justify-between border-b border-base-200 px-3 py-2`
    const noTitle = !title || title === ''
    return (
        /* Overlay */
        <div  
        className={overlayClasses} 
        {...__juno?.attributes} /* do we put them here or on the drawer */
        style={{
            backgroundColor: backdrop == 'none' ? 'transparent' : backdrop == 'dark' ? darkBackground : lightBackground,
            zIndex: 50, 
            backdropFilter: backdrop == 'blurred' && 'blur(2px)',
            WebkitBackdropFilter: backdrop == 'blurred' && 'blur(2px)', /* For Safari compatibility */
        }}>
        <div className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`} data-tag={__juno?.attributes?.['data-tag']} >
        <Icon icon='close' className='absolute right-2 p-0.5 rounded bg-base-0 hover:bg-base-100 top-2 cursor-pointer hover:scale-110 transition-all opacity-70 hover:opacity-100'/>
        {!noTitle && 
        <div className={titleClasses}>
            <h2 className='font-semibold text-lg'>
                    {title}
            </h2>
        </div>}

        <div className={`flex flex-col flex-grow justify-between px-3 py-2 pb-3 text-lg md:text-base ${noTitle ? 'pr-8' : '' }`}>
            {text}
        </div>
        {/* Buttons */}
        
        {(primaryAction || secondaryAction) &&
        <div className={`flex flex-row md:items-center mt-2 flex-grow-0 flex-shrink-0 md:justify-end gap-3 md:gap-2 p-5 md:px-3 md:py-2`}>
            {secondaryAction && 
            <Button size={'small'} style={'ghost'} marginTop={'8px'} text={secondaryAction} 
                // junoProps={{onPropertyUpdate:  () => onPropertyUpdate('secondaryAction') }} /* remove line */
            />} 
            {primaryAction && 
            <Button text={primaryAction} size={'small'} marginTop={'8px'} style={'filled'}
                // junoProps={{onPropertyUpdate:  () => onPropertyUpdate('primaryAction') }} /* remove line */
            />}
        </div>}
        </div>
        </div>
    );
}



/* ignore rest */


Popover.stories = [
    {
        name: 'Default',
        wrapperClasses: 'flex flex-col w-full max-w-[640px] h-full max-h-[480px] bg-base-0',
        props: {
            text: 'Popover is a quick action item that pops up to present dynamic content.'
        }
    }
]

Popover.definitions = {
    apiName: 'Popover',
    displayName: 'Popover',
    description: 'A versatile overlay component for displaying contextual information, alerts, or forms. It offers editable text and title, primary and secondary buttons, and customizable backdrop settings for various UI contexts.',
    ai_instructions: 'popover is a quick action item that overlays app shell to present dynamic content. smaller than Modal but has similar anatomy',
    type: 'dialogs',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'in review',
    package: 'Starter',
    propDefinitions: {
        title: {
            type: 'string',
            default: 'Popover Title', 
            displayName: 'Title', 
            editable: true
        },
        text: {
            type: 'string',
            default: 'Longer message containing important information..',
            displayName: 'Text', 
            editable: true
        },
        
        backdrop: {
            type: 'oneOf',
            options: ['dark', 'blurred', 'none'],
            displayName: 'Backdrop',
            default: 'dark', 

        },
        
        primaryAction: {
            type: 'string',
            default: "Confirm", 
            displayName: 'Primary Action', 
            tile: '1/2'
            
        },
        secondaryAction: {
            type: 'string',
            default: "Cancel", 
            displayName: 'Secondary Action',
            tile: '1/2'
        }
    }
};