import React from 'react';
import { spacingMap } from '../helpers';
import { config } from '../config';

type FlexBoxProps = {
    direction?: 'flex-col' | 'flex-row',
    width?: 'full' | 'auto' | '1/2' | '1/3' | '1/4' | '2/3' | '3/4' | string,
    height?: 'full' | '1/2' | '1/3' | '1/4' | '2/3' | '3/4' | 'auto' | string,
    paddingX?: '0px' | '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    paddingY?: '0px' | '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    gap?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'primary' | 'accent' | 'base-900',
    bgOpacity?: '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100',
    hasOutline?: boolean,
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
    corners?: 'none' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl' | 'full',
    alignItems?: 'start' | 'center' | 'end' | 'stretch',
    justifyContent?: 'start' | 'center' | 'end' | 'between',
    textSize?: 'auto' | 'xs' | 'sm' | 'base' | 'md',
    maxWidth?: number,
    minWidth?: number,
    maxHeight?: number,
    minHeight?: number,
    overflow?: 'auto' | 'hidden' | 'visible' | 'scroll' | 'y-scroll' | 'x-scroll',
    selfAlign?: 'auto' | 'start' | 'center' | 'end',
    display?: boolean,
    bgImageSrc?: string,
    bgImageStyle?: 'darken' | string,
    fontColor?: 'base-0' | 'base-100' | 'base-200' | 'base-300' | 'base-500' | 'base-700' | 'primary' | 'accent' | 'base-900' | 'base-content' | 'auto' | 
        'success' | 'error' | 'warning' | 'info' | 'success-content' | 'error-content' | 'warning-content' | 'info-content',
    children: React.ReactNode,
    __juno?: any
}

export default function FlexBox({
        direction,
        width = "full",
        height,
        paddingX,
        paddingY,
        background,
        bgOpacity,
        hasOutline,
        flexWrap,
        corners,
        gap,
        alignItems,
        justifyContent,
        textSize,
        maxWidth,
        minWidth,
        maxHeight,
        minHeight,
        overflow,
        selfAlign,
        display = true,
        bgImageSrc,
        bgImageStyle,
        fontColor,
        children,
        __juno = {}
      }: FlexBoxProps) {

    const localConfig = { ...config, ...(__juno?.designConfig || {}) };
    const mobile_props = __juno?.mobile_props || {};

    let bgStyles = mobile_props?.background ? `bg-${mobile_props?.background}` : '';
    bgStyles = background ? bgStyles+` ${bgStyles == '' ? '' : 'md:'}bg-${background}` : bgStyles;

    let cornerStyles = mobile_props?.corners ? `rounded-${mobile_props?.corners}` : '';
    cornerStyles = corners ? cornerStyles+` ${cornerStyles == '' ? '' : 'md:'}rounded-${corners}` : cornerStyles;

    let widthStyles = mobile_props?.width ? `w-${mobile_props?.width}` : '';
    widthStyles = width ? widthStyles+` ${widthStyles == '' ? '' : 'md:'}w-${width}` : widthStyles;

    let heightStyles = mobile_props?.height ? `h-${mobile_props?.height}` : '';
    heightStyles = height ? heightStyles+` ${heightStyles == '' ? '' : 'md:'}h-${height}` : heightStyles
    
    let fontSize = mobile_props?.textSize ? `text-${mobile_props?.textSize}` : '';
    fontSize = textSize ? fontSize+` ${fontSize == '' ? '' : 'md:'}text-${textSize}` : fontSize;
    
    const outlineStyle = localConfig.outlines == 'strong' ? 'current-20' : 'current-10';
    let borderStyles = mobile_props?.hasOutline ? 'border border-'+outlineStyle : '';
    borderStyles = hasOutline ? borderStyles+` ${mobile_props?.hasOutline == false ? 'md:' : ''}border border-${outlineStyle}` : borderStyles;

    let gapStyles = mobile_props?.gap ? `gap-${spacingMap[mobile_props?.gap]}` : '';
    gapStyles = gap ? gapStyles+` ${gapStyles == '' ? '' : 'md:'}gap-${spacingMap[gap]}` : gapStyles;

    let paddingXStyles = mobile_props?.paddingX ? `px-${spacingMap[mobile_props?.paddingX]}` : '';
    paddingXStyles = paddingX ? paddingXStyles+` ${paddingXStyles == '' ? '' : 'md:'}px-${spacingMap[paddingX]}` : paddingXStyles;

    let paddingYStyles = mobile_props?.paddingY ? `py-${spacingMap[mobile_props?.paddingY]}` : '';
    paddingYStyles = paddingY ? paddingYStyles+` ${paddingYStyles == '' ? '' : 'md:'}py-${spacingMap[paddingY]}` : paddingYStyles;
    
    const paddingStyles = `${paddingXStyles} ${paddingYStyles}`;
    
    let wrapStyles = mobile_props?.flexWrap ? `flex-${mobile_props?.flexWrap}` : '';
    wrapStyles = flexWrap ? wrapStyles+` ${wrapStyles == '' ? '' : 'md:'}flex-${flexWrap}` : wrapStyles;
    
    let alignItemsStyles = mobile_props?.alignItems ? `items-${mobile_props?.alignItems}` : '';
    alignItemsStyles = alignItems ? alignItemsStyles+` ${alignItemsStyles == '' ? '' : 'md:'}items-${alignItems}` : alignItemsStyles;

    let justifyContentStyles = mobile_props?.justifyContent ? `justify-${mobile_props?.justifyContent}` : '';
    justifyContentStyles = justifyContent ? justifyContentStyles+` ${justifyContentStyles == '' ? '' : 'md:'}justify-${justifyContent}` : justifyContentStyles;
    
    const mobileFontStyles = mobile_props?.fontColor ? `text-${mobile_props?.fontColor}` : 
        mobile_props?.background ? (mobile_props?.background?.startsWith('base') && mobile_props?.background != 'base-900') ? `text-base-content` : `text-base-0` : '';
    let fontStyles = fontColor ? `text-${fontColor}` : background ? (background?.startsWith('base') && background != 'base-900') ? `text-base-content` : `text-base-0` : '';
    fontStyles = mobileFontStyles ? `${mobileFontStyles} md:${fontStyles}` : fontStyles;

    /*const inheritFontStyle = (!background) ? '' : (background?.startsWith('base') && background != 'base-900') ? `text-base-content` : `text-base-0`;    
    let fontStyles = mobile_props?.fontColor ? `text-${mobile_props?.fontColor}` : '';
    fontStyles = (fontColor == 'auto' || !fontColor) ? fontStyles+inheritFontStyle : `text-${fontColor}`;*/
    
    let overflowStyles = mobile_props?.overflow ? `overflow-${mobile_props?.overflow}` : '';
    overflowStyles = overflow ? overflowStyles+` ${overflowStyles == '' ? '' : 'md:'}overflow-${overflow}` : overflowStyles;

    const selfAlignStyles = selfAlign ? `self-${selfAlign}` : '';
    let directionStyles = mobile_props?.direction ? `${mobile_props?.direction}` : '';
    directionStyles = direction ? directionStyles+` ${directionStyles == '' ? '' : 'md:'}${direction}` : directionStyles;

    const classes = `flex 
        ${overflowStyles}
        ${directionStyles} ${wrapStyles} 
        ${widthStyles}
        ${selfAlignStyles} 
        ${fontStyles} 
        ${fontSize} 
        ${bgStyles} 
        ${borderStyles} 
        ${gapStyles} ${paddingStyles} 
        ${cornerStyles} 
        ${alignItemsStyles} ${justifyContentStyles} ${heightStyles}`

    const imageOverlay = bgImageStyle === 'darken' ? 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), ' : '';
    const inLineStyles = {
        background: bgImageSrc && `${imageOverlay} url(${bgImageSrc}) no-repeat center center / cover`, 
        backgroundColor: bgOpacity ? `color-mix(in srgb, var(--${background}) ${bgOpacity}%, transparent)` : null, 
        ...(maxWidth && { maxWidth }),
        ...(minWidth && { minWidth }),
        ...(maxHeight && { maxHeight }),
        ...(minHeight && { minHeight }),
    };


    const filteredInLineStyles = Object.fromEntries(
        Object.entries(inLineStyles).filter(([_, value]) => value != null)
    );

    if (display) return (
        <div 
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
            style={Object.keys(filteredInLineStyles).length > 0 ? filteredInLineStyles : undefined}
        >
            {children}
        </div>
    );
}




