import { useContext, useState, useEffect } from 'react';
import UserContext from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import { Button, InputText } from '../../ui-kit/exports/react';

export default function RegisterPage() {
  const { setUser, setIsAuthenticated } = useContext(UserContext);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [formErrors, setFormErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear error message when user starts typing
    setFormErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // Regex for password (minimum 8 characters, at least one number, one lowercase and one uppercase)
  
    if (!formData.firstname.trim()) errors.firstname = 'First Name is required';
    if (!formData.lastname.trim()) errors.lastname = 'Last Name is required';
  
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    }
  
    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (!passwordRegex.test(formData.password)) {
      errors.password = 'Password must be at least 8 characters long and include a number, a lowercase and an uppercase letter';
    }
  
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
  
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const { email, password, firstname, lastname } = formData;
      
      const auth = getAuth();
      
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password, firstname, lastname);
        const user = userCredential.user;
        
        await updateProfile(user, {
          displayName: `${firstname} ${lastname}`
        });

        // Get the Firebase token for the created user and put it in local storage
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
    
        // CREATE DATABASE USER
        const userResponse = await fetch('/register', { 
          method: 'POST', 
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, firebaseId: user.uid, firstname, firstname })
        });
    
        const userData = await userResponse.json();
        const userId = userData.id;
        
        setUser({ 
          id: userId, 
          firebaseId: user.uid, 
          email: user.email, 
          token: token, 
          firstname, 
          lastname 
        });
        setIsAuthenticated(true);
        navigate(`/`); // Redirect to the project page
        
      } catch (err) {
        
      }
    };  

    const sameProps = {
      size: 'large',
      bgColor: 'base-50',
      hasOutline: false,
      width: 'full',
      onChange: handleChange,
    }

 
    const inputProps = {
      firstname: {
        type: 'text',
        name: 'firstname',
        value: formData.firstname,
        placeholder: 'First Name',
        state: formErrors.firstname ? 'error' : 'default',
        helperText: formErrors.firstname, 
        ...sameProps  
      },
      lastname: {
        type: 'text',
        name: 'lastname',
        value: formData.lastname,
        placeholder: 'Last Name',
        state: formErrors.lastname ? 'error' : 'default',
        helperText: formErrors.lastname,
        ...sameProps
      },
      email: {
        type: 'email',
        name: 'email',
        value: formData.email,
        placeholder: 'Email',
        state: formErrors.email ? 'error' : 'default',
        helperText: formErrors.email,
        ...sameProps
      },
      password: {
        type: 'password',
        name: 'password',
        value: formData.password,
        placeholder: 'Password',
        state: formErrors.password ? 'error' : 'default',
        helperText: formErrors.password,
        ...sameProps
      },
      confirmPassword: {
        type: 'password',
        name: 'confirmPassword',
        value: formData.confirmPassword,
        placeholder: 'Confirm Password',
        errorMessage: formErrors.confirmPassword, 
        ...sameProps
      }
    };
    return (
      <div className='flex flex-col items-center justify-center h-screen bg-white'>
        <div className='max-w-[480px] w-full bg-white rounded-lg p-6 pb-10 flex flex-col gap-5'>
        <img src={'/brand/logo.svg'} alt="Logo" width={240} className='mx-auto mb-5'/>
          <form onSubmit={handleSubmit} className='flex flex-col gap-5'>
            <div className='flex flex-row gap-3 w-full justify-between'>
              <InputText  {...inputProps.firstname} />
              <InputText  {...inputProps.lastname} />
            </div>
              <InputText  {...inputProps.email} />
              <InputText  {...inputProps.password} />
              <InputText  {...inputProps.confirmPassword} />
            
            <Button
          size={'large'}
          color={'primary'}
          style={'filled'}
          onClick={(e) => handleSubmit(e)}
          text={'Create Account'}
          marginTop={'md'}
          />

          <Button 
          size={'large'}
          onClick={() => navigate('/login')}
          text={'Log In'}
          />
          </form>
        </div>
      </div>
    );
  }   








    /*/ CREATE PROJECT FOR USER
        const projectResponse = await fetch('/projects/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Assuming you're using token-based authentication for the /projects/add endpoint
          },
          body: JSON.stringify({
            name: 'Default Project',  // Default project name
            userId: userId,
            ds: '4cf707d6-317b-11ee-a595-ea12fce6e513'  // tailwind
          })
        });
  
        const projectData = await projectResponse.json();
        const projectId = projectData.id;
        
        const tasks = ['']
        navigate(`/project/${projectId}`, { state: { tasks: tasks } }); */