import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createNewIds } from '../../../../utilities/helpers';
import { v1 as uuidv1 } from 'uuid';
import { EditorContext } from "../../EditorContext";
import { useSortable } from '@dnd-kit/sortable';
import { CSS, isHTMLElement } from '@dnd-kit/utilities';
import PageName from "./PageName";
import FramePreview from "../../../../components/FramePreview";


export default function PageThumbnail({
  frame,
  isMobile,
  isSelected,
  columnWidth, 
  page, 
  cssVars,
  folder, 
  showNotes, 
  isDraggingPreview=false,
  isDraggedFrame = false
}) {

  // Calculate the aspect ratio based height and scale factor
  const originalWidth = 1440
  const originalHeight = 820
  const aspectRatio = originalWidth / originalHeight;
  
  // Calculate the height to maintain the aspect ratio
  const thumbnailHeight = useMemo(() => (columnWidth) / aspectRatio - (isMobile && 14), [columnWidth, aspectRatio]);
  
  const { selector, setSelector, streaming, view, setView, handleAction, dndState } = useContext(EditorContext);
  
  function handleFrameSelection(e, frame) {
    e.stopPropagation()
    // console.log(frame.thumbnail)
    setSelector({ ...selector, page, frame, folder, object: null })
  }
  

  function handleClick(frame){
    setSelector({ ...selector, page, frame, folder, object: null })
    setView({...view, overview: false})
  }

  const handleDeleteFrame = (frame) => {
    handleAction({ type: 'DELETE_FRAME', frame });
  };

  const handleDuplicateFrame = (frame) => {
    const newId = uuidv1(); // will use for frame.id and object.frame
    
    let newObjects = [...frame.objects]; // copy existing objects
    
    newObjects = createNewIds(newObjects, frame.id, newId); // create new Ids for these objects
    newObjects = newObjects.map(obj => ({...obj,frame: newId})); // update frame property

    const newFrame = {
        ...frame,
        id: newId,
        index: frame.index + 1,
        objects: newObjects
    };
    
    handleAction({type: 'INSERT_FRAME', frame: newFrame });
}

    
    const elementRef = useRef(null);

    // TODO: DISABLE DURING STREAMING BECAUSE DURING AI WORL IT JUST JUMPS SPORADICALLY AROUND THE SCREEN
    useEffect(() => {
      if (isSelected && elementRef.current && !dndState.isDragging && !streaming) {
        !streaming && elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }, [isSelected, dndState.isDragging, streaming]);  
    

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: frame.id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  

    const isLoading = view.loading.includes(frame.id)
    // console.log(frame)
    
    const primary = '#E04C18'

  return (
        <div 
        // add drag & drop listeners
        className="h-auto" 
        //style={{animation: isSelected ? 'fadeInDrop 150ms ease' : 'fadeInDrop 50ms ease'}}
        >
        <div className={`flex transition-all relative flex-col flex-shrink-0 flex-grow-0 group ${isDraggedFrame ? 'bg-accent rounded-xl' : ''}`}
        style={{ ...style,
        flexBasis: columnWidth - 12, 
        maxWidth: `${columnWidth - 12}px`,
        transform: isDraggingPreview && 'rotate(-5deg) scale(0.75) translate(12.5%, 12.5%)',
        }}>

          { isDraggedFrame ? <div className={`rounded`}
          ref={elementRef}
          style={{
            width: `${columnWidth - 12}px`,
            height: `${thumbnailHeight - 6}px`, // here - 12 vs -16 as we account for border width
            boxSizing: 'border-box', 
          }}
          ></div>
          : 
          <>
          {showNotes && <div className="flex flex-col w-full gap-1">
        <div className='flex flex-row text-xs text-base-0-500 font-light items-center justify-between transition-all gap-1 mb-2'>
        <PageName frame={frame} columnWidth={columnWidth} isSelected={isSelected} handleFrameSelection={handleFrameSelection} showNotes={showNotes}/>
        {/*<ContextMenu
        frame={frame}
        onEdit={handleClick}
        onDuplicate={handleDuplicateFrame}
        onDelete={handleDeleteFrame}
        isSelected={isSelected}
        addNextFrame={addNextFrame}
        />*/}
        </div>  
        
        {/*<PageNotes frame={frame} isSelected={isSelected} handleFrameSelection={handleFrameSelection} showNotes={showNotes}/>*/}
        
        </div>}
          <div className={`rounded`}
          style={{boxShadow: `0 0 0 1px red)`}}
          ref={elementRef}
          >
        <div
        
          className={`overflow-hidden relative transition-all flex-shrink-0 duration-100 shadow-sm border-[0.5px] border-base-300 rounded-md `}
        onClick={(e) => handleFrameSelection(e, frame)}
        ref={setNodeRef} id={frame.id}  {...attributes} {...listeners}
        onDoubleClick={() => handleClick(frame)}
          style={{
            boxShadow: isSelected && `0 0 0 1px var(--base-0), 0 0 0 3px var(--primary)`,
            width: `${columnWidth - 10}px`,
            height: `${thumbnailHeight - 6}px`, // here - 12 vs -16 as we account for border width
            boxSizing: 'border-box', 
          }}
        >
          {isLoading && <div className="absolute w-full h-full loading"></div>}
          <div 
            style={{width: 1440, height: 820, transform: `scale(${columnWidth / 1440})`, transformOrigin: 'top left'}}
            className="bg-base-0 w-full h-full flex items-start justify-start "
            >
          {<FramePreview
          frame={frame}
          cssVars={cssVars} 
        />}
          </div>
        </div>
        
        
        </div>
        
        
        </>}
        </div>
        
    </div>
  );
}






