import React, { useContext } from 'react';
import { getOptimalTextColor } from '../../../../../utilities/colorHelpers';
import { EditorContext } from '../../../EditorContext';
import { Xmark } from 'iconoir-react';
import { DSContext } from 'pages/editor/DSContext';

export default function SelectColor({ propKey, label, colorKeys, currentColor, onChange }) {

  const { currentTheme, currentThemeId } = useContext(DSContext)
  const colorVars = [
    'base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'base-400', 'base-500', 'base-600', 'base-700', 'base-800', 'base-900', 'base-content',
    'primary', 'primary-focus', 'primary-content', 'primary-surface', 
    'accent', 'accent-focus', 'accent-content', 'accent-surface',
    'info', 'info-focus', 'info-content', 'info-surface',
    'success', 'success-focus', 'success-content', 'success-surface',
    'warning', 'warning-focus', 'warning-content', 'warning-surface',
    'error', 'error-focus', 'error-content', 'error-surface'
  ]
  const colorVariables = Object.fromEntries(
    Object.entries(currentTheme.variables).filter(([key]) =>
      colorVars.some(filter => key.includes(filter))
    )
  );
  
  const filteredVariables = Object.fromEntries(
    Object.entries(colorVariables).filter(([key]) => colorKeys.includes(key))
  );
 
  // TODO allow 'transparent' as a color option
  // TODO add nulify
  // ADD non-strict color choices
  // const nullOption = colorKeys.find(key => key == null)
  
  return (
    <div className='flex flex-col w-full gap-0 items-end text-xs'>
    <div className='flex flex-row gap-1 justify-between items-start w-full'>
      {label}
      <button className='hover:underline opacity-70 hover:opacity-100 transition-all duration-75'
      onClick={() => onChange(propKey, null)}
      ><Xmark /></button>
    </div>
    <div className='flex flex-col gap-2 rounded w-full bg-base-50 hover:bg-base-0 hover:ring-inset hover:ring-[0.5px] hover:ring-base-100
    transition-all duration-150
    '>
      
        <div className='w-full p-1 flex flex-row flex-wrap gap-[3px] items-start'>
        {Object.entries(filteredVariables).map(([varName, colorValue]) => (
          <div
            key={varName}
            className={`h-5 rounded-sm flex items-center justify-center p-2 text-sm font-medium hover:scale-105 transition-all duration-150 relative group
            ring-1 ${currentColor == varName ? 'border border-base-0 ring-primary z-10' : 'hover:ring-primary border border-base-0 ring-base-100 hover:z-10'}
            `}
            onClick={() => onChange(propKey, varName)}
            title={colorValue}
            style={{
              backgroundColor: colorValue,
              width: 20
              
            }}
          >
            <div className='absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full opacity-0 group-hover:opacity-100 transition-all duration-150
            text-xs whitespace-nowrap bg-base-100 text-base-700 p-1 rounded-md pointer-events-none
            ' 
            >{varName}</div>
          </div>
        ))}
        {/*nullOption && 
        <div
        key={'none'}
        className={`h-6 rounded-md flex items-center justify-center text-sm font-medium relative
        ${currentColor == 'none' ? 'text-primary' : 'text-base-700 hover:text-primary'}`}
        onClick={() => handleColorSelect('none')}
        title={'none'}
      >
        <Xmark />
      </div>
      */}
      </div>

    </div>
    </div>
  );
};
