import { ArrowLeft, Google } from "iconoir-react"

export default function IconsPicker({theme, setTheme, iconSets}) {
    const map = {
        'heroicons': heroicons,
        'feather': feather,
        'iconoir': iconoir,
        'material': material,
        'ionic': ionic

    }
    const setStyles = `flex flex-col gap-1 h-14 items-center text-xs transition-all p-2 pb-1 rounded-md hover:scale-110 cursor-pointer border`
    const currentSet = theme.config.iconset
    return (
        <div className='flex flex-col gap-1 items-start w-full relative group'>
            {/*<label className='text-xs'>Icon Set</label>*/}
                <div 
                className='grid grid-cols-5 gap-3 w-full'
                >
                    {iconSets.map(iconSet => 
                    <div 
                    key={iconSet}
                    className={`${setStyles} ${currentSet === iconSet ? 'bg-base-100 border-primary text-primary' : 'border-transparent hover!boder-primary bg-base-100'}`}
                    onClick={()=> setTheme({...theme, config: {...theme.config, iconset: iconSet}})}
                    >
                        <div className="w-5 h-5 flex-grow-0"
                        
                        >{map[iconSet]()}</div>
                        {iconSet == 'heroicons' ? 'hero' : iconSet}
                    </div>
                    )}
                </div>
                
        <span className='absolute top-1/2 -right-4 group-hover:translate-x-full translate-x-3/4 -translate-y-1/2 z-50 opacity-0 group-hover:opacity-100 transition-all 
        px-3 pl-1 py-1 flex gap-1 rounded-lg text-sm font-medium shadow-sm
        bg-white text-slate-800 !bg-base-0 !text-base-content'>
            <ArrowLeft className="scale-50" style={{strokeWidth: 3}} />icon set
        </span>

        </div>
                
    )
}



function heroicons() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
        </svg>
    )
}

function feather() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-feather"><path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path><line x1="16" y1="8" x2="2" y2="22"></line><line x1="17.5" y1="15" x2="9" y2="15"></line></svg>
    )
}

function iconoir() {
    return (
        <svg viewBox="0 0 24 24" strokeWidth="1" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M14.1488 9.47163V3.61153C14.1488 2.72151 13.4273 2 12.5373 2V2C11.6473 2 10.9258 2.72151 10.9258 3.61153V8.44611" stroke="currentColor" strokeLinecap="round"></path><path d="M16.346 12.841L18.5217 5.58862C18.7755 4.74265 18.2886 3.85248 17.4394 3.60984V3.60984C16.5943 3.3684 15.7142 3.8609 15.4779 4.70743L14.1484 9.47149" stroke="currentColor" strokeLinecap="round"></path><path d="M7.61935 9.24985L8.67489 11.5913C9.03961 12.4003 8.68159 13.352 7.87404 13.72C7.06183 14.0901 6.10347 13.7296 5.73663 12.9159L4.68109 10.5745C4.31637 9.76542 4.67439 8.81376 5.48193 8.44574C6.29415 8.07559 7.25251 8.43614 7.61935 9.24985Z" stroke="currentColor" strokeLinecap="round"></path><path d="M11.7192 12.2615V12.2615C11.9239 11.694 11.8998 11.0692 11.6518 10.5192L10.5787 8.13874C10.2181 7.33892 9.27613 6.98454 8.4778 7.34836V7.34836C7.66469 7.71892 7.31885 8.68832 7.71382 9.48986L7.84946 9.76511" stroke="currentColor" strokeLinecap="round"></path><path d="M13.8566 17.6767L14.3487 16.6927C14.3976 16.5947 14.3461 16.4763 14.241 16.4454L10.6903 15.4011C9.97853 15.1918 9.51797 14.5038 9.59563 13.766V13.766C9.68372 12.9292 10.4284 12.3188 11.2662 12.3968L16.0542 12.8422C16.0542 12.8422 19.8632 13.4282 18.5447 17.2372C17.2262 21.0463 16.7867 22.3648 13.8566 22.3648C11.9521 22.3648 9.16855 22.3648 9.16855 22.3648H8.87555C6.52912 22.3648 4.62697 20.4627 4.62697 18.1163V18.1163L4.48047 9.91211" stroke="currentColor" strokeLinecap="round"></path></svg>
    )
}

function material() {
    return (
        <Google />
    )
}

function ionic() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512" style={{ color: 'currentColor' }}>
            <path fill="currentColor" d="M256 153.9A102.1 102.1 0 10358.1 256 102.23 102.23 0 00256 153.9z" />
            <circle fill="currentColor" cx="402.59" cy="116.45" r="46.52" />
            <path fill="currentColor" d="M459.86 163.2l-1.95-4.28-3.11 3.52a70 70 0 01-28.06 19.32l-3 1.1 1.22 2.93A181.43 181.43 0 01439 256c0 100.92-82.1 183-183 183S73 356.92 73 256 155.08 73 256 73a180.94 180.94 0 0178.43 17.7l2.87 1.3 1.25-2.92A70.19 70.19 0 01359.21 62l3.67-2.93-4.17-2.07A221.61 221.61 0 00256 32C132.49 32 32 132.49 32 256s100.49 224 224 224 224-100.49 224-224a222.19 222.19 0 00-20.14-92.8z" />
        </svg>
    );
}