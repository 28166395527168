import { useEffect, useState } from 'react';
// import * as SampleLogos from '../../assets/brand/index';
import { config } from '../config';

type LogoProps = {
    type?: 'logo' | 'symbol',
    size?: '12px' | '16px' | '20px' | '24px' | '28px' | '36px' | '40px' | '48px' | '60px' | '96px',
    isInverted?: boolean,
    customWidth?: number,
    customHeight?: number,
    onClick?: () => void,
    selfAlign?: 'auto' | 'start' | 'center' | 'end',
    assets?: any,
    __juno?: any
}

/* TODO needs auto swap for inverted logo when dark mode is enabled */

export default function Logo({
        type = 'symbol',
        size = '28px',
        customWidth,
        customHeight,
        selfAlign,
        isInverted,
        onClick,
        __juno = {},
      }: LogoProps) {
    
    const localConfig = { ...config, ...(__juno?.designConfig || {}) };

    const [isDarkMode, setIsDarkMode] = useState(false);

    /*/ Effect to detect theme changes
    useEffect(() => {
        const themeClass = document.documentElement.classList.contains('dark');
        setIsDarkMode(themeClass);
    }, []);*/

    const logo = localConfig?.brand?.logo || config?.brand?.logo
    const logo_inverted = localConfig?.brand?.logo_inverted || config?.brand?.logo_inverted
    const symbol = localConfig?.brand?.symbol || config?.brand?.symbol
    const symbol_inverted = localConfig?.brand?.symbol_inverted || config?.brand?.symbol_inverted
    
    const useNormal = !isInverted ? !isDarkMode : isInverted
    const imageOptions = [
        {option: 'symbol', url: isInverted ? symbol_inverted : symbol},
        {option: 'logo', url: isInverted ? logo_inverted : logo}
    ]
    
    const imageURL = imageOptions.find(option => option.option === type)?.url || null;
    
    const classes = `flex-shrink-0 ${selfAlign ? 'self-'+selfAlign : ''}`
    
    const wrapperInlineStyles = {
        width: customWidth ? customWidth : type == 'symbol' && size,
        height: customHeight || size,
        objectFit: 'contain' as React.CSSProperties['objectFit'], 
    }

    return (
        <div
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
            style={wrapperInlineStyles}
            /* replace to '            onClick={onClick}' */
            > 
            
            <img 
                src={imageURL}
                style={wrapperInlineStyles} 
                draggable={false} 
            />
        </div>   
    )
}

/* ignore rest */


Logo.definitions = {
    apiName: 'Logo',
    displayName: 'Logo',
    description: 'A component for displaying a logo with options to choose between a symbol and a full logo. It supports customization of size, width, height, and alignment. The logo automatically adjusts for dark or light themes.',
    ai_instructions: 'can be symbol or full logo. symbol is square, logo is elongated horizontally. automaticlaly adapts to dark and light themes ',
    type: 'media',
    relativeSize: 'small',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        type: {
            type: 'oneOf',
            options: ['logo', 'symbol'],
            displayName: 'Type',
            default: 'symbol',
            tile: '1/2'
            
        },
        selfAlign: {
            type: 'oneOf',
            options: ['auto', 'start', 'center', 'end'],
            displayName: 'Self Align',
            default: 'auto', 
            tile: '1/2'
        },
        size: {
            type: 'oneOf',
            options: ['12px', '16px', '20px', '24px', '28px', '36px', '40px', '48px', '60px', '96px'],
            displayName: 'Size (height)',
            default: '28px', 
            tile: '1/2'
        },
        color: {
            type: 'oneOf',
            options: ['normal', 'inverted'],
            displayName: 'Color',
            ai_instructions: 'normal is usually dark on light background and inverted is used on dark background',
            default: 'normal', 
            tile: '1/2'
        },
        customWidth: {
            type: 'number',
            displayName: 'Custom Width',
            default: null, 
            tile: '1/2'
        },
        customHeight: {
            type: 'number',
            displayName: 'Custom Height',
            default: null, 
            tile: '1/2'
        },
    }
}


// Import the SVG files
// import sample_symbol from '../assets/brand/symbol.svg'; 
// mport sample_logo from '../assets/brand/logo.svg'; 
// import sample_symbol_inverted from '../assets/brand/symbol.svg';
// import sample_logo_inverted from '../assets/brand/logo_inverted.svg';

/*
import sample_symbol from '../assets/brand/hellodoc/hellodoc_sym.svg'; 
import sample_logo from '../assets/brand/hellodoc/hellodoc.svg';
import sample_symbol_inverted from '../assets/brand/hellodoc/hellodoc_sym.svg'; 
import sample_logo_inverted from '../assets/brand/hellodoc/hellodoc.svg';
*/