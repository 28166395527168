import { SegmentedSwitch, Select, ToggleSwitch } from "../../../../ui-kit/exports/react";
import CornersPicker from "./CornersPicker";


export default function Surfaces({theme, onSave}) {

  const outlineOptions = [
    {"label":"None","value":"none"},
    {"label":"Default","value":"light"},
    {"label":"Strong","value":"strong"}
  ]
  const selectedOutline = theme.config.outlines || 'light';

return (
<div className="flex flex-col flex-nowrap w-full text-auto gap-3 items-start justify-start  p-3">
  <h1 className="text-ellipsis font-accent whitespace-pre-wrap text-base font-medium text-left">
    Styling
  </h1>
  <div className="flex flex-col flex-nowrap w-full text-auto gap-3 items-start justify-start">
    {/* Outlines */}
    <div className="flex gap-3 text-xs items-center w-full">
        <span className="w-1/4 flex-shrink-0">Outlines</span>
        <div className="flex-grow ">
        <SegmentedSwitch 
            size="small"
            width="full"
            options={outlineOptions}
            value={selectedOutline}
            onChange={(value) => onSave({...theme, config: {...theme.config, outlines: value}})}
            />
        </div>
    </div>
      
  <div className="flex gap-3 text-xs items-center w-full">
        <span className="w-1/4 flex-shrink-0">Corners</span>
        <div className="flex-grow">
          <CornersPicker theme={theme} onSave={onSave} />
        </div>
      </div>
    
    <div className="flex gap-3 text-xs items-center w-full">
        <span className="w-1/4 flex-shrink-0"></span>
        <div className="flex-grow ">
          <ToggleSwitch 
            size="small"
            checked={theme.config.is_rounded || false}
            onChange={(e) => onSave({...theme, config: {...theme.config, is_rounded: e.target.checked}})}
            style={theme.config.is_rounded ? 'round' : 'rectangle'}
            label="Rounded Elements"
            />
        </div>
    </div>

    
      
  </div>
  
</div>)
}