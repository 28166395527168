import React, { useRef, useMemo } from "react";
import { getChildren } from "../../../utilities/helpers";
import Router from "./Router";
import { isEqual } from "lodash";
import { config } from "../../../ui-kit/exports/react/components/config";


function compareState(prevProps, nextProps) {
    // LIST ALL SITUATIONS WHEN TO RERENDER FRAME
    
    /* if (!isEqual(prevProps, nextProps)) {
        return false; // Return false to prevent a re-render
    } else {
        return true;
    }*/
    
    // New frame is selected
    if (prevProps.selector?.frame?.id !== nextProps.selector?.frame?.id) {
        return false; // Return false to indicate props are not equal, causing a re-render
    }

    // New object is selected
    if (prevProps.selector?.object?.id !== nextProps.selector?.object?.id) {
        return false; // Return false to indicate props are not equal, causing a re-render
    }

    // New object is hovered
    if (prevProps.hoveredObjId !== nextProps.hoveredObjId) {
        return false; // Return false to indicate props are not equal, causing a re-render
    }

    // New object is hovered
    if (prevProps.view?.showOutlines !== nextProps.view?.showOutlines) {
        return false; // Return false to indicate props are not equal, causing a re-render
    }

    // Page changed
    if (!isEqual(prevProps.selector?.page, nextProps.selector?.page)) {
        return false; // Return false because the objects have changed
    }
    
    // Frame objects changed
    if (!isEqual(prevProps.selector?.frame, nextProps.selector?.frame)) {
        return false; // Return false because the objects have changed
    }

    // Frame objects changed
    if (!isEqual(prevProps.assets, nextProps.assets)) {
        return false; // Return false because the objects have changed
    }

    // View changed to mobile
    if (!isEqual(prevProps.isMobile, nextProps.isMobile)) {
        return false; // Return false because the objects have changed
    }

    // THEME
    if (!isEqual(prevProps.currentTheme, nextProps.currentTheme)) {
        return false; // Return false because the objects have changed
    }
    // If none of the above conditions are met, return true to prevent a re-render
    if (nextProps.dndState?.isDragging) {
        return false
    }

    if (nextProps.streaming) {
        return false
    }

    if (prevProps.view?.mode !== nextProps.view?.mode) {
        return false
    }
    
    return true;
}


const Frame = React.memo((props) => {

    const { 
        selector, setSelector,
        currentTheme, styleVariables,
        project, 
        hoveredObjId, 
        view, setView, isMobile,
        streaming,
        handleMouseEnter, handleMouseLeave, 
        handleAction, 
        dndState, handleDragStart, handleDragOver, handleDragEnd, collectRefs
     } = props
    const frame = selector?.frame;
    
    
    const renderCountRef = useRef(0); // Initialize a ref to store the render count
    renderCountRef.current++; 
    // console.log('frame rerender', renderCountRef.current)

    // console.log(selector)
    frame.frame = frame.id;
    frame.parent = frame.id;

    const children = useMemo(() => {
        return getChildren(frame.id, frame.objects) || [];
      }, [frame.id, frame.objects]);  // Dependencies array
      
    const frameRef = useRef();

    
    
    const dndOverObject = {...frame, componentAPIName: 'Frame'}
    const dndListeners = {
        onDragStart: (event) => {event.stopPropagation(); handleDragStart(event, dndOverObject, frameRef)},
        onDragOver: (event) => {event.stopPropagation(); handleDragOver(event, dndOverObject, frameRef)},
        onDragEnd: (event) => {event.stopPropagation(); handleDragEnd(event, dndOverObject, frameRef)},
    }

    const isDraggingLayout = dndState?.draggingLayout
    const assets = project?.assets
    // const jsx = prepareFrameJSXwithIDs(selector)
    // console.log(jsx)

    const projectConfig = project?.project?.config || {}
    const brand = projectConfig?.brand || {}
    const designConfig = currentTheme?.config || {}
    const modifiedConfig = {...designConfig, brand: {...config.brand, ...brand}}
    
    function renderCanvasFrame(children) {
        return children
            .sort((a, b) => a.index - b.index)
            .map((object) => (
                <Router 
                    key={object.id} 
                    self={object}
                    environment={{
                        location: 'canvas', 
                        isMobile,
                        frame,
                        assets, 
                        designConfig: modifiedConfig,
                        editorProps: {
                            view, streaming, setView, 
                            selector, setSelector, 
                            handleAction, hoveredObjId, 
                            handleMouseEnter, handleMouseLeave,
                            dndState, handleDragStart, handleDragOver, handleDragEnd, collectRefs
                        }
                    }} />
                        ));
                }

    function PreviewFrame() {
        // console.log('rendering preview frame')
        // const [loading, setLoading] = useState(true);
        return <iframe
              src={`/share/frame_full/${frame.id}`}
              className="w-full h-full"
              // onLoad={() => setLoading(false)}
            />
    }
    
    
    const cssVariables = Object.fromEntries(
        Object.entries(styleVariables).map(([key, value]) => [`--${key}`, value])
    );
  
    // console.log('vars', cssVariables)
    return (
            <div className={`flex flex-col items-start flex-shrink-0 relative w-full min-h-full 
                ${view.mode == 'editor' ? 'h-auto' : 'h-full'}
            ${isDraggingLayout &&  ` dragging-parent `}
            `}
            ref={frameRef}
            {...dndListeners}
            id={'frame'}
            style={{
                ...cssVariables,
                color: 'var(--base-content)',
                boxSizing: 'content-box', 
                backgroundColor: 'var(--base-0)',
                fontFamily: `var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        }}
            >
            {view.mode == 'editor' ? renderCanvasFrame(children) : <PreviewFrame />}

            </div>
    );
}, compareState);

export default Frame;



            

            {/* Extra frame (hidden) to query for code inspector 
            // TODO old approach where we render the frame in an invisible div so we can look up elements
            {view.rightSide == 'inspect' &
            <div id="invisibleFrame" className="hidden">
                {renderPreviewFrame(children)}
            </div>}
            */}
