import React, { useState } from 'react';
import * as UIKit from '../'; 
import { Pagination, Icon, Checkbox } from '../'; 

type Component = {
  component: string,
  props: {[key: string]: any}
}

type TableColumn = {
  accessor: string,
  type: 'text' | 'number' | 'datetime' | 'object' | 'arrayOfObjects' | 'components' | string,
  header?: string,
  width: string,
  direction?: string,
  alignItems?: string,
  justifyContent?: string,
  isSortable?: boolean,
  hideOnMobile?: boolean,
  displayOnHoverOnly?: boolean,
};

type RowData = {
  id: number | string,
  [key: string]: string | number | Component | Array<Component>
}

type TableWidgetProps = {
  bgColor?: 'base-0' | 'base-100' | 'zebra' | string,
  textSize?: 'auto' | 'xs' | 'sm' | 'base' | 'md',
  cellPaddingX?: '0px' | '4px' | '6px' | '8px' | '12px' | '16px' | '24px',
  cellPaddingY?: '0px' | '4px' | '6px' | '8px' | '12px' | '16px' | '24px',
  pagination?: 'none' | 'mini' | 'standard',
  showHeader?: boolean,
  corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl',
  borders?: 'none' | 'all' | 'horiz' | 'vert' | 'onlyHeader',
  hasOutline?: boolean,
  hasRowSelect?: boolean,
  columnData?: TableColumn [],
  rowData?: RowData [],
  rowsPerPage?: number,
  onRowClick?: (rowId: number) => void,
  __juno?: any
}

export default function TableWidget({
    bgColor,
    textSize,
    cellPaddingX = '8px',
    cellPaddingY = '6px',
    pagination,
    showHeader = true,
    corners = 'md',
    borders = 'horiz',
    hasRowSelect,
    columnData = [], 
    rowData = [],       
    hasOutline = true,
    rowsPerPage = 10,
    onRowClick= () => {},
    __juno = {}
  }: TableWidgetProps) {
    
    const isMobile = false
    const [selectedRows, setSelectedRows] = useState([])
    const [ sortState, setSortState ] = useState({ accessor: 'name', direction: 'asc' })
    const totalRows = rowData.length
    const paginationRequired = totalRows > rowsPerPage
    const totalPages = Math.ceil(totalRows / rowsPerPage)
    const [currentPage, setCurrentPage] = useState(1)
    
    function handleAllRowsSelect () {
      const rowIds = rowData.map((item) => item.id) || []
      const newSelectedRows = selectedRows.length < rowIds.length ? rowIds : [] 
      setSelectedRows(newSelectedRows)
    }
    function handleRowSelect (rowId) {
      let newRows = [...selectedRows]
      if (selectedRows.includes(rowId)) {
        newRows = selectedRows.filter((row) => row !== rowId)
      } else {
        newRows = [...selectedRows, rowId]
      }
      setSelectedRows(newRows)
    }
    // Pre-process data
    let columns = columnData
    let data = [...rowData].sort((a, b) => {
    if (a[sortState.accessor] < b[sortState.accessor]) {
      return sortState.direction === 'asc' ? -1 : 1;
    }
    if (a[sortState.accessor] > b[sortState.accessor]) {
      return sortState.direction === 'asc' ? 1 : -1;
    }})

    
    const addSelection = hasRowSelect
    if (addSelection) {
        const newColumn = {
            accessor: 'checkbox',
            header: '',
            width: '6%',
            type: 'checkbox',
            direction: 'flex-row',
            alignItems: 'center',
            justifyContent: 'start',
            isSortable: false,
            hideOnMobile: false
        }
        columns = [newColumn, ...columns]
        
        data = data.map((row) => {
            return { checkbox: { 
              component: "Checkbox", 
              props: { 
                checked: selectedRows.includes(row?.id),
                size: "small", 
                label: null, 
                /* replace to '                onChange: () => handleRowSelect(row?.id)' */
                } 
              }, 
              ...row,
            }
        })
    }

    // STYLING 
    const textColor = (!bgColor || bgColor == 'none') ? '' : `text-base-content`
    const cornerStyles = corners != 'none' ? `rounded-${corners}` : ''
    const textSizeStyles = textSize ? `text-${textSize}` : 'text-base'
    
    const tableContainerClasses = `w-full table-auto border-collapse ${textSizeStyles} ${textColor} ${cornerStyles}`
    
    const lighterBorder = borders == 'none' ? 'none' : `1px solid color-mix(in srgb, currentColor 10%, transparent)`
    const darkerBorder = borders == 'none' ? 'none' : `1px solid color-mix(in srgb, currentColor 15%, transparent)`
    const borderRadius = corners == 'none' ? '0' : `var(--border-radius-${corners})` || '4px'
    
    // Background color for the table rows
    function getRowStyles(index, rowId) {
      const rowSelected = selectedRows.includes(rowId)
      let rowStyles = ``
      if (!bgColor || bgColor == 'none') {
        rowStyles = rowSelected ? 'bg-base-50' : 'hover:bg-base-50' // transparent bg only highlights on hover or when selected
      } else if (bgColor == 'zebra') {
        rowStyles = index % 2 === 0 ? 'bg-base-50' : 'bg-base-0 hover:bg-base-50' // zebra stripes
      } else {
        rowStyles = bgColor == 'base-0' ? 
        rowSelected ? `bg-base-50` : `bg-base-0 hover:bg-base-50` : 
        bgColor == 'base-50' ? rowSelected ? `bg-base-100` : `bg-base-50 hover:bg-base-100` : ''
      }
      return rowStyles
    }

    const padding = {
      paddingLeft: cellPaddingX,
      paddingRight: cellPaddingX,
      paddingTop: cellPaddingY,
      paddingBottom: cellPaddingY
    }
    
    // This function helps style the cells based on the column configuration
    const styleFromColumn = (column, isHeader=false) => ({
        ...(isHeader && !showHeader ? {} : padding),
        display: isHeader && !showHeader ? 'none' : 'flex',
        height: isHeader ? showHeader ? 'auto' : 0 : '100%',
        minHeight: '28px',
        textAlign: column?.textAlign,
        whiteSpace: column?.nowrap ? 'nowrap' : 'normal', 
        alignItems: column?.alignItems,
        justifyContent: column?.justifyContent,
        flexDirection: column?.direction,
      });
    
    // This function ensures that the total width of the columns is 100%
    const totalVisibleWidth = columns?.reduce((total, column) => {
      if (!isMobile || !column.hideOnMobile) {
        const widthValue = parseFloat(column.width);
        return total + widthValue;
      }
      return total;
    }, 0);

    // This function renders the content of each cell
    function renderCell({ content, columnType='text' }){
      if (columnType === 'object' || columnType === 'arrayOfObjects' || columnType === 'checkbox' || columnType === 'components') {
        if (!Array.isArray(content)) {
          content = [content] // Convert non-array content to an array
        }
    
        return (
          <>
            {content.map((item, idx) => {
              if (item && typeof item === 'object' && item?.component && item?.props) {
                const Component =  UIKit[item.component]
                
                if (Component) return (
                  <React.Fragment key={idx}>
                    {React.createElement(Component, item.props)}
                  </React.Fragment>
                );
              } else {
                return <span key={idx}>{item ? String(item) : ''}</span>;
              }
            })}
          </>
        );
      } else {
        // For 'text', 'number', 'datetime', render the content directly
        return <span>{content ? String(content) : ''}</span>;
      }
    };    

    return (
      <div 
      className={`relative w-full flex-shrink-0 border-spacing-0 ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
      {...__juno?.attributes}
      >
        {(columns.length == 0 || !columns) ? 
        <div className={`flex flex-col gap-2 items-center ring-1 ring-inset ring-current-10 
        bg-current-5 justify-center w-full p-5 text-base-content
        ${cornerStyles}
        `}>
          <Icon icon='table' size='20px' />
          Table is not configured
        </div>
        :
        <table className={tableContainerClasses}
         style={{
          // tableLayout: 'fixed',  
          borderCollapse: 'separate',  
          borderSpacing: 0,
          border: hasOutline ? darkerBorder : undefined,
          borderRadius
        }}
        
        >
        
          {/* TABLE HEADER */}
          <thead >
          <tr className={!columns.length ? `h-7 ` : ''}>
            {columns?.map((column, index) => {
              if (isMobile && column.hideOnMobile) {
                return null; // Don't render this column on mobile
              }
              const originalWidthPercent = parseFloat(column.width);
              const adjustedWidth = (originalWidthPercent / totalVisibleWidth) * 100;
              const isSortable = column.isSortable && column.type !== 'object' && column.type !== 'arrayOfObjects';
              const isSorted = sortState.accessor == column.accessor;
              
              return (<th key={index} 
                className={`${column?.hideOnMobile ? 'hidden @md:table-cell' : 'table-cell'} relative`}
                style={{ 
                width: `${adjustedWidth.toFixed(2)}%`,
                backgroundColor: (!bgColor || bgColor == 'none') ? '' : bgColor == 'base-100' ? 'var(--base-100)' : 'var(--base-0)',
                borderRight: (borders == 'vert' || borders == 'all') ? lighterBorder : 'none',
                borderBottom: showHeader && borders !== 'none' && borders != 'vert' ? darkerBorder : 'none',
                
                ...(showHeader && index == 0 ? { borderTopLeftRadius: borderRadius } : {}),
                ...(showHeader && index === columns.length - 1 ? { borderTopRightRadius: borderRadius } : {}),
                // Ensure that the right border is not applied to the last header cell
                ...(index === columns.length - 1 ?  { borderRight: 'none'} : {}),
                }}>
                  <div 
                    className='flex gap-1.5 h-full relative group items-center select-auto'  
                    style={styleFromColumn(column, true)} >
                  {column.type === 'checkbox' ? 
                    <Checkbox 
                      size='small' 
                      checked={selectedRows.length > 0}
                      label={''} 
                      indeterminate={selectedRows.length != rowData.length}
                      /* replace to '                      onChange={handleAllRowsSelect}' */
                    /> : 
                  column.header}
                  {isSorted ?
                  <div className={`rounded-full hover:bg-current-10 cursor-pointer flex-shrink-0`} 
                  style={{order: column.justifyContent == 'end' ? -2 : 2}}
                  onClick={(e) => 
                      {e.stopPropagation();
                      setSortState({ 
                      accessor: column.accessor, 
                      direction: sortState.direction === 'asc' ? 'desc' : 'asc' })}}>
                  
                  {sortState.direction == 'asc' ? 
                    <Icon icon='arrow-up' className='scale-75 w-4 h-4 select-none' /> : 
                    <Icon icon='arrow-down' className='scale-75 w-4 h-4 select-none'  />
                  }
                  </div> : isSortable && 
                  <div className={`rounded-full hover:bg-current-10 cursor-pointer opacity-0 group-hover:opacity-100`} 
                  style={{order: column.justifyContent == 'end' ? -2 : 2}}
                  onClick={(e) => 
                    {e.stopPropagation();
                    setSortState({ 
                      accessor: column.accessor, 
                      direction: 'asc' })}}>
                  <Icon icon='arrow-down' className='scale-75 w-4 h-4 select-none'  />
                  </div>
                  }
                  </div>
              </th>
            )
            })}
          </tr>
        </thead>

      {/* TABLE BODY */}
      <tbody>
        {data?.map((item, rowIndex) => (
          <tr key={rowIndex} 
          // onClick={() => onRowClick(item.id)} 
          
          className={`${getRowStyles(rowIndex, item.id)} relative group`} >
            {columns.map((column, colIndex) => { 
              
              if (isMobile && column.hideOnMobile) {
                return null; 
              }

              const originalWidthPercent = parseFloat(column.width);
              const adjustedWidth = (originalWidthPercent / totalVisibleWidth) * 100;
              const isLastRow = rowIndex === data.length - 1;
              const borderBottom = ((borders == 'horiz' || borders == 'all') ? 
              (!isLastRow ? lighterBorder : (pagination && paginationRequired) ? darkerBorder : 'none') : 'none');

              return (<td 
                key={colIndex} 
                
                className={`${column?.hideOnMobile ? 'hidden @md:table-cell' : 'table-cell'} relative`}
                style={{   
                  borderRight: (borders == 'all' || borders == 'vert') ? lighterBorder : 'none',
                  borderBottom,
                  width: `${adjustedWidth.toFixed(2)}%`,
                  alignContent: column.alignItems || 'start',
                  justifyContent: column.justifyContent || 'start',
                  height: '100%',
                    ...(colIndex === 0 && rowIndex === data.length - 1 ? { borderBottomLeftRadius: pagination == 'none' &&  borderRadius } : {}),
                    ...(colIndex === columns.length - 1 && rowIndex === data.length - 1 ? { borderBottomRightRadius: pagination == 'none' && borderRadius } : {}),
                    ...(colIndex === columns.length - 1 ? { borderRight: 'none' } : {}),
                }}>
              <div className={`flex gap-1 h-full w-full ${column.displayOnHoverOnly ? 'opacity-0 group-hover:opacity-100' : ''}`} style={styleFromColumn(column)} >
              {renderCell({
                content: item[column?.accessor], 
                columnType: column?.type})
                }
              </div>
            </td>)}
            )}
          </tr>
        ))}
      </tbody>
      { pagination && pagination !== 'none' && paginationRequired &&
        <tfoot><tr>
        <td colSpan={columns.length} style={{overflow: 'hidden'}}>

        <Pagination 
          type={pagination}  
          // bgColor={bgColor}
          paddingX={cellPaddingX} 
          paddingY={cellPaddingY} 
          /* replace to '          onChange={(page) => setCurrentPage(page)}' */
          currentPage={currentPage} // add a variable
          totalPages={totalPages}
          />
          

      </td>
      </tr>
      </tfoot>
      }

        </table>}
        </div>
    );
}




/* ignore rest */

TableWidget.definitions = {
    apiName: 'TableWidget',
    displayName: 'Table',
    description: 'single table component designed to work with data array, does not accept any children',
    ai_instructions: `...`,
    type: 'table',
    package: 'Premium',
    relativeSize: 'large',
    acceptedChildren: 'none',
    propDefinitions: {
      bgColor: {
            type: 'oneOf',
            options: ['base-0', 'base-100', 'zebra'],
            displayName: 'Background',
            default: null,
            tile: '1/2'
        },
        borders: {
          type: 'oneOf',
          options: ['none', 'all', 'horiz', 'vert', 'onlyHeader'],
          displayName: 'Borders',
          default: 'horiz', 
          tile: '1/2'
      }, 
        textSize: {
            type: 'oneOf',
            options: ['xs', 'sm', 'base', 'md'],
            default: null,
            displayName: 'Text Size',
            tile: '1/2'
        },
        pagination: {
          type: 'oneOf', 
          options: ['mini', 'standard', 'none'],
          displayName: 'Pagination',
          ai_instructions: 'mini is a smaller version aligned to the right side, only showing next / back buttons, standard is spread-out to full width and shows page numbers in the middle',
          default: null,
          tile: '1/2'
      },
        cellPaddingX: {
            type: 'spacing',
            options: ['0px', '4px', '6px', '8px', '12px', '16px', '24px'],
            displayName: 'Spacing X',
            tile: '1/2',
            default: '8px', 
        },
        cellPaddingY: {
          type: 'spacing',
          options: ['0px', '4px', '6px', '8px', '12px', '16px', '24px'],
          displayName: 'Spacing Y',
          tile: '1/2',
          default: '6px'
        },
        corners: {
            type: 'corners',
            options: ['none', 'sm', 'md', 'lg', 'xl'],
            displayName: 'Corners',
            default: 'md',
            tile: '1/2'
        },
        showHeader: {
          type: 'bool',
          displayName: 'Show Header',
          default: true, 
          tile: '1/2'
      },
        hasRowSelect: {
            type: 'bool',
            displayName: 'Row Select',
            default: null,
            tile: '1/2'
        },
        columnData: {
          type: 'arrayOfObjects',
          displayName: 'Columns',
          default: []
        },
        rowData: {
          type: 'arrayOfObjects',
          displayName: 'Data',
          default: []
        }, 
        rowsPerPage: {
          type: 'number',
          displayName: 'Rows Per Page',
          default: 10
        },
        hasOutline: {
          type: 'bool',
          displayName: 'Outline',
          default: true
        },
        
    }
};