export const projects = [
    {  
        name: 'JUNO', 
        symbol: '/symbol.svg', 
        description: 'Interface design tool for developers.',
        stack: 'Node JS, React JS, Firebase.', 
        classes: "flex items-center justify-center md:col-span-4 md:col-start-1 md:row-start-1 md:row-span-1",
        images: [
            "https://storage.googleapis.com/juno-v1.appspot.com/juno%201.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=8ZK4x2eWr88UpUOTqVXAEKCl2R8%2FrAe92OFBj19dBe%2FarHC0tkaP94%2FmrDkjz3LPIdhJDDUssGJKI2QH6u9xQHRzU%2BUMm0DPq%2BPew7OJ13kBP5v%2FYb9tJ3Rpw0A0XOttpimaTDgL0SL9%2FP1X2HCutLZ1hjX%2BvYrcCRzGMC1t89nDsd1ElT3qC0%2F%2BA%2F2p8wFbemipFBqGuxptTjVqjMcWblJp3vAp0X2OtHOxpPwIQDQKcK2p9FbVeq9bgXyLd6a2wGgNogav9h68tcP7G1EkiNdXwGSOkPpt7Ln5gaSSpGLaXeBvruD9eYYXbScr9Bcrm7xwoBgJpxt7JlG0Ohthvg%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/juno%202.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=PrDtbPFrXM3X41UUIDCoRvGyzmV1rWkSMLgoD5QdBI0L9FviQ05itiOgq95Fh5KWHrrpxnX2BFDDBwB9F2moj%2BCgRwBZZupseLAbUPgj6TCLudZ%2FC%2FTySKIKBJ%2FB9qleEgtqLJDHSpWhHZIkT194RGBFdN3JV6CtshQO7cYy7q%2FzxturU2u6LhdGuzN9TxQJaHkXXPZCwPjUIt0eqfptThqmHfJ%2BGI%2BrUeo9jzHuYMQ%2FEGNNvbY2NWl%2BEqANiSOLKGM5XM0kyy13LIWhthzkU0bfeCXBlUb8ip%2F5MwtVvPbQCRSIoiR1mGBWPUOrC5NOe5nVyfo58r%2BNEdLN3c8KYg%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/juno%203.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=iKJeONEMSfLM53ZrG5QRr4pr2IBCzcCGimfzriu2OHhD7nxqbHYgib0YCV96P2drTNAoourJY9S%2FwywIVxAL2Q0bn4MsQF1K1CqDXf0CgCEi%2F2KXjqtcI8r49KMBroZkrWBFb8PQmh2BgGT5FV5xv8RlAeNlHR6x7cRySRilq2x%2BtyT4eGcphWAYrKIMfoy2MQuwG4LyPyn9xUUvwg%2B8J6hizb844WzNnDdNA2LWKn7YsseI6jDSPacg3OQbX2GKlO4I4kzvbNV00tWKlCfcKiso0OaYj7lOE1G1wDwsbjVk1AjpIrZvFHHTblZgbyNwRXJRfwjAymHWuMky7FpUlQ%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/www.junodesign.app_projects%20%281%29.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=5UZspPT%2BFKg24x8ZjqzSA5YZZX6gG3jrWZTUurBWCuVTBgkZ6l25NXshlah7td%2Bd%2Fbt6Xbn9hbDwei65lFWGTVCJG7JUAdi7BzZ2Q1SiUCzC4rnD39INWN6mNeKtc2%2FG4Ca4qogi2C0gHcB1MJq%2FQX6n5WoF8qf73Wmdat19VrG1EvyEVhaP%2BmDz4zNihQ9equ%2FKd%2BorC649uy0rU2g%2BBNTvELHza62JvdPrYcFPRxrQZQeIRBdvA3iKTbzozFNURxqWmkh%2BUoUyRpVH8aUM9xni900ebh09jTUp2ZFkVtGikLmp%2FD2IIH2dIymeOWEh6XB%2F2z7XZF%2FzLJ91psUVAg%3D%3D",            
            "https://storage.googleapis.com/juno-v1.appspot.com/www.junodesign.app_projects%20%282%29.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=X3wduBukmrhdKFYJoVTx8%2FZWbGSF55k5iEMQQnPtQcyjkpxQYl%2Fc67MgrPDrvxBJmEESSmm4HM4H9jguXNph4UgJezGuFfB5WkaPKhABqlJv4xF5LLoj2Wycga5Q0ytm77dItpuW2odF5mwLoOoU9Rpkyanyo4EMMjGIKdciC6nn4hOqaW6u%2B8bK9DakJjIPwg5MKoFrEvgDnfD38FkqJiabDcHmFEZkEiN8fK4KAlgWU4w4th67ceLgfKlhu96ToU99UT4QuiU6cj5%2F%2BFmojYE63Cd%2F3VyDTlgfSHMwPUC%2Fvf4ZEIJ7xxUuNJJ%2B1bwPkaazARD7yWtPjg160IT4bw%3D%3D",
            

          ]
        
    },

    { 
        name: 'ClearBill', 
        classes: "flex md:col-start-5 md:col-span-2 md:row-start-1 md:row-span-1 md:col-span-1",
        symbol: 'https://sednciixfnwqwlmdmeff.supabase.co/storage/v1/object/public/assets/logos/favicon.svg', 
        video: 'https://kgfipbdrigsksyfqdhrm.supabase.co/storage/v1/object/public/marketing/videos/clearbill_3.mp4',
        /*images: [
            "https://storage.googleapis.com/juno-v1.appspot.com/app.myclearbill.com_%20%281%29.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=DST9x%2FR3Aj36GFuc8Nv%2Fd4COcexf2PA7%2FmvTUNRPukJa01kDFCy1Qe9SL1PwCXsPzVRnM%2B0UmrfZOUnxRxXFRYPRy%2Fz1SMkUd6IysIw1V6Vxuq26%2BB6Woi0CuRx%2B0hxzhbltTZYTumpbYnKNkcy4u9AX98fusK6r6WIDtC46oVDtMXyCxiTEgrbwpF9FAS5XKG5OKiMeh5VX1kzugtpJ44VNEl7JMuV%2BJA%2FWhn79jz5AuOZpbZ00Y7RI1j8RZgzIvUDzssU1Vsbw6JlImff2Bq7zLRQ9LS50N5MaKOhZuzpyS3DgMFkOMO2C5VSliiqVB8XiEWb%2FqRnym%2BDSyeAjOA%3D%3D"
        ],*/
        description: 'Medical billing for patients.',
        aspect: '592/1280',
        stack: 'Python, React JS, Supabase', 
        tag: '48 hrs', 
        
    },
    
    {
        name: 'Tutorys', 
        symbol: 'https://storage.googleapis.com/juno-v1.appspot.com/logo192.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=Ibpdk3xjDSWO2O0Djv2f75mtmkC7B3m79AOCi3%2Fw46skU59wL0efV1EpL%2BK9hYTV0Nb42%2Boxi%2Bv%2FNbShUuWWOhCH%2BJ4EIi%2F%2BHLIw2lu26Z5gkcGVAIVLI%2FVyJRL2YPet1gHDy5FDY0764jZWCSTnma7RJ0Lx7wU8gNw9MQg5KC1LDhqloJCeB8OEXgWp9U2%2FYRJNns2fa4B%2Bu1%2FUZtpCnYgiVcJKDGwRgfUw1r5tZa4MwWgK%2B8tyWUVFNQbu8AYRCOZoEnay49lN3Fywx89QqNlYLvJ1HNgCgIeVwK8Fe5YimXzsLOtFb74zzw31oxG%2FK47DHuytOLgREN%2B21OpM%2BA%3D%3D',
        description: 'Pronunciation tutor for children.',
        stack: 'React JS, Python, AI.',
        classes: "h-full flex items-center justify-center md:col-start-1 md:col-span-3 md:row-start-2",
        
        images: [
            "https://storage.googleapis.com/juno-v1.appspot.com/tutorys.com_landing.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=iVA2Sugi3urwkAqUn0RiJKugMzF5GmqavvjN9Y65tOV2jpGEIouzFef5HtA%2FEh8avulqOu%2Bbl9GqO7gCvB4riZ9ErupzTcPckh67aj%2F0BXrlNDC5Zvd13vxMTpp6XihltUoicQ6e8LNKbpm2GwJS%2BjT7feteWYKcZl6te2ydvWy6jjsMioBa%2FKGY8Mgz%2F8J%2Bp7%2BJCARRozKLjdIz3OryyHF1etfhVSp0ve8UNFzoFkw1OZXpmzepl%2FGWueB6E3%2FYklbX%2BheEkhkOF84ERj%2Bfr%2FLK%2F1822qm5lIh6BE3F7PwdVvRWG1Efqjuvb1tgeJV%2BYKf62vlAFuUWg3mkDuNDqg%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/tutorys.com_test_211b9988-3eb6-45e1-afed-7afdfbe0c47e.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=dwweCZ5vsUyk42rFZQL0QC5ZN3yh2Jf%2FGZyVEtEb5dZ4jORlw8%2F2pGkBMUp2A9388RjyhD89x2JIO5Mor3JkyUrkMvOJKY%2B3RW6zo1AJG6qp2we797dbVcVKow1zh6HIUPQVjVUsX10Wzh%2BpWWO8IckpVcDU0SyM4tC3KzAi%2Bale2PfFGMcxrNVYNFV2w3icShYZPVFobzx8j6jVkQxMMRTVs5ASLiedzGinm93QpU5LeFuxwx%2F8YKL5D7RcOwdq94NUumIHEKD5oNocaqadZtXXHT6It6dvmEVSsLsHGdfhjmqHfFwD4z0CToeGehh%2Bq93mhPulJoCEM%2F3mZpgXCg%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/tutorys.com_test_211b9988-3eb6-45e1-afed-7afdfbe0c47e%20%281%29.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=Q8B4nZLtnuzXmDKqtDUBjzI6X%2BWsBULaHA2amGnKo5jZaEJoRzcEZOjsdgGv8Ga%2FPBhlreYwUDa87EZ3nocf6vkPWAX%2FuwLRXc7V8yNWVSbWx5NSrGBhHURw3dnrCJvmSHRFyq8q%2Br%2BO2QJUBs82PDqiSnSr8JRZRcbzKR1rKH4Rdphb9dgA8MrDRPwAxYv8Jq%2BAmar6C%2F69AbmM9e%2BVC3j3apnL4TfKuMF6VT1i7U%2F1sbgb9HJ0MWr9Pd5tLL5eWHmgPqINcBQHFncWDE2C5ndMjrmT0VyjjgYeuxt6xAV9cvpIwU%2Fo%2BXrA9riKdOiWZWbI57XHhsdYAqT4HVRmWA%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/tutorys.com_test_211b9988-3eb6-45e1-afed-7afdfbe0c47e%20%283%29.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=D2mCal59jatxI14K6jFRC9qpuq8J9vj66CBiPajlg85h%2F%2BIdYJ9wIuk9ZF9aP5mecj0icmdrcK1ehg1ijcw9w2URgm3X%2BhUBWt3MoPOX6bq2yNzbwipOaTm8zr3DKeISCrKIMcbIWYt8%2FhBHiuGVlY%2BWxYS9zsMg8BHYC7eTVxlzxAuY4bkE%2BzQZvCBNwSm2vmFj7JpfWWNwsiK2OH%2Foift8NVbK4W7WyirP0fP4WA8JlRiWrxj0cd8JVnXfYoBMeWni%2B%2BtWbKAt%2FoZJ2BNYN%2BiqGVtwyrI4zBJ%2Fh75CovIw8lxvlB38k6dlf5BTQ157xN0SoD65RqCrMkO5RqbOyw%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/tutorys.com_test_211b9988-3eb6-45e1-afed-7afdfbe0c47e%20%284%29.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=7RT9C3JMX5jSECmZHhmDi%2FFv2mKivUKB3P8izMhK%2FNrSTcbR8aZeSOzFrj60UKXGRevehOixCbY1TXaavYQ9qXB7Z0XRDYFVzM%2BYgkcgO3n3xYgulyy9fm0kDhaSbPF5F9t77SkoVKLzLX%2FXd%2FzEIumFUsYdJoC39qDPdFvgjiztfrfRgaY37ENp7T5RHFd88dSFVVEoDqaCWMPfwUdf%2F9ZbaGoQoHgl6IosSfTfruXiIMTkcPhjlbHnebAFr2nuV7qYNJKc8BKKnB8x4T2sLJYGM%2FZGCnbSgypQqiDZS%2BCPKL%2BoQhvXjy6g6uorrMeCjnSd06%2Bw0ZswN%2FdnJHF9GA%3D%3D",
        ]

    },
    { 
        name: 'AgTech Startup (Stealth)',
        // symbol: "https://storage.googleapis.com/juno-v1.appspot.com/barnwell_logo192.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=jAbY5N8H28VZvXXpgsVq%2B%2FhO7%2FlfLfjt4O1iiubc7UcUrjsLNaqg4lh9gzA8X7TxwxziYFYotHfANUMzNeGs7kGT5fN76vq69XkgxKW6go3GrlMW6QswzV2okrw4axzN4mmpNX0LipxTMKCcotmdhO73Jefnxq5uXoZ87%2B8y5l%2FbrJEbA%2FjIVixJyrHWnqYcLiyMl%2FV%2FZ1wSBEe9R5mxP7fLA%2Fhy0bleuv5XWlVAxPFpjKsezG1EuqULjm0%2B66rczuE94LWi8eha2bMaL3vTYuVzSM3joYaa3F%2B4bjLUgLlXw4x%2BuI13OTCap8SUL5TaEtibF2CxTflkSHvUtM59wQ%3D%3D",
        symbol: "https://storage.googleapis.com/juno-v1.appspot.com/stealth_symbol.svg?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=vT2pvFi7KwvlMqHARzzu0msbFsmU9okG2XY0tRT44pAGkHYzkVtS7ND5a7MLnVW4s2thSMEc4iY0LXdQFk5c3nV6cR6CEa%2BqyoAgaSFQ0ZeoK%2Fn1spfF6t9SdTlF%2BRtLqklEwP6zw39txxQrnczmn1GdbiiLacizO3CuK5LSsOwJ2eFMJSoAr9DOLXdwTQJ2eTTyiwe6DtGFeSQoyjJ%2BPG3E5aYfpBkBwhtXNDKYNn28hlNtgwwGULTAbWcIkuSPZ8K07G4IEf1%2FNKP%2B4s0uOkgNC1C%2Baa18PHG4rga597Wci6Izo%2FVtVJPJ1IZjQkNc6m3g8cppbsrK4jj2dPA5ng%3D%3D",
        images: [
            "https://storage.googleapis.com/juno-v1.appspot.com/barnwell%201.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=rnfG8Z9oo3dwVrTrOeO63sBXdxWyqvRBkHrbDaMWPpWI%2FMhDhy9JVOHfXfjOANwe82oYmzwwocj6s7s322yS6KV4%2BNFEGlT3LN1djJ0OR4Lj5guL%2FrDwQZ2Jhkv9NXVMkIP8ptG6HTIiSaao6dTxN4%2FUOTc3xai%2FXb9ciku%2FfYiDxK1ueqXZ3hKTl5mPzfWo3YDnzLePHPrVy4gtU9DyDrren6ne%2BwfqVYGXJHU763NN%2FZShLPhZ0zpBYbPW3K%2FqJuCE0%2BxKamUYR7wHfayCjVRN3JQVdCX2kkOPuaRy73dss3aHiOdr8carxhvxpPCUhL0fTky1PViSws2d2OrPHA%3D%3D",
                        "https://storage.googleapis.com/juno-v1.appspot.com/barnwell%205.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=6f3EHu7kOF7HTbABKShbm3SkEL%2FyZt648aiCNUthcZu5%2F8qfb5DHWLed2LsiRAszEsTSHQjVHGw473e7vciLDK%2BIVl7dEGFdYLBcYeUj32%2FnZ4iBEbkD1LrcoMrctZvvztm%2BOr4GEY4o5pcdMNzn0eiu7zWnu4abIoFd3ona9ytRyCHbhjCJ%2FLMwdmL1LDKlIHAlGPdCSRM7ZGHcpKP4yApkc%2BXfn%2B08MtQkQkkWIbFYGkHbXjZscHb52K%2Flr5NGVtrtrxkqL%2Bs0wbLVg1Hpa209pL3ag3spFCwajckh%2B%2FC8eIpKZrD8Jg59PEQZaOnNTO2YNChk04%2FeWzUVxbfUqw%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/barnwell%202.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=ojiu8OiCOmwGUKLMiJzPvAyJg4z3LJAvFoChYT84Q5nR6f9qu9znLXoY%2BnsMbcEY4JhqpCgqRV474Q35uPS%2BLtJJP6r2YJ7YZ8huCSev1Jtd%2FR3Zoh%2FsBslAqnOmJ3N6t757YJmLTm7aD6SrkwCdDA5iUJPeC8mS7W0i9CbLNHOVyRdt%2FiV1vf%2BHFwb%2F6a01teE2Ny%2Bae%2FJrqy3EA74PtYMeJ9807R324uMvY35F7U8Lp7DIc4lQWsncwUStGBMroB1ym66hRvNKyJOiiyJbKfYh9w5vS5dT0mqJfAKO9mKJ2%2BjM9xIY%2FlAEvxiybBGaWjdhp3HlI9m70kGCYjwvHw%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/barnwell%203.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=m6iOQwRE1YqKiQiGLansaDwDLGI2kV%2BBA9h5VjwlhXFL7lY4mlwGL8%2Fj0RoOiKGu0PCVedt%2BYDezddvUxuwjoy4HWlaqO3ZRMzw23eFQibyKpW71KZ1w1IXsDBj47xI44URQxKDwLjDKYTe2zDhMz7gvVvvSpReQpJpshF5Vu3bgjIf0uxvnf%2BOW%2Fkl4k0wpdY2dWwScZJpsOiMl73mVpyC4qmBLvIxra4YUsO%2B0wLq7KrTwGkhMzbMb9ClGes%2BjAFX19DpFHj5x0h2JVlpNMkmVTlGWQBIcViIhbe1%2BvpaDFakY%2BAtp1C5JYtcGZFUPhlXcGH1ZDajFf9sRWiNPRw%3D%3D",
            "https://storage.googleapis.com/juno-v1.appspot.com/barnwell%204.webp?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102462800&Signature=tjWJ75zYYtRMxwFcylZQqzOSb%2BKRWSnS3%2BIOWfZ1M6pKWXSze0JjBQzI9K5HDFugu1Bmv4DBn%2F%2Fd52wt7U06FE3xdSOEBxDBwBUM8AHEDnkRKHz18dJSlwjeJKGENaTMROFZDONZoLzewPI%2BfA6ResZoSykVMM16vnu2l1Gsu5L9R%2B7ULbbCWh6PEjw1Vzhuu1UpR5EnTDx%2BGWmKsWlO4Qtj%2F0xJIAfOn7W4Ok55asYjopRhjcQGBozIFOMPUNA1hfxyGWjqLMYbcr90NCw04Evu7nKjNnxkT2PDPUGfjS5DnekLlpSfdVT7SqRtJdnqQ3RbUTwRIInSvB6aDqAE4w%3D%3D",
        ],
        description: 'Pathogen monitoring for farmers.',
        classes: "flex items-center justify-center md:col-start-4 md:col-span-3 md:row-start-2",
        stack: 'UX/Design, React JS code.', 
        corners: 'xs'
        
    },
    
]