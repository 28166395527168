import { useState } from "react";
import { Button, ButtonIcon, InputText, Loader } from "../../ui-kit/exports/react";
import { ArrowRight, Linkedin, Mail, Twitter } from "iconoir-react";

export default function ContactForm({mainWidth}) {
    const [isSubmitted, setIsSubmitted] = useState(false);
    
    return (

  
        <div className="flex flex-col flex-grow w-full h-full items-start justify-between relative 
        p-5 mx-auto" style={{maxWidth: mainWidth}}>
        <div/>
        {!isSubmitted ? 
        <Form 
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
        /> : 
        <SuccessState 
        
        />}        
        <a className="pb-6 md:pb-12 flex flex-row gap-3 items-center mt-12
        md:hover:translate-x-1 text-base-700 md:text-base-content
        text-lg md:text-2xl md:hover:underline transition-all duration-150 ease-in-out"
        href="mailto:sam@junodesign.app"
        >
            <ArrowRight className="w-6 h-6"/> sam@junodesign.app
        </a>
    </div>
    )
    
  }

  
  function Form({
    setIsSubmitted
  }) {
      const [email, setEmail] = useState("");

      const [error, setError] = useState(null);
      
    const [isLoading, setIsLoading] = useState(false);
    
    async function handleSubmit(event) {
        event.preventDefault();
        setError(null);
        setIsLoading(true);

        if (!emailIsValid) {
            setError("Email is not valid.");
            setIsLoading(false);
            return;
        }
    
        try {
            const response = await fetch('/api/leads/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const result = await response.json();
    
            if (result.message === "Lead added successfully") {
                setIsSubmitted(true);
                
            } else {
                setError("Failed to submit form.");
            }

            setIsLoading(false);
        } catch (err) {
            console.error("Error:", err);
            setError("Something went wrong. Please try again.");
            setIsLoading(false);
        }

     
     }
    
     const emailIsValid = email.trim() && email.includes('@') && email.includes('.');
    
      return (

          <form className="flex flex-col flex-nowrap w-full max-w-[1200px]
          text-base-content gap-3 items-start justify-start h-auto"
              onSubmit={handleSubmit}
          >
             
              <h1 className="text-ellipsis text-2xl md:text-4xl font-semibold select-none whitespace-nowrap"
                  style={{
                      animationDuration: '150ms',
                  }}>
                  Get In Touch
              </h1>
              <div className="flex flex-row w-full gap-4 items-center">
                  <input 
                    className="bg-base-0 text-2xl md:text-6xl placeholder-base-200 h-12 md:h-auto appearance-none border-none"
                    type="email"
                    name="email"
                    id='email-input'
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); setError('') }}
                    placeholder='you@company.com'

                  />
                  {email.length > 0 && 
                  <ButtonIcon
                    isPill
                    size='large'
                    color={emailIsValid ? 'primary' : 'base-700'}
                    style={emailIsValid ? 'filled' : 'ghost'}
                    state={isLoading ? 'loading' : emailIsValid ? 'default' : 'disabled'}
                    icon='arrow-right'
                  />}
              </div>
              
              {error && <p className="text-red-500 mt-3">{error}</p>}
          </form>
      )
  }
  

  function SuccessState() {
    return (
        <div className="flex flex-col flex-nowrap w-full max-w-[1200px]
        text-base-content gap-3 items-start justify-start h-auto"
            
        >
           
            <h1 className="text-ellipsis text-2xl md:text-4xl font-semibold select-none whitespace-nowrap"
                style={{
                    animationDuration: '150ms',
                }}>
                We'll be in touch.
            </h1>

        </div>
    )
  }


