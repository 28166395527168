import { Button, Checkbox, InputText, Main } from "../ui-kit/local"
import ReactDOMServer from 'react-dom/server';

export default function TestPage() {
  const content = ReactDOMServer.renderToString(<SamplePage />);
  const htmlString = `
    <html>
      <head>
        <!-- Link to any CSS or include style tags here -->
        <link rel="stylesheet" href="/styles/tailwind.css" />
      </head>
      <body>
        ${content}
      </body>
    </html>
  `;
  return (
    <div className="w-full h-screen flex flex-col items-center bg-white p-4">
      <Checkbox />
        <iframe
          // src="/share/frame_full/37520c20-cdea-11ef-bdab-81ae8479a0fa"
          srcDoc={htmlString}
          width="600"
          height="300"
          style={{ border: "1px solid #ccc" }}
          title="Preview"
          // sandbox="allow-same-origin" 
        />
    </div>
  );
}

export function SamplePage() {
  // const designConfig = {designConfig: theme.config}
  return (
    <Main selfAlign="center" alignItems="center" justifyContent="center" background="base-50" >
<form className="flex flex-col flex-nowrap  px-6 py-6 w-full max-w-full max-w-[400px]  self-auto text-base-content  bg-base-0  gap-6 rounded-lg items-start justify-start h-auto" style={{maxWidth: '400px' }}>
  <div className="flex flex-col flex-nowrap w-full max-w-full   self-auto text-inherit    gap-2   items-start justify-start h-auto  ">
    <h1 className="text-ellipsis text-2xl     font-semibold  " style={{ whiteSpace: 'pre-wrap' }}>
      Form Sample
    </h1>
    <div className="flex flex-row flex-nowrap w-full max-w-full   self-auto text-inherit    gap-2   items-center justify-start h-auto  ">
      <span className="inline-flex text-sm      text-left" style={{ whiteSpace: 'pre-wrap' }}>
      Fill out the form below
      </span>
      <a className="inline-flex text-sm text-base-content   underline    cursor-pointer" href="#" target="_self" rel="">
        Learn more
      </a>
    </div>
  </div>
  <div className="flex flex-col flex-nowrap w-full max-w-full   self-auto text-inherit    gap-4   items-start justify-start h-auto  ">
    <InputText label="" placeholder="email@company.com" width="full" leftIcon="mail" />
    <InputText label="" placeholder="(123) 456 7898" width="full" leftIcon="phone"  />
    <Checkbox label="I simply agree to everything" width="full" style="standard" size="medium"/>
  </div>
  <div className="flex flex-col flex-nowrap w-full max-w-full   self-auto text-inherit    gap-2   items-stretch justify-start h-auto  ">
    <Button text="Submit" color="primary"  style="filled" rightIcon="send"  />
    <Button text="Go Back"  />
  </div>
</form>
</Main>
  )
}