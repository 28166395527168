import React, { useMemo, useRef } from "react";
import { v1 as uuidv1 } from 'uuid';
import { createNewIds } from "../../../../../utilities/helpers";
import FramePreview from "pages/editor/minnav/FramePreview";
import { NavArrowRight } from "iconoir-react";

export default function FrameTemplate({ frame, assets, view, variables, insertNewFrame, handleDragEnd, handleDragStart }) {
  const myRef = useRef(null);
  const { thumbnail, width=1440, height=820 } = frame
  const templateObjects = [...frame.objects] // copy the objects
  
  const tileWidth = 244;
  

  // Calculate the scaling factor based on the largest dimension
  const scaleWidth = tileWidth / width;
  const scaleFactor = Math.min(1, scaleWidth); // Ensure the scaling does not exceed 1

  // Use the scaling factor to determine the dimensions
  const scaledWidth = width * scaleFactor;
  const scaledHeight = height * scaleFactor;
  
  const tempFrameId = uuidv1(); // will use for frame.id and object.frame
  let objects = createNewIds(templateObjects, frame.id, tempFrameId); // create new Ids for these objects
  const rootObject = objects.find((obj) => obj.parent === tempFrameId);
  objects = objects.map((obj) => obj.id === rootObject?.id ? { ...obj, parent: 'rootObject', frame: tempFrameId } : { ...obj, frame: tempFrameId });
  
  const canDrag = !view?.disableDnD && view?.mode == 'editor' && !view?.overview
  
  function handleClick() {
    insertNewFrame({ objects });
  }

  const self = {
    source: "library",
    componentAPIName: rootObject?.componentAPIName,
    objects
  };

  return (
    <div className="flex flex-col w-full gap-1 p-1 hover:bg-base-200 hover:ring-4 hover:ring-base-200 rounded relative group"
    onClick={handleClick}
    >
    <div
    className="flex flex-col justify-center border border-[0.5px] border-base-300 hover:border-base-400 transition-all duration-75
    bg-base-200 items-center relative font-normal select-none overflow-hidden rounded-sm"
    ref={myRef}
    style={{ width: scaledWidth, height: scaledHeight }}
    
  >
    
    <div
      className="bg-base-0"
      style={{
        transform: `scale(${scaleFactor})`,
        cursor: canDrag ? 'grab' : 'copy',
        width: width,
        height: height
      }}
        draggable={canDrag}
        onDragStart={(event) => {canDrag && event.stopPropagation(); handleDragStart(event, self, myRef)}}
        onDragEnd={(event) => {canDrag && event.stopPropagation(); handleDragEnd(event, self, myRef)}}
      >
        {<FramePreview
          frame={frame} width={width} height={height}
          thumbnail={thumbnail}
          assets={assets}
          effectiveVariables={variables} 
        />}
        
      </div>
      </div>
      <div className='flex flex-row gap-1 justify-between w-full font-normal text-xs '>
            <h4 className='capitalize flex-grow truncate pl-0.5 opacity-50 group-hover:opacity-100'>
              {frame.name}
            </h4>
            <NavArrowRight width={16} height={16} className="opacity-0 group-hover:opacity-70 hover:opacity-100 hover:stroke-[1.6px]"/>
      </div>
      </div>
  );
}