import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSwipeable } from 'react-swipeable';
import { ButtonIcon, PlaybackButton } from '../../ui-kit/exports/react';
import ProgressButton from './ProgressButton';


export function VideoPreview({ project }) {
    const playerRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [localPlay, setLocalPlay] = useState(false);
    const videoSrc = project.video;
  
    const handleProgress = ({ played }) => {
      setProgress(played * 100);
    };
  
    const handlePlayPause = () => {
      setLocalPlay((prev) => !prev);
    };
  
    const handleEnded = () => {
      setProgress(0);
      setLocalPlay(false);
    };
  
    return (
      <div className="flex flex-col rounded-md overflow-hidden w-full h-full">
        <div className='w-full h-full relative'>
        {<ReactPlayer
          ref={playerRef}
          url={videoSrc}
          playing={localPlay}
          loop
          onProgress={handleProgress}
          onEnded={handleEnded}
          progressInterval={20}
          controls={false}
          muted
          playsinline
          width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover' }}
            
          
        />}
        </div>
        <div className={`absolute z-10 top-5 right-5  transition-all duration-300 ease-in-out
            ${localPlay ? '' : 'md:opacity-0 md:group-hover:opacity-100'}
            `}>
          <ProgressButton
            onClick={handlePlayPause}
            progress={progress}
            isPlaying={localPlay}
          />
        </div>
      </div>
    );
  }

export function SlideShow({ project }) {
    const images = project.images || [];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadedImages, setLoadedImages] = useState([]);
    const [swiping, setSwiping] = useState(false);

    useEffect(() => {
        const preloadImages = async () => {
            const promises = images.map((src) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = src;
                    img.onload = () => resolve(src);
                    img.onerror = () => reject(src);
                });
            });

            try {
                const loaded = await Promise.all(promises);
                setLoadedImages(loaded);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error preloading images", error);
            }
        };

        if (images.length > 0) {
            preloadImages();
        }
    }, [images]);

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe("left"),
        onSwipedRight: () => handleSwipe("right"),
        trackTouch: true,
        trackMouse: false,
    });

    const handleSwipe = (direction) => {
        setSwiping(true);
        if (direction === "left") {
            setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        } else if (direction === "right") {
            setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
        }
        setTimeout(() => setSwiping(false), 300); // Transition duration match
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        
        <div
            {...handlers}
            className=" overflow-hidden rounded-md"
            style={{ 
                width: '100%',
                cursor: 'pointer' }}
        >
            <div
                className={`transition-transform duration-300 ease-in-out`}
                style={{
                    display: 'flex',
                    transform: `translateX(-${currentIndex * 100}%)`,
                }}
            >
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="flex-shrink-0 w-full"
                        style={{
                            width: '100%',
                            aspectRatio: project.aspect || '8/5',
                            backgroundImage: `url(${image})`,
                            backgroundSize: '100% auto',
                            backgroundPosition: 'top center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                ))}
            </div>

            {/* Dots */}
            {images?.length > 1 && (
                <div
                    className="absolute z-10 bottom-2.5 md:bottom-auto md:top-6 right-1/2 md:right-6 translate-x-1/2 md:translate-x-0 flex items-center justify-center gap-1 
                    md:opacity-0 md:group-hover:opacity-100 transition-all duration-150 ease-in-out"
                >
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`w-1.5 h-1.5 rounded-full bg-current-20 hover:bg-current-50 
                                pointer-events-none md:pointer-events-auto
                                ${
                                currentIndex === index && 'bg-current-50'
                            }`}
                            onClick={() => setCurrentIndex(index)}
                        />
                    ))}
                </div>
            )}

            {/* Next and Prev buttons */}
            <div
                className="hidden md:absolute w-full z-10 top-1/2 
                -translate-y-1/2
                left-0 px-6 md:flex items-center justify-between gap-2
                drop-shadow
                opacity-0 group-hover:opacity-100 transition-all duration-150 ease-in-out"
            >
                <ButtonIcon
                    icon="chevron-left"
                    style='filled'
                    color='base-0'
                    isPill
                    onClick={() =>
                        setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1)
                    }
                />

                <ButtonIcon
                    style='filled'
                    color='base-0'
                    isPill
                    onClick={() =>
                        setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1)
                    }
                    icon="chevron-right"
                />
            </div>
        </div>
    );
}



