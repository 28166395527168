import { useEffect, useRef, useState } from "react"
import { projects } from "./projects";
import { SlideShow, VideoPreview } from "./PreviewComponents";

export default function Examples({mainWidth}) {

    const list = projects
    const [playingProject, setPlayingProject] = useState(null);
    

    return (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 w-full h-full
             gap-8 flex-grow items-center justify-start md:py-12 relative px-5"
             style={{maxWidth: mainWidth}}
             >
                
                    {list.map((project, index) => (
                    <div className={`flex flex-col w-full h-full`} key={index}>
                    <div className={`w-full p-6 ${project.aspect ? 'md:px-10 py-8' : 'md:p-10'}
                    flex-shrink-0 rounded-3xl relative group aspect-square
                    hover:shadow-[inset_0_0px_32px_rgba(0,0,0,0.02)]  transition-all duration-150 ease-in-out
                    bg-base-50 flex-grow flex flex-col items-center justify-center`}>
                    
                    <div className={`${!project.aspect ? 'w-full' : 'h-full'}
                    ring-[0.5px] ring-current-20 rounded overflow-hidden  flex flex-col items-center justify-center`}
                    style={{aspectRatio: project.aspect || 'auto'}}
                    >
                    {project.video ? <VideoPreview
                        key={project.name} 
                        playingProject={playingProject}
                        project={project} 
                        setPlayingProject={setPlayingProject} />
                    : <SlideShow
                        project={project} 
                        setPlayingProject={setPlayingProject} 
                        playingProject={playingProject}
                        />}
                    </div>
                    
                    
                    </div>
                    <ProjectDescription project={project} />
                    </div>
                    
                    
                    ))}
                
                
                
            </div>
    )
  }

function ProjectDescription({ project }) {
    return (
        <div className="flex flex-row mt-6 gap-4 items-center justify-start w-full select-none">
                    
                    <div className={`flex flex-col w-11 h-11 flex-shrink-0 flex-grow-0
    transition-all duration-150 ease-in-out rounded-${project.corners || 'xl'} relative group
    border-[0.5px] border-current-5
                    `}
                    style={{
                        backgroundImage: `url(${project.symbol})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: project.name == "Juno" && 'var(--accent)', 
                    }}/>
                    <div className="flex flex-col flex-nowrap w-full text-auto items-start justify-start">
                        <span className="inline-flex text-md font-semibold text-left">
                        {project.name}
                        </span>
                        <span className="inline-flex text-sm font-light text-base-600 text-left ">
                        {project.description}
                        </span>
                    </div>
                    </div>
    )
}

/*
<h2 className="text-lg md:text-xl font-semibold leading-tight relative 
              bg-clip-text bg-gradient-to-b from-gray-700 to-gray-800 text-transparent" 
                style={{  fontFamily: 'Unbounded' }}
                key={project.name}
            >
                
                {project.name}
                {project.tag &&
            <span
                className="hidden md:absolute top-0 -right-2 translate-x-full select-none transition-all duration-75
                inline-block px-2 py-0.5 text-xs font-medium bg-primary text-white rounded-md shadow-sm"
            >
                {project.tag}
    </span>}
            </h2>*/
