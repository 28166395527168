import * as UIKit from '../ui-kit/exports/react';
import * as LocalKit from 'ui-kit/local';
import { getChildren, getDescendants } from '../utilities/helpers';

export default function PreviewRouter (props) {
    
    const { self, assets, isMobile: localMobile, descendants=[], designConfig} = props;
    const htmlComponents = ['Flexbox', 'Grid', 'Form']
    const needsDummyState = ['Checkbox', 'Select']
    const ComponentToRender = (htmlComponents.includes(self.componentAPIName) || needsDummyState.includes(self.componentAPIName))
    ? LocalKit[self.componentAPIName] : UIKit[self.componentAPIName] || null

    const isMobile = localMobile == undefined ? window.innerWidth < 768 : localMobile;
    const children = getChildren(self?.id, descendants);
    
    const attributes = {
        id: self.id,
    }
    
    
    const effectiveProps = (isMobile && !htmlComponents.includes(self?.componentAPIName)) ? {...self.object_props, ...self.mobile_props} : self.object_props

    const objectProps = {
        ...effectiveProps,
        assets: assets,
        __juno: {
            mobile_props: self?.mobile_props || {}, // this is for Flexbox, Grid and Form
            designConfig,
            attributes
        }
    };  


    // console.log('LightRouter', self.id)
    if (ComponentToRender) return (<>
    <ComponentToRender {...objectProps} >
        {children.length ? children.sort((a, b) => a.index - b.index).map(child => (
            <PreviewRouter 
                key={child.id} 
                self={child} 
                assets={assets} 
                isMobile={isMobile}
                designConfig={designConfig}
                descendants={getDescendants(child.id, descendants)} 
            />
        )) : null}
    </ComponentToRender>
    </>)
}